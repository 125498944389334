var Func = (function () {

    return {
        //A-Z
        CLOUD: {
            APPLE_LOGIN: "APPLE_LOGIN", // 애플 로그인
            AD_FILE_UPLOAD: "AD_FILE_UPLOAD", //DB 값으로 파일업로드 사이즈 제한
            CHAT_MOA_VIEW: "CHAT_MOA_VIEW", // 채팅 모아보기 버튼
            CHAT_LINK_MOA_VIEW: "CHAT_LINK_MOA_VIEW", // 링크 모아버기 버튼
            CHAT_SRCH: "CHAT_SRCH", // 채팅 검색
            CHAT_NOTICE: "CHAT_NOTICE", // 채팅 공지사항
            DROPBOX_UPLOAD_PREVENT: "DROPBOX_UPLOAD_PREVENT", // dropbox off
            DROPBOX_UPLOAD: "DROPBOX_UPLOAD", // dropbox on
            FILE_EXTENSION_BLOCK: "FILE_EXTENSION_BLOCK", //DB 값으로 파일업로드 확장자 제한
            GOOGLEDRIVE_UPLOAD: "GOOGLEDRIVE_UPLOAD", // google drive on
            GOOGLEDRIVE_UPLOAD_PREVENT: "GOOGLEDRIVE_UPLOAD_PREVENT", // google drive off
            GANTT: "GANTT", // 간트 차트
            GUEST_LIMIT: "GUEST_LIMIT", //게스트 제한
            GUEST_STORAGE_LIMIT: "GUEST_STORAGE_LIMIT",
            IMPORT_FILE_BOX: "IMPORT_FILE_BOX", // 파일함 첨부
            NOTDISTURB: "NOTDISTURB", // 방해금지모드 요일 설정
            PROFILE_THUMBNAIL: "PROFILE_THUMBNAIL", //프로필 썸네임
            POST_VIEW_SELECT: "POST_VIEW_SELECT", // 프로젝트 포스트 뷰 선택
            QUICK_GUIDE: "QUICK_GUIDE", // 퀵 가이드
            RESTRICT_POPUP_MANAGER: "RESTRICT_POPUP_MANAGER", //제한팝업에 관리자 이름표기
            SHOW_SLOGAN: "SHOW_SLOGAN", // 프로필 슬로건
            TASK_START_TIME: "TASK_START_TIME", // 업무 시작,종료시각 추가
            UPLOAD_PREVENT: "UPLOAD_PREVENT", // 업로드 방지
            VOUCHER_BANNER: "VOUCHER_BANNER", //바우처배너
            GOV_VOUCHER_BANNER: "GOV_VOUCHER_BANNER", //정부지원금 바우처배너
            WRITE_AUTHORITY_SEPARATE: "WRITE_AUTHORITY_SEPARATE", //글/댓글 작성 권한 구분
            VIDEO_CONFERENCE: "VIDEO_CONFERENCE", //화상회의
            DOWNLOAD_PREVENT: "DOWNLOAD_PREVENT", //다운로드 방지
            BLOCK_WEB_LNB_EXCEPT_BOOKMARK: "BLOCK_WEB_LNB_EXCEPT_BOOKMARK", //왼쪽 모아보기 제거 (북마크빼고)
            BLOCK_WEB_LNB_PROJ_LIST: "BLOCK_WEB_LNB_PROJ_LIST", //왼쪽 프로젝트 리스트 제어
            CHAT_JBCL_VIEW: "CHAT_JBCL_VIEW", // (채팅) 직급 + 부서 정보 출력
            PARTICIPANTS_INFO: "PARTICIPANTS_INFO", // (프로젝트) 직급 + 부서 정보 출력
            TASK_UPGRADE: "TASK_UPGRADE", // 업무 2.0
            SCHEDULE_UPGRADE: "SCHEDULE_UPGRADE", // 일정 2.0
            MINI_TOOLTIP: "MINI_TOOLTIP", // 미니모드 툴팁사용여부
            EDIT_TIME_TEXT: "EDIT_TIME_TEXT", //(일정, 업무) 시간 키보드 입력 가능여부
            MINI_CONTACT: "MINI_CONTACT", //미니모드 연락처 보이도록.
            CHAT_PROJECT_PARTI: "CHAT_PROJECT_PARTI", //프로젝트 채팅방 참여자 구분여부
            ELECTRON_NAVI: "ELECTRON_NAVI", //일렉트론 네이베이션 사용여부
            MESSENGER_TOOLTIP: "MESSENGER_TOOLTIP", //메신저 툴팁
            EXTEND_TASK_FILTER: "EXTEND_TASK_FILTER", //업무 모아보기 필터 확장 ( 마감일, 수정일 )
            USE_CHANNEL_TAB: "USE_CHANNEL_TAB", //미니 그룹웨어탭 사용여부
            MINI_BANNER: "MINI_BANNER", //미니 배너 노출여부
            MINI_SECURE_DOWNLOAD: "MINI_SECURE_DOWNLOAD", //시큐어 다운로드 기업
            MINI_DIRECTORY_DOWNLOAD: "MINI_DIRECTORY_DOWNLOAD", //다운로드경로 지정 다운로드 기업
            ALARM_COUNT_SOCKET: "ALARM_COUNT_SOCKET", //알람카운트 소켓으로 연결
            STOP_FLOWNEW: "STOP_FLOWNEW", //flow new 사용중지 팝업
            SENTRY_MONITOR: "SENTRY_MONITOR", // 센트리 모니터링 활성화 유무
            FLOW_S_CHANGE_BLOCK_VERSION: "FLOW_S_CHANGE_BLOCK_VERSION", // 이전 버전으로 돌아가기
            SERVICE_INTEGRATION: "SERVICE_INTEGRATION", // 외부서비스 연동
            COPY_SUBTASK_CONTENTS: "COPY_SUBTASK_CONTENTS", // 다른 프로젝트 복사 - 하위 업무
            CHAT_REPLY: "CHAT_REPLY", // 채팅 답장하기 기능
            CHAT_DIRECT_BOTTOM: "CHAT_DIRECT_BOTTOM", // 채팅 하단으로 바로내려가기 버튼 활성화
            CHAT_REPLY_SHAKE: "CHAT_REPLY_SHAKE", // 채팅 답장 흔들기
            CHAT_DIRECT_REPLY: "CHAT_DIRECT_REPLY", // 채팅 호버로 답장 바로하기 버튼 활성화
            CHAT_IMAGE_GROUP: "CHAT_IMAGE_GROUP", // 이미지 묶어보내기
            WEB_RTC_SPEECH_RECOGNITION: "WEB_RTC_SPEECH_RECOGNITION", // WEB-RTC ( 음성녹음 )
            DESKTOP_UPDATE_POPUP: "DESKTOP_UPDATE_POPUP", // 데스크탑 업데이트 팝업
            CHAT_IMAGE_GROUP_KEEP: "CHAT_IMAGE_GROUP_KEEP", //이미지 묶어보내기 실패 메세지 저장
            CHAT_IMAGE_GROUP_MULTI: "CHAT_IMAGE_GROUP_MULTI", //이미지 묶어보내기 동시 전송 가능
            CHAT_LIST_DIRECT_RECEIVE: "CHAT_LIST_DIRECT_RECEIVE", //채팅 리스트에서 바로 소켓으로 받은 정보를 보여주는 기능 (db 조회 없이)
            DESKTOP_AUTOUPDATE_ON_POPUP: "DESKTOP_AUTOUPDATE_ON_POPUP", //업데이트 팝업에서 업데이트 버튼을 클릭했을때 새버전으로 바로 자동재시작.
            DVSN_EMPL_CNT: "DVSN_EMPL_CNT", // 조직도 인원수
            GOOGLE_CALENDAR: "GOOGLE_CALENDAR", // 구글 캘린더 연동
            WIDE_POST_VIEW: "WIDE_POST_VIEW", // 포스트 넓은 상태로 보기
            ALARM_BANNER_VIEW: "ALARM_BANNER_VIEW", // 아래 배너 알림 표시
            OFF_CHAT_VIDEO_CONFERENCE: "OFF_CHAT_VIDEO_CONFERENCE",
            SUBTASK_BUNDLE: "SUBTASK_BUNDLE", //하위업무 묶어보기
            SUBTASK_DOUBLE_BUNDLE: "SUBTASK_DOUBLE_BUNDLE", //타 묶어보기 + 하위업무 묶어보기
            GIPHY_IMAGE: "GIPHY_IMAGE", // GIPHY API를 이용한 GIF 이미지 전송 기능
            POST_TEMPLATE: "POST_TEMPLATE", // 포스트 템플릿
            MEMO: "MEMO", // 메모장
            PROJECT_MAKE_PRO: "PROJECT_MAKE_PRO", // 프로젝트 2.0, 템플릿
            SECURE_CONNECT: "SECURE_CONNECT",       // secure 접속하는 이용기관 처리
            SECURE_AWS: "SECURE_AWS",               // AWS 주소가 고객사 방화벽에 막혀있는 경우
            ADD_INVITE_LINK : "ADD_INVITE_LINK", // 프로젝트에서 링크로 초대하기 팝업 출력
            CHAT_MANAGER: "CHAT_MANAGER", //채팅 관리자 기능
            NEW_CHAT_INVITATION: "NEW_CHAT_INVITATION", //채팅 초대 신규 전문 변경
            FLOW_ALARM_MAIL: "FLOW_ALARM_MAIL", // 메일 알림
            NEW_REQ_CHAT: "NEW_REQ_CHAT", // 채팅 읽음처리 분리
            CHAT_SESSION_CHECK: "CHAT_SESSION_CHECK",   // 채팅 세션 체크(보안)
            SIGN_UP_EMAIL_INVITE_PAGE: "SIGN_UP_EMAIL_INVITE_PAGE",
            CHAT_SECURITY_SESSION:"CHAT_SECURITY_SESSION", // 채팅 소켓 연결 (보안)
            TUTORIAL: "TUTORIAL", //튜토리얼
            END_PROJECT_KRX: "END_PROJECT_KRX",   // 프로젝트 종료
            PROJECT_TOUR_LINK_CREATE: "PROJECT_TOUR_LINK_CREATE",  // 프로젝트 투어 링크 생성 버튼 표기
            ORZ_HIDDEN_MM: "ORZ_HIDDEN_MM",
            ROUTINE: "ROUTINE", // 게시물 현재 프로젝트에 복제 & 루틴
        },
        KT_BIZWORKS: {
            KT_QNA: "KT_QNA",
        },
        ENTER: {
            CHAT_ALL_DELETE_ALWAYS: "CHAT_ALL_DELETE_ALWAYS", // 채팅 전체 삭제 항상
            GOOGLE_MAP: "GOOGLE_MAP", // 구글 맵 사용 여부
            HIDE_PRFL_EML: "HIDE_PRFL_EML",
            HIDE_PRFL_CLPH_NO: "HIDE_PRFL_CLPH_NO",
            HIDE_PRFL_CMPN_TLPH_NO: "HIDE_PRFL_CMPN_TLPH_NO",
            HIDE_PRFL_SLGN: "HIDE_PRFL_SLGN",
            HIDE_PRFL_CMNM:"HIDE_PRFL_CMNM",
            HIDE_PRFL_COMPANY_INFO : "HIDE_PRFL_COMPANY_INFO",
            FILE_DOWNLOAD_ONLY: "FILE_DOWNLOAD_ONLY", // 문서뷰어 안열리게
            UPLOAD_IMAGE_ONLY: "UPLOAD_IMAGE_ONLY", // 이미지만 업로드
            BLOCK_MODIFY_PROFILE:"BLOCK_MODIFY_PROFILE",
            BLOCK_MODIFY_FLNM: "BLOCK_MODIFY_FLNM",
            BLOCK_MODIFY_DVSN: "BLOCK_MODIFY_DVSN",
            BLOCK_MODIFY_JBCL: "BLOCK_MODIFY_JBCL",
            BLOCK_MODIFY_CLPH_NO: "BLOCK_MODIFY_CLPH_NO",
            BLOCK_MODIFY_CMPN_TLPH_NO: "BLOCK_MODIFY_CMPN_TLPH_NO",
            BLOCK_MODIFY_EML: "BLOCK_MODIFY_EML",
            BLOCK_MODIFY_SLGN: "BLOCK_MODIFY_SLGN",
            PRFL_IMG_UPDATE_OFF: "PRFL_IMG_UPDATE_OFF",
            SECURITY_PLEDGE_POPUP: "SECURITY_PLEDGE_POPUP",
            SECURITY_PLEDGE_PROJECT_MAKE_POPUP: "SECURITY_PLEDGE_PROJECT_MAKE_POPUP",
            PRFL_IMG_TO_TOP: "PRFL_IMG_TO_TOP",
            PWD_EDIT: "PWD_EDIT", //환경설정 비밀번호 변경
            PWD_REG_MORE_THAN_EIGHT:"PWD_REG_MORE_THAN_EIGHT", //비밀번호 8글자 이상, 특수문자 포함
            TWO_FACTOR_FOR_LOCKED : "TWO_FACTOR_FOR_LOCKED", // 계정이 잠겼을 때, SMS 투팩터 인증을 통한 잠금해제 후 SMS 로 임시비밀번호 받기
            ZOOM_MOBILE_ONLY: "ZOOM_MOBILE_ONLY",
            BOT_TEXT_TO_EMAIL: "BOT_TEXT_TO_EMAIL",
            DVSN_EMPL_CNT: "DVSN_EMPL_CNT",
            BOT_IMG_TO_DOC: "BOT_IMG_TO_DOC",
            NONE_LOGO: "NONE_LOGO", //로고 없애기
            NONE_IMAGE_VIEWER_PANEL: "NONE_IMAGE_VIEWER_PANEL", // 이미지 뷰어 패널 제거
            AD_AUTO_LOGIN: "AD_AUTO_LOGIN", //AD ID 체크하여 AUTO LOGIN
            SHOW_EMAIL_INVITATION: "SHOW_EMAIL_INVITATION", // 이메일 초대하기 버튼
            OKR: "OKR",
            ORGANIZATION_DRAG_EXECUTE: "ORGANIZATION_DRAG_EXECUTE", // 조직도 드래그로 전문 호출
            ADD_INVITE_LINK: "ADD_INVITE_LINK", // 프로젝트 초대 링크 복사
            IMG_TO_DOCVIEWER: "IMG_TO_DOCVIEWER", //img 파일 문서뷰어로
            COLABO_EVALUATION: "COLABO_EVALUATION", // 상시 업무 평가
            TELECOMMUTE_REPORT:"TELECOMMUTE_REPORT", //재택 근무 일지
            MOBIS_SAP_FUNC:"MOBIS_SAP_FUNC", //모비스 SAP 활동, 성과 이력
            MOBIS_EMAIL_EXPORT : "MOBIS_EMAIL_EXPORT", // 모비스 이메일 내보내기
            EMAIL_EXPORT : "EMAIL_EXPORT", // 이메일 내보내기
            ELAND_CHAT_BOT : "ELAND_CHAT_BOT",
            EDITOR2_TITLE_REQUIRE: "EDITOR2_TITLE_REQUIRE", //글 작성 2.0 제목 필수 값 처리
            DGBC_WEBFILTER : "DGBC_WEBFILTER", //DGBC web filter
            GUEST_INPUT_REQUIRED : "GUEST_INPUT_REQUIRED", //외부인 계정 로그인 시 필수 정보 입력 팝업
            EMPL_CALENDAR:"EMPL_CALENDAR",
            BLOCK_CHAT_DVSN_INVITE : "BLOCK_CHAT_DVSN_INVITE", // 채팅 부서초대 제한
            SOIL_CHAT_BOT:"SOIL_CHAT_BOT", // SOIL 챗봇 플로팅 버튼
            IPT_FUNC:"IPT_FUNC",
            IPT_FWD:"IPT_FWD",
            IPT_DND:"IPT_DND",
            STATUS_FUNC:"STATUS_FUNC",
            HIDE_GUEST_INVITATION:"HIDE_GUEST_INVITATION", //초대 팝업 외부인 창 숨기기
            HIDE_GROUP_INVITATION:"HIDE_GROUP_INVITATION", //초대 팝업 그룹 창 숨기기
            HIDE_CHAT_SECRET_BTN:"HIDE_CHAT_SECRET_BTN", //시크릿 채팅 버튼 제거
            DBFI_OFFICIAL:"DBFI_OFFICIAL", //DB 금투 공식 프로젝트
            DBFI_UPLOAD_AGREE:"DBFI_UPLOAD_AGREE", //파일 업로드 관련 사전 동의
            EMPL_CALENDAR:"EMPL_CALENDAR",
            MRA_ITSM : "MRA_ITSM",
            SHOW_FMC_NO:"SHOW_FMC_NO",
            SHOW_EXT_NO:"SHOW_EXT_NO",
            FMC_FUNC:"FMC_FUNC",
            GROUP_CALL_KAKAO:"GROUP_CALL_KAKAO",
            SHOW_DAY_OFF:"SHOW_DAY_OFF",
            SHOW_WORKING_TIME:"SHOW_WORKING_TIME",
            HIDE_SETTING_WORKING_TIME:"HIDE_SETTING_WORKING_TIME",
            SHOW_CHARGE_JOB_NM:"SHOW_CHARGE_JOB_NM",
            SHOW_EMP_NO : "SHOW_EMP_NO",
            MRA_CHECK_DEAD_ISSUE_AND : "MRA_CHECK_DEAD_ISSUE_AND", //MRA_ITSM 지연, 이슈 체크박스 and 처리
            MRA_ITSM_LIST_RESET_COUNT : "MRA_ITSM_LIST_RESET_COUNT", //MRA_ITSM 지연, 이슈 필터에 따른 건수 표시 제어
            ALARMBOT_DRAW_DATE : "ALARMBOT_DRAW_DATE", //ALARMBOT에 날짜 구분선 추가
            SHOW_CHAT_ORG_GUEST:"SHOW_CHAT_ORG_GUEST", // 미니 채팅 검색 결과 창 외부인 노출
            MESSAGE : "MESSAGE",
            PSNM_SMS:"PSNM_SMS", // PSNM SMS기능
            INVITE_EXCEL_TAB:"INVITE_EXCEL_TAB", // 초대 창 엑셀 탭 활성화
            CHAT_FILE_UPLOAD_OFF:"CHAT_FILE_UPLOAD_OFF", //채팅 창 업로드 금지
            HIDE_ORG_BTN:"HIDE_ORG_BTN", //조직도 아이콘 가리기
            HIDE_CONTACT_BTN:"HIDE_CONTACT_BTN", // 연락처 아이콘 가리기
            HIDE_PROJECT_MAKE_BTN: "HIDE_PROJECT_MAKE_BTN", // 프로젝트 생성 버튼 가리기
            HIDE_PROJECT_INVITE_BTN: "HIDE_PROJECT_INVITE_BTN", //프로젝트 초대 버튼 가리기
            HIDE_CHAT_INVITE_BTN:"HIDE_CHAT_INVITE_BTN", //채팅 초대 버튼 가리기
            CLICK_TO_CALL:"CLICK_TO_CALL",
            PRIVACY_VALIDATE_CHECK:"PRIVACY_VALIDATE_CHECK", // 개인정보 입력 금지
            MESSAGE_POPUP: "MESSAGE_POPUP", //쪽지
            RETRIEVE_MESSAGE: "RETRIEVE_MESSAGE", //쪽지 회수 버튼 노출
            CALENDAR_PLUS: "CALENDAR_PLUS", // 스케쥴 플러스
            BLOCK_WORKING_STATUS:"BLOCK_WORKING_STATUS", // 환경설정 내 근무상태 수정버튼 제한
            BLOCK_CHARGE_JOB:"BLOCK_CHARGE_JOB", // 환경설정 내 담당업무 수정버튼 제한
            BLOCK_WORKING_TIME:"BLOCK_WORKING_TIME", // 환경설정 내 근무시간 수정버튼 제한
            BLOCK_WORKING_PLACE:"BLOCK_WORKING_PLACE", // 환경설정 내 근무지 수정버튼 제한
            NOLIMIT_CHAT:"NOLIMIT_CHAT", // 이전 채팅 보기
            EXPORT_PDF: "EXPORT_PDF", // PDF 내보내기(저장)
            CHAT_TOTAL_SEARCH:"CHAT_TOTAL_SEARCH", // 채팅 통합 검색
            FORWARD_CONTENTS:"FORWARD_CONTENTS", // 프로젝트, 채팅 전달하기
            HIDE_COPY_PROJECT:"HIDE_COPY_PROJECT", // 다른 프로젝트에 복사 숨기기
            IPT_CALL_LIST: "IPT_CALL_LIST", //ipt 전화 내역
            ADMIN_HISTORY: "ADMIN_HISTORY", // 익명방 요건 - 관리자 글
            MAKE_ANONYMOUS_PRJ: "MAKE_ANONYMOUS_PRJ", // 프로젝트 생성에서 익명방 기능 적용
            ADMIN_SHOW_PARTICIPANT_IN_ANONYMOUS_PRJ: "ADMIN_SHOW_PARTICIPANT_IN_ANONYMOUS_PRJ", // 관리자는 익명방에서 참여자 리스트 출력
            ADMIN_SHOW_INVITE_BTN_IN_ANONYMOUS_PRJ: "ADMIN_SHOW_INVITE_BTN_IN_ANONYMOUS_PRJ", // 관리자는 익명방에서 초대버튼 출력
            KOGAS_CHATBOT: "KOGAS_CHATBOT",
            KCLOUD_MOCOMSYS: "KCLOUD_MOCOMSYS", // 한국가스공사 문서중앙화 연동
            KEYWORD_SEARCH: "KEYWORD_SEARCH", // 조건 and 검색 (키워드 검색)
            KOGAS_OTP: "KOGAS_OTP", // 한국가스공사 OTP
            HIDE_OPEN_PROJECT: "HIDE_OPEN_PROJECT", //회사 공개 프로젝트 삭제
            SPREAD_INVITE_ORG_CHART: "SPREAD_INVITE_ORG_CHART", //초대 팝업 조직도 펼치기
            MAJOR_SERVICE: "MAJOR_SERVICE",
            SINMUNGO_PROJECT: "SINMUNGO_PROJECT", // 신문고 프로젝트
            EXEC_SCHEDULE:"EXEC_SCHEDULE", // 임원 일정
            HIDE_CAMERA_UPLOAD_BUTTON: "HIDE_CAMERA_UPLOAD_BUTTON",
            HIDE_PICTURE_UPLOAD_BUTTON: "HIDE_PICTURE_UPLOAD_BUTTON",
            HIDE_CALENDAR_PLUS_ALARM : "HIDE_CALENDAR_PLUS_ALARM", // 내 캘린더 미리알림 영역 제거
            REPEAT_SCHEDULE : "REPEAT_SCHEDULE",
            PWD_REG_MORE_THAN_FIFTEEN: "PWD_REG_MORE_THAN_FIFTEEN", // 15~30자리, 대/소문자 구분, 숫자, 특수문자 포함
            REMINDER_ALARM: "REMINDER_ALARM", //BMW 다시알림
            BMW_REMINDER_DEFAULT: "BMW_REMINDER_DEFAULT", //BMW 다시알림 기본값
            SURVEY: "SURVEY", //설문
            HIDE_KEYWORD_SEARCH_EMP_NO : "HIDE_KEYWORD_SEARCH_EMP_NO", // 초대 팝업 검색 -> 사번 영역 가리기
            HIDE_KEYWORD_SEARCH_CMNM : "HIDE_KEYWORD_SEARCH_CMNM", // 초대 팝업 검색 -> 회사명 영역 가리기
            HIDE_AUTO_LOGIN:"HIDE_AUTO_LOGIN", //자동 로그인 가리기
            SECO_VIDEO_CONFERENCE:"SECO_VIDEO_CONFERENCE", // 세코 4NB 화상회의
            WIDE_ORG_USER_LIST_VIEW : "WIDE_ORG_USER_LIST_VIEW", // 조직도 사용자 리스트 넓게 보기
            TOP_DEPARTMENT_CLICK : "TOP_DEPARTMENT_CLICK", //POSCO 상단부서클릭 여부
            EMPLOYEE_OPINION_PROJECT: "EMPLOYEE_OPINION_PROJECT", // 직원의견방
            REMARK_PIN_AUTH: "REMARK_PIN_AUTH", // 댓글 상단고정 권한 설정
            REMARK_PIN_HIGHLIGHT: "REMARK_PIN_HIGHLIGHT", // 댓글 상단고정 완료 시 하이라이트 표시
            POST_TITLE_NECESSARY: "POST_TITLE_NECESSARY", // 글 제목 필수값
            PRESENTATION_MODE: "PRESENTATION_MODE", // 회의모드
            DOCVIEWER_ONLY : "DOCVIEWER_ONLY",
            SHOW_MORE_DEP: "SHOW_MORE_DEP", // 부서 잘 보이게
            SHOW_FAVORITE_GROUP_CNT:"SHOW_FAVORITE_GROUP_CNT", //미니 즐겨찾기 명수 표시

        },
        DEVELOP: {
            URL_COPY: "URL_COPY", //포스트 URL 복사
            EDITOR3: "EDITOR3", // 에디터 3.0 - CK EDITOR
        },
        LOG: {
            LOCK: "LOCK_TOAST" //잠금모드 60초 전부터 토스트 알림이 나옴
        }
    }
})()
