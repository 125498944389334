var PasswordExpiration = (function () {

    let isOpen = false;
    let $passwordExpiration;
    let $modalHeader;
    let $modalMessage;
    let $orgPassword;
    let $newPassword;
    let $newPasswordConfirm;
    let $postponeBtn;
    let $changeBtn;
    let isUseOrgPassword;
    let funcPwInit, funcPwChange;
    let modalText = {
        header : "",
        message : "",
    }

    const PW_CHANGE_REASON = {
        INIT: "INIT",
        EXPIRE: "EXPIRE",
    }

    /**
     * 1. 문자열이 'FLOW_'로 시작하고 그 뒤에 하나 이상의 숫자가 오는 경우
     * 2. 문자열이 'BFLOW_'로 시작하고 그 뒤에 하나 이상의 숫자가 오는 경우
     * 3. 'POSCOICT_2', 또는 'SAMFIRE_'로 시작하고 그 뒤에 최대 하나의 숫자가 오는 경우
     */
    const TARGET_INTT_REGEXP = /^(?:B?FLOW_\d+|POSCOICT_2|HANSOLPNS_\d+|SAMFIRE_\d?)$/i;

    return{
        init: init,
        miniInit: miniInit,
    }

    function init() {
        if (!functionExecutionCondition()) return;
        if (isOpen) return;

        // set element
        $passwordExpiration = $("#passwordExpiration");
        $modalHeader = $("#passwordExpirationHeader");
        $modalMessage = $("#passwordExpirationMessage");
        $orgPassword = $passwordExpiration.find("#pwExpireOrgPassword");
        $newPassword = $passwordExpiration.find("#pwExpireNewPassword");
        $newPasswordConfirm = $passwordExpiration.find("#pwExpireNewPasswordConfirm");
        $postponeBtn = $passwordExpiration.find("#pwExpirePostpone");
        $changeBtn = $passwordExpiration.find("#pwExpireChange");

        // 이벤트 추가
        $changeBtn.off('click').on('click', passwordChange);
        $postponeBtn.off('click').on('click', (e) => {
            passwordLimitDateExtend(e, () => { $passwordExpiration.css('display', 'none'); })
        });
        $orgPassword.off("keyup").on("keyup", (e) => {
            if (e.keyCode === 13) { $newPassword.focus(); }
            else koreanInputCheck(e.target);
        });
        $newPassword.off("keyup").on("keyup", (e) => {
            if (e.keyCode === 13) { $newPasswordConfirm.focus(); }
            else koreanInputCheck(e.target);
        });
        $newPasswordConfirm.off("keyup").on("keyup", (e) => {
            if (e.keyCode === 13) { $changeBtn.click(); }
            else koreanInputCheck(e.target);
        });

        // 모달 실행!
        openPasswordExpirationModal((data) => {
            if (!modalOpenCondition(data.REASON)) return;

            pwModalSettingByType(data.REASON);
            $modalHeader.text(modalText.header);
            $modalMessage.html(modalText.message);
            if (Often.isFunc(Func.ENTER.PWD_REG_MORE_THAN_FIFTEEN)) $newPasswordConfirm.next().text('비밀번호 입력 (15~30자 대문자, 소문자, 숫자, 특수문자 조합)');

            const $orgPwRowElement = $orgPassword.closest("li");
            let showCallback;
            if (data.REASON === PW_CHANGE_REASON.INIT) {
                isUseOrgPassword = false;
                $orgPwRowElement.css('display', 'none');
                $postponeBtn.css('display', 'none');
                showCallback = () => $newPassword.focus();
            } else if (data.REASON === PW_CHANGE_REASON.EXPIRE) {
                isUseOrgPassword = true;
                $orgPwRowElement.css('display', 'block');
                Often.isFunc(Func.ENTER.PWD_REG_MORE_THAN_FIFTEEN) ? $postponeBtn.css('display', 'none') : $postponeBtn.css('display', 'block');
                showCallback = () => $orgPassword.focus();
            }

            if (Often.isFunc("POPUP_QUEUE")) {
                inputPopupQueue("#passwordExpiration", 30, () => {
                    $passwordExpiration.show(0, showCallback);
                    isOpen = true;
                });
            } else {
                $passwordExpiration.show(0, showCallback);
                isOpen = true;
            }
        });

        function koreanInputCheck($eTarget) {
            Validation.checkPasswordAllowedCharacters($eTarget.value, function () {
                Often.toast("error", i18next.t(main.password.inputKorWarning));
                $eTarget.value = $eTarget.value.replace(Validation.getKoreanRex(), "");
            });
        }
    }

    // 미니 초기화 : 사용하지 않음.
    function miniInit() {
        if (!functionExecutionCondition()) return;

        openPasswordExpirationModal((data) => {
            if (!modalOpenCondition(data.REASON)) return;

            pwModalSettingByType(data.REASON);
            PopupDraw.drawConfirm({
                title: modalText.header,
                contents: {
                    main: modalText.message,
                    submit: i18next.t(dictionary.change),
                    cancel: (data.REASON === PW_CHANGE_REASON.EXPIRE)
                        ? i18next.t(main.password.dontShowThirtyDays)
                        : i18next.t(common.remindLater),
                },
                callback: {
                    submit() {
                        return MiniProject.openProjectPage();
                    },
                    cancel: data.REASON === PW_CHANGE_REASON.EXPIRE ? passwordLimitDateExtend : null,
                    close() {
                        return false;
                    }
                }
            });

            // 후 처리
            // confirm을 띄운 후, 비밀번호 초기 설정이면 30일 미루기 버튼 삭제
            // 뒷 배경 클릭해도 팝업 사라지지 않게 변경
            if (data.REASON === PW_CHANGE_REASON.INIT) {
                $("#tempPopup").find(".flow-pop-sub-button-1.cancel-event").css('display', 'none');
            }
            $("#tempPopup").removeClass("back-area").find(".back-area").removeClass("back-area")
        });
    }

    // 기능 실행 조건
    function functionExecutionCondition() {
        funcPwInit = Often.isFunc("REQUEST_PASSWORD_INIT");
        funcPwChange = Often.isFunc("REQUEST_PASSWORD_CHANGE");
        const isFunc = funcPwInit || funcPwChange;
        let isc = isFunc && (TARGET_INTT_REGEXP.test(_USE_INTT_ID) || _ENTER_YN === "Y");
        return isc;
    }

    function modalOpenCondition(reason) {
        const condInit = (reason === PW_CHANGE_REASON.INIT && funcPwInit);
        const condChange = (reason === PW_CHANGE_REASON.EXPIRE && funcPwChange);
        return (condInit || condChange);
    }

    // 패스워드 변경 모달을 띄웁니다.
    function pwModalSettingByType(reason) {
        if (reason === PW_CHANGE_REASON.INIT) {
            // 초기 비밀번호 세팅
            modalText.header = i18next.t(common.resetPassword);
            modalText.message = i18next.t(main.password.initSettingMessage);
        } else if (reason === PW_CHANGE_REASON.EXPIRE) {
            // 비밀번호 연장
            modalText.header = i18next.t(common.change, {val: $t(dictionary.password)});
            Often.isFunc(Func.ENTER.PWD_REG_MORE_THAN_FIFTEEN) ? modalText.message = "180일 동안 비밀번호를 변경하지 않았습니다.<br>개인정보 보호를 위하여 비밀번호를 변경해 주세요."
                : modalText.message = i18next.t(main.password.expirationMessage);

        }
    }

    // 비밀번호 변경
    async function passwordChange() {
        // 1. 값 유무
        let isNotEmpty = true;

        if (isUseOrgPassword) {
            isNotEmpty &= Often.null2Void($orgPassword.val()) !== "";
        }
        isNotEmpty &= Often.null2Void($newPassword.val()) !== "";
        isNotEmpty &= Often.null2Void($newPasswordConfirm.val()) !== "";
        if (!isNotEmpty) {
            Often.toast("error", i18next.t(common.passwordAlert));
            return false;
        }

        // 2. 규칙 검사
        const checkJson = Validation.checkInput($newPassword, true);
        if (Object.keys(checkJson).length > 0) {
            Often.isFunc(Func.ENTER.PWD_REG_MORE_THAN_FIFTEEN)
                ? Often.toast("error", "비밀번호는 15~30자의 대문자, 소문자, 숫자, 특수문자를 포함해야 합니다.") : Often.toast("error", i18next.t(common.passwordWarning)); // 비밀번호 규칙 불일치
            checkJson.errorObj.focus();
            return false;
        }

        // 3. 비밀번호 일치
        if ($newPassword.val() !== $newPasswordConfirm.val()) {
            Often.toast("error", i18next.t(common.new, {val: $t(main.alert.passwordNotMatch)})); // 비밀번호 확인 불일치
            return false;
        }

        // 4. 비밀번호 변경 전문
        const currPwd = isUseOrgPassword ? $orgPassword.val() : "";
        const passwordChangeType = isUseOrgPassword ? "PASSWORD_EXPIRATION" : "SET_INITIAL_PASSWORD";
        const data = {
            "CURR_PWD": currPwd,
            "PWD": $newPassword.val(),
            "GB": passwordChangeType,
        }
        Ajax.executeApi(RestApi.PUT.COLABO2_PWD_U002, data, (e) => {
            if (Often.null2Void(e.ERR_CD) !== "") {
                Often.toast("error", e.ERR_MSG);
                return false;
            } else {
                Often.toast("success", i18next.t(main.alert.passwordChange));
                $passwordExpiration.css('display', 'none');
                if(Often.isElectron() && _IsMini) ElectronApi.Comm.reload({isClearCache: false});
            }
        });
    }

    // 비밀번호 변경 요구 모달 오픈
    function openPasswordExpirationModal(callback) {
        getUserPasswordState((data) => {
            if (Often.null2Void(data.PWD_CHG_YN) === "Y") {
                callback?.(data);
            }
        });
    }

    // 비밀번호 유효일자 연장 (30일간 보지 않기)
    function passwordLimitDateExtend(e, callback) {
        const data = {USER_ID: _USER_ID, RGSN_DTTM: _RGSN_DTTM, TYPE: "E"}
        Ajax.executeApi("ACT_PWD_LIMIT_UPDATE", data, () => {
            callback?.();
            PopupDraw.closePopup();
        })
    }

    // 비빌번호 유효일자 가져오기
    function getUserPasswordState(callback) {
        const data = {USER_ID: _USER_ID, RGSN_DTTM: _RGSN_DTTM,}
        Ajax.executeApi("ACT_PWD_LIMIT_SELECT", data, callback);
    }
})();
