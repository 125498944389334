var MonitorUtil = (function () {

    return {
        isLimitMonitor: isLimitMonitor,
        load: load,
        loadGtm: loadGtm,
        loadGa: loadGa,
        loadSentry: loadSentry,
    }

    function getGoogleKey() {
        //구글애널리틱스 - 운영 : GTM-PJMHRLW , 개발 : GTM-5rwln3j
        const gtmKey = Often.isGlobal() ? "GTM-N2M3V36" : "GTM-PJMHRLW";
        return Often.isServerModeByHost("REAL") ? gtmKey : 'GTM-5RWLN3J';
    }

    function isLimitMonitor() {
        if (window.ServerChecker && window.Often) {
            return ServerChecker.isEnter || ServerChecker.isInnerNetwork;
        } else {
            return false;
        }
    }

    function load() {
        if (isLimitMonitor()) return;

        const googleKey = getGoogleKey();
        GoogleAnalytics.load(googleKey);
        GoogleTagManager.initSetting(googleKey);

        KaKaoPixel.load();
        DaumTracking.load();
    }

    function loadGtm() {
        if (isLimitMonitor()) return;
        if (Often.isBrowser("ie")) return;

        const googleKey = getGoogleKey();
        GoogleTagManager.initSetting(googleKey);
    }

    function loadGa() {
        if (isLimitMonitor()) return;
        if (Often.isBrowser("ie")) return;

        const googleKey = getGoogleKey();
        GoogleAnalytics.load(googleKey);
    }

    function loadSentry() {
        SentryMonitor.load();
    }

})()
