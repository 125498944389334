var Authority = (function () {

    //프로젝트 내 글/댓글 작성 권한
    return {
        isAuthorityCheck: isAuthorityCheck,
        isAdminAuthorityCheckAndAction: isAdminAuthorityCheckAndAction,
        isEndAuthorityCheckAndAction: isEndAuthorityCheckAndAction,
        changeCreatePostAreaByAuthority: changeCreatePostAreaByAuthority,
    }

    /**
     * 글/댓글 작성권한 체크
     * @param {string} checkMode - ADMIN, WRITE, REMARK
     * @param {string} [projectSrno] - 존재하지 않으면 postPopup의 projectSrno로 처리
     * @returns {boolean}
     */
    function isAuthorityCheck(checkMode, projectSrno) {
        projectSrno = projectSrno || getCurrentProjectSrno();
        var popProjectSettingJson = Often.null2Void(LocalUtil.getLocalValue("POP_PROJECT_SETTING"));
        var curProjectSettingJson = Often.null2Void(LocalUtil.getLocalValue("CURRENT_PROJECT_SETTING"));
        var targetProjectSettingJson;
        if (popProjectSettingJson.COLABO_SRNO === projectSrno) {
            targetProjectSettingJson = popProjectSettingJson;
        } else if (curProjectSettingJson.COLABO_SRNO === projectSrno) {
            targetProjectSettingJson = curProjectSettingJson;
        } else {
            return true;
        }
        var isWriteAuthority = Often.isFunc(Func.CLOUD.WRITE_AUTHORITY_SEPARATE);
        var isLimitWriteRemark = targetProjectSettingJson[isWriteAuthority ? "MNGR_WR_CM_YN" : "MNGR_WR_YN"] === "Y";
        if (checkMode === "ADMIN") return targetProjectSettingJson.MNGR_DSNC === "Y";
        if (checkMode === "WRITE") return !(targetProjectSettingJson.MNGR_DSNC === "N" && targetProjectSettingJson.MNGR_WR_YN === "Y" && "E" !== targetProjectSettingJson.STATUS);
        if (checkMode === "REMARK") return (targetProjectSettingJson.MNGR_DSNC === "Y" || !isLimitWriteRemark) && "E" !== targetProjectSettingJson.STATUS;
        if (checkMode === "END") return !(targetProjectSettingJson.STATUS === "E");

        function getCurrentProjectSrno() {
            var $postPopup = $("#postPopup");
            var isVisible$PostPopup = $postPopup.is(":visible");
            if (!isVisible$PostPopup) return Detail.getProjectSrno();
            return Often.null2Void($postPopup.find(".detail-item").attr("data-project-srno"));
        }
    }

    function isAdminAuthorityCheckAndAction(isAdminAuthority, event) {
        var isMonitoring = ('Y' === (LocalUtil.getLocalValue("ONLY_BUY_SETTING", "MONITORING_YN")));
        if (isAdminAuthority && !isAuthorityCheck("ADMIN")) {
            event && event.preventDefault();
            Often.toast("error", i18next.t(main.alert.adminRestriction));
            return true;
        }
        if(isMonitoring){
            Often.toast("error", "모니터링 계정은 읽기 권한만 가능합니다.");
            PopupDraw.closePopup();
            return true;
        }
        return false;
    }

    function isEndAuthorityCheckAndAction(isEndAuthority, event) {
        if (isEndAuthority && !isAuthorityCheck("END")) {
            event && event.preventDefault();
            Often.toast("terminate-project", '종료된 프로젝트에서 불가한 동작 입니다.');
            return true;
        }
        return false;
    }

    function changeCreatePostAreaByAuthority($createPostArea) {
        var $createText = $createPostArea.find(".work-desing-text");
        var isMonitoring = ('Y' === (LocalUtil.getLocalValue("ONLY_BUY_SETTING", "MONITORING_YN")));
        if(Often.isFunc(Func.CLOUD.END_PROJECT_KRX) && !isAuthorityCheck("END")) {
            $createPostArea.addClass("end");
            $createPostArea.removeClass("admin");
            $createText.text('종료된 프로젝트는 게시글 생성이 불가합니다.');
        }else if (isAuthorityCheck("WRITE")) {
            $createPostArea.removeClass("admin");
            $createText.text(i18next.t(main.detail.placeholderWritePost));
        } else {
            $createPostArea.addClass("admin");
            if(isMonitoring) {
                $createText.text("모니터링 계정은 읽기 권한만 가능합니다.");
                return;
            }
            $createText.text(i18next.t(common.adminWriteOnly, {type: $t(dictionary.article)}));
        }
    }


})();
