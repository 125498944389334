var KeyCheck = (function () {

    return {
        isKey: isKey,
        isArrowVertical: isArrowVertical,
        isAvailable: isAvailable,
        isStyleCommand: isStyleCommand,
        isSelectAllCommand: isSelectAllCommand,
        isShiftEnter: isShiftEnter,
        isTextDrag: isTextDrag,
        isUpperCase: isUpperCase,
        isCapsLockOn: isCapsLockOn,
    }

    function isStyleCommand(e) {
        var KEY = e.key.toUpperCase();
        return ((e.ctrlKey || isKey(e, "CTRL") || (e.metaKey || isKey("META"))) &&
            (KEY === 'B' || KEY === 'I' || KEY === 'U' || KEY === 'S'));
    }

    function isKey(e, code) {
        var isIe = Often.isBrowser("ie");

        if (code === "BACK") return isCode(8, "Backspace", "Backspace");
        if (code === "TAB") return isCode(9, "Tab", "Tab")
        if (code === "ENTER") return isCode(13, "Enter", "Enter");
        if (code === "SHIFT") return isCode(16, "Shift", "Shift");
        if (code === "CTRL") return isCode(17, "Control", "Control");
        if (code === "META") return isCode(91, "Meta", "Meta");
        if (code === "ALT") return isCode(18, "Alt", "Alt");
        if (code === "ESC") return isCode(27, "Esc", "Escape");
        if (code === "SPACE_BAR") return isCode(32, "Spacebar", " ");
        if (code === "LEFT") return isCode(37, "Left", "ArrowLeft");
        if (code === "UP") return isCode(38, "Up", "ArrowUp");
        if (code === "RIGHT") return isCode(39, "Right", "ArrowRight");
        if (code === "DOWN") return isCode(40, "Down", "ArrowDown");
        if (code === "DELETE") return isCode(46, "Del", "Delete");
        if (code === "F") return isCode(70, "F", "F");
        if (code === "PLUS") return isCode(107, "Add", "+");
        if (code === "MINUS") return isCode(109, "Subtract", "-");
        if (code === "RELOAD_WINDOW") return isCode(116, "F5", "F5");
        if (code === "RELOAD_MAC") return isCode(82, "R", "R");
        if (code === "KOREAN_INPUTTING") return isCode(229, "Process", "Process");
        if (code === "CAPS_LOCK") return isCode(20, "CapsLock", "CapsLock");
        if (code === "HANJA") return isCode(25, "HanjaMode", "HanjaMode");
        if (code === "PAGE_UP") return isCode(33, "PageUp", "PageUp");
        if (code === "PAGE_DOWN") return isCode(34, "PageDown", "PageDown");
        if (code === "END") return isCode(35, "End", "End");
        if (code === "HOME") return isCode(36, "Home", "Home");
        if (code === "ARROW") return isCode(37, "Left", "ArrowLeft") ||
            isCode(38, "Up", "ArrowUp") ||
            isCode(39, "Right", "ArrowRight") ||
            isCode(40, "Down", "ArrowDown");

        function isCode(number, ieKey, Key) {
            if (Number(e.keyCode) === number) return (Number(e.keyCode) === number);
            else if (isIe) return e.key === ieKey;
            else return e.key === Key;
        }
    }

    function isAvailable(e) {
        //문자, 숫자, Backspace, Del, Space, Enter 만 이벤트 허용
        //태블릿일때는 keyCode 0이라도 통과
        return (!((e.keyCode >= 65 && e.keyCode <= 90) ||
            (e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            specialCharactersKeyCode(e.keyCode) ||
            (isKey(e, "BACK") || isKey(e, "DELETE") ||
             isKey(e, "SPACE_BAR") || isKey(e, "ENTER") || isKey(e, "KOREAN_INPUTTING")) ||
            (Often.isTablet() && e.keyCode === 0)));
    }

    // @우성호 : 비밀번호 특수문자
    // ` ~ - _ = + \ | ] } [ { ' " ; : / ? . > , < (num)/ (num)* (num)- (num)+ (num).
    function specialCharactersKeyCode(keyCode) {
        switch (keyCode) {
            case 106: case 107: case 109: case 110: case 111:
            case 186: case 187: case 188: case 189: case 190:
            case 191: case 192: case 219: case 220: case 221:
            case 222:
                return true;
            default:
                return false;
        }
    }

    //방향키 위아래 여부
    function isArrowVertical(e) {
        return isKey(e, "UP") || isKey(e, "DOWN");
    }

    function isSelectAllCommand(e) {
        var KEY = Often.null2Void(e.key).toUpperCase();
        return ((e.ctrlKey || isKey(e, "CTRL") ||
            (e.metaKey || isKey("META"))) &&
            KEY === 'A');
    }

    function isShiftEnter(e) {
        if (e.shiftKey || isKey(e, "SHIFT")) {
            if (e.keyCode === 13 || isKey(e, "ENTER")) {
                return true;
            }
        }
        return false;
    }

    function isShiftArrow(e) {
        return (e.shiftKey || isKey(e, "SHIFT")) && isKey(e, "ARROW");
    }

    function isCtrlShiftArrow(e) {
        return (e.ctrlKey || isKey(e, "CTRL") || (e.metaKey || isKey("META")))
            && (e.shiftKey || isKey(e, "SHIFT"));
    }

    function isTextDrag(e) {
        if ((isShiftArrow(e) || isCtrlShiftArrow(e)) && isKey(e, "ARROW")) {
            return true;
        } else if (isSelectAllCommand(e)) {
            return true;
        } else if ((e.shiftKey || isKey(e, "SHIFT"))
            && (isKey(e, "HOME")
                || isKey(e, "END")
                || isKey(e, "PAGE_UP")
                || isKey(e, "PAGE_DOWN"))) {
            return true;
        } else {
            return false;
        }
    }

    function isUpperCase(e) {
        var key = e.key;
        if (key.length === 1 && /[A-Z]/.test(key)) {
            return true;
        }
        return false;
    }

    function isCapsLockOn(e) {
        return e.originalEvent ? e.originalEvent.getModifierState('CapsLock') : false;
    }
})();
