const TeamsHelper = function () {
	const projectSetBtn = '<button class="teams-set-button"><i class="icons-setting-3"></i><span>Setting</span></button>';

	const isTeams = () => {
		return (((window.name === "embedded-page-container" || window.name === "extension-tab-frame") && Often.getCookie('isTeams') === 'Y')) || (Often.isMobile() != null);
	};

	const makeTeamsScreen = () => {
		if (!isTeams()) return;

		const field = $('#allMainContent');
		if (Often.isMobile()) {
			field.addClass('teams-mobile').css({'display': 'block'});
			$('body').attr('teams-mobile', 'true');
		} else {
			field.css({'display': 'block', 'min-width': 'auto'});
		}
		blockBackButton();
		removeElement();
	}

	const removeElement = () => {
		$('#openInviteLayerBtn').remove(); // 초대하기 제거
		$('.main-top').remove(); // 상단 탭 제거
		$('#leftTask').remove(); // 사이드 탭 제거
		$('#leftArea').addClass('push-left'); // 사이드 탭 옆으로 밀기
		$('#leftFilterBtn').addClass('teams-mode');
		$('#leftFilterBtn .left-btn-open .sb-close').remove(); // 사이드탭 접기 / 펴기
		$('#leftFilterBtn .left-btn-open .sb-change').remove(); // 사이드탭 접기 / 펴기
		$('#movePost').remove(); // 게시글 바로가기 버튼 제거
		$('#pinToTopBnt').remove(); // 상단고정 버튼 제거
		$('.js-setting-ul li[data-code=copy]').remove(); // 다른 프로xw젝트로 옮기기 버튼 제거
		$('#excelDownButton').remove(); // 엑셀 다운로드 버튼 제거
		$('#detailSettingTopButton').remove(); // 프로젝트 세팅 버튼
		$('#projectStar').remove(); // 프로젝트 즐겨찾기 제거
		$('.card-popup-title').remove();
		$(".left-filter").css('background', "#fff");
		$('.logo-area.logo-display-wrap').remove();
		$(".main-header-1").css('border-right', '1px solid #ddd');
		if (Often.isMobile()) {
			Profile.openPop = () => {};
			Profile.drawProfilePopup = () => {};
			$('.drag-button').remove();
			$('.project-description').remove();
		}
		$('#detailTab .js-tab-item:not([data-code=task])').remove(); // 프로젝트 탭의 업무 이외에 모두 제거
		if (!$('#detailTop .teams-set-button').length) {
			$('.project-status-group').css('display', 'flex').append(projectSetBtn).on('click', () => {
				const originUrl = window.location.origin;
				window.location.href = `${originUrl}/teams/setting`;
			});
		}
	}

	const blockBackButton = () => {
		history.pushState(null, null, location.href);
		window.onpopstate = function () {
			history.go(Often.isMobile() ? -10 : 1);
		}
	}


	return {
		isTeams: isTeams,
		makeTeamsScreen: makeTeamsScreen,
	}
}();
