var ElectronEvent = (function () {

    return {
        clickNaviBar: clickNaviBar,
        setHistoryButton: setHistoryButton,
        addReloadMouseDownEvent: addReloadMouseDownEvent,
        closeReloadLayer: closeReloadLayer,
    }

    function clickNaviBar($eTarget) {

        if (isBackBtnAndAction()) return;
        if (isForwardBtnAndAction()) return;
        if (isReloadBtnAndAction()) return;
        if (isReloadMenuAndAction()) return;

        function isBackBtnAndAction() {
            var $backBtn = $eTarget.closest('.js-history-back');
            if($backBtn.length === 0) return false;

            if(!$backBtn.find('a').hasClass('on')) return true;
            history.go(-1);
            return true;
        }

        function isForwardBtnAndAction() {
            var $forwardBtn = $eTarget.closest('.js-history-forward');
            if($forwardBtn.length === 0) return false;

            if(!$forwardBtn.find('a').hasClass('on')) return true;
            history.go(1);
            return true;
        }

        function isReloadBtnAndAction() {
            var $reloadBtn = $eTarget.closest('.js-reload-btn');
            if($reloadBtn.length === 0) return false;

            ElectronApi.Comm.reload({isClearCache: true});
            return true;
        }

        function isReloadMenuAndAction() {
            var $reloadMenu = $eTarget.closest('.js-reload-menu');
            if($reloadMenu.length === 0) return false;

            var dataCode = $reloadMenu.attr('data-code');
            if(dataCode === 'reload') ElectronApi.Comm.reload({isClearCache: false});
            else if (dataCode === 'reload-force') ElectronApi.Comm.reload({isClearCache: true});
            return true;
        }
    }

    /**
     * PC설치형 페이지 히스토리 세팅
     *
     * [고려사항]
     * 1. 플젝 세부 페이지가 첫페이지인 상태로 로딩하면 활성화
     * 2. 플젝에서 Tab 이동하면 활성화
     * 3. 메인에서 비활성화, 메인에서 화살표 눌러도 이동되지 않도록 비활성화
     */
    function setHistoryButton(isBack, isPageExist, nextPageId) {
        const $electronNavi = $('#electronNavi');
        const $historyButton = isBack ? $electronNavi.find('.js-history-back') : $electronNavi.find('.js-history-forward');
        const $historyIcon = $historyButton.find('a');

        // 새로고침이면 undefinded 아니면 값이 있음.
        if (nextPageId) {
            nextPageId === "main"
                ? $historyIcon.removeClass('on')
                : $historyIcon.addClass('on');
        } else { // 새로고침
            ViewChanger.getCurrentPageId() === "main"
                ? $historyIcon.removeClass('on')
                : $historyIcon.addClass('on');
        }
    }

    function addReloadMouseDownEvent() {
        var $reloadButton = $('#electronNavi .js-reload-btn');
        $reloadButton.off("mousedown").on("mousedown", function (e) {
            if (e.button !== 2) return;
            e.stopPropagation();
            showReloadMenu();
        })
    }

    function showReloadMenu() {
        var $reloadBtn = $("#electronReloadLayer");
        $reloadBtn.find('#js-reload').text(Often.isBrowser("mac")? 'Cmd + R' : 'F5');
        $reloadBtn.find('#js-reload-force').text(Often.isBrowser("mac")? 'Shift + Cmd + R' : 'Ctrl + F5');
        $reloadBtn.removeClass('d-none').css('display', 'block');
        $('.header').attr('oncontextmenu', 'return false');
    }

    function closeReloadLayer() {
        $("#electronReloadLayer").css('display', 'none');
    }

})();
