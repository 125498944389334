var KaKaoPixel = (function () {

    return {
        load: load
    }

    function load() {

        try {
            $.getScript("/flow-renewal/js/lib/kakaoPixel/kakaoPixel.min.js", function (data, textStatus, jqxhr) {
                if (jqxhr.status !== 200) return;
                kakaoPixel('6501044944766874017').pageView();
                kakaoPixel('6501044944766874017').completeRegistration();
            });
        } catch (e) {
            console.error('No Kakao Pixel tracking.');
        }

        //<script async src="/flow-renewal/js/lib/kakaoPixel/kakaoPixel.min.js?ver=<%=version%>"></script>
    }

})()