/**
 * Google Calendar Documentation
 *
 * Google Calendar Api에서는 Calendar과 Event의 개념이 존재 한다.
 * Calendar은 공휴일, 휴가 재택 캘린더와 같이 Event의 상위 개념이다
 * Event는 Calendar의 하위 개념으로 특정 날짜에 To-do를 뜻한다
 *
 * Flow:
 * Google Calendar의 리스트를 호출 -> List에 Flow Task, Schedule... 이 존재여부 확인한다 ->
 * 없다면 Calendar 생성, 있다면 Event 생성 -> Event Insert 요청 ->
 * 409에러(이미 존재하는 이벤트)가 발생하면 해당 Event 업데이트 그렇지 않을 경우 정상적으로 Insert
 */

var GoogleCalendar = function () {
    return {
        link: link,
        create: create,
        update: update,
        delete: remove,
        createWData: createWData,
        createAPI: createAPI,
        attendeeController: attendeeController,
    }

    function create(colaboSrno, colaboCommtSrno, isAlertNeeded = true, isToastNeeded = false) {
        if (GoogleCalendarPopupHandler(colaboSrno, colaboCommtSrno, isAlertNeeded)) return;
        //createAPI(colaboSrno, colaboCommtSrno, isToastNeeded)
    }

    function remove(colaboSrno, colaboCommtSrno) {
        if (GoogleCalendarPopupHandler(colaboSrno, colaboCommtSrno)) return;

        /*
        Ajax.executeApi(RestApi.DELETE.FLOW_GOOGLE_CALENDAR_D001, {
            'COLABO_SRNO': colaboSrno,
            'COLABO_COMMT_SRNO': colaboCommtSrno,
        })
         */
    }

    function update(colaboSrno, colaboCommtSrno, isAlertNeeded = false) {
        if (GoogleCalendarPopupHandler(colaboSrno, colaboCommtSrno, isAlertNeeded)) return;

        /*
        Ajax.executeApi(RestApi.PUT.FLOW_GOOGLE_CALENDAR_U001, {
            'COLABO_SRNO': colaboSrno,
            'COLABO_COMMT_SRNO': colaboCommtSrno,
        })
         */
    }

    function createWData(event, isToastNeeded = false, isAlertNeeded = false) {
        const record = !!event.COMMT_REC ? event.COMMT_REC[0] : event;
        const colaboSrno = !!record ? record.COLABO_SRNO : "0";
        const colaboCommtSrno = !!record ? record.COLABO_COMMT_SRNO : "0";
        if (!hasAccess()) return isAlertNeeded ? link(colaboSrno, colaboCommtSrno) : "";

        const scheduleRecord = record.SCHD_REC;
        const taskRecord = record.TASK_REC;
        if (!scheduleRecord && !taskRecord) return; // 스케줄 또는 업무가 아닐경우 동작을 하지 않는다
        if (!scheduleRecord && !!taskRecord[0] && taskRecord[0].END_DT === "") {
            return Often.toast('error', i18next.t(common.input , {val : $t(common.dueDate)}))
        }

        Ajax.executeApi(RestApi.POST.FLOW_GOOGLE_CALENDAR_C001, {
            'COLABO_SRNO': record.COLABO_SRNO,
            'COLABO_COMMT_SRNO': record.COLABO_COMMT_SRNO,
        }, () => {
            if(isToastNeeded) Often.toast('success', i18next.t(common.hasSync, {val : $t(dictionary.googleCalendar)}))
        })
    }

    function createAPI(colaboSrno, colaboCommtSrno, isToastNeeded = false) {
        Ajax.executeApi(RestApi.POST.FLOW_GOOGLE_CALENDAR_C001, {
            'COLABO_SRNO': colaboSrno,
            'COLABO_COMMT_SRNO': colaboCommtSrno,
        }, () => {
            if (isToastNeeded) Often.toast('success', i18next.t(common.hasSync, {val : $t(dictionary.googleCalendar)}))
        })
    }

    function link(colaboSrno = '0', colaboCommtSrno = '0') {
        const isPlanFunc = PlanFunc.isPlanFunc("GOOGLE_CAL");
        if (isPlanFunc === null) {
            if (!Often.isFunc("GOOGLE_CALENDAR")) return;
            if (Often.isGuest()) {
                GoogleCalendarGuestPopup();
                return;
            }
        } else if (!isPlanFunc) return;

        /*if(OutService.isSubDomain()) return;*/

        Ajax.executeApi(RestApi.POST.FLOW_GOOGLE_AUTH_C001, {
            COLABO_SRNO: colaboSrno,
            COLABO_COMMT_SRNO: colaboCommtSrno
        }, (data) => {
            if (ElectronApi.Comm.isElectron()) {
                ElectronApi.Window.open({
                    url: data.CONNECT_URL,
                    title: '_target',
                    width: '500px',
                    height: '700ox',
                    frame: true,
                    x: '100',
                    y: '100'
                });
            } else {
                window.open(data.CONNECT_URL, "_blank", "width=500px,height=700px,top=100,left=100");
            }
        })
    }

    function attendeeController(colaboSrno, colaboCommtSrno, type) {
        switch (type) {
            case 1 :
                create(colaboSrno, colaboCommtSrno);
                break;
            case 2:
                remove(colaboSrno, colaboCommtSrno);
                break;
        }
    }

    function GoogleCalendarPopupHandler(colaboSrno, colaboCommtSrno, isPopupNeeded = false) {
        const isFunctionOn = Often.isFunc("GOOGLE_CALENDAR");
        const isPlanFunc = PlanFunc.isPlanFunc("GOOGLE_CAL");
        const isGuest = Often.isGuest();
        const isIntegrated = JSON.parse(LocalUtil.getLocal('ONLY_USER_INTEGRATION')).GoogleCalendar === 'Y';
        let isTerminate = false;

        if (!isFunctionOn) {
            isTerminate = true;
        } else if (isPlanFunc === null) {
            if (!isGuest && !isIntegrated && isPopupNeeded) {
                GoogleCalendarBusinessPopup(colaboSrno, colaboCommtSrno);
                isTerminate = true;
            } else if (isGuest || !isIntegrated) {
                isTerminate = true;
            }
        } else if (isPlanFunc && !isIntegrated && isPopupNeeded) {
            GoogleCalendarBusinessPopup(colaboSrno, colaboCommtSrno);
            isTerminate = true;
        }
        return isTerminate;
    }

    function hasAccess() {
        return Often.isFunc("GOOGLE_CALENDAR") && !Often.isGuest() && JSON.parse(LocalUtil.getLocal('ONLY_USER_INTEGRATION')).GoogleCalendar === 'Y'
    }

    function GoogleCalendarBusinessPopup(colaboSrno, colaboCommtSrno) {
        PopupDraw.drawBlock({
            class: "googleCalendar",
            seeAgain: "googleCalendar",
            contents: {
                title: i18next.t(main.alert.googleCalendarTitle),
                main: i18next.t(main.alert.googleCalendarMain),
                submit: i18next.t(dictionary.connect),
            },
            callback: {
                submit: () => {
                    link(colaboSrno, colaboCommtSrno);
                }
            }
        })
    }

    function GoogleCalendarGuestPopup() {
        PopupDraw.drawBlock({
            class: "googleCalendar",
            contents: {
                title: i18next.t(main.alert.googleCalendarTitle),
                main: i18next.t(main.alert.googleCalendarMain),
                submit: i18next.t(common.upgradeService),
                link_text: i18next.t(common.showMoreDetail),
            },
            linkUrl: 'https://support.flow.team/hc/ko/articles/6646255198105',
            callback: {submit: Upgrade.showUpgradeLayer}
        })
    }
}();
