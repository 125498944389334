const Upload = (function () {
    const UploadAsync = requireModule('UploadAsync', {on: ['main', 'messenger']});

    let formDataArray;
    let fileJsonArray;
    let cancelFileJson = {};
    let successFileJson = {};
    let cancelImageJson = {};
    let queueTotalCount;
    let imageCount;
    let isUploadImageGroup;
    let imageSrcArray;

    let profileCondition = {
        MODE: 'profile',
        BASE_SIZE: 102400,
        COMP_SIZE: 102400,
        MAX_SIZE: 400
    }

    let isUploading = false;
    let isCancel = false;
    let isReUpload = false;
    let isProfileImage = false;
    let PathDivision;
    let isCameraImage = false;

    /**
     *  UPLOAD_ASYNC 기능키가 제거되면 불필요로직 전부 삭제 예정.
     *  messenger 가 아닌 main 적용은 모닝메이트 프론트개선건 작업시 함께 적용될듯.
     */
    return {
        uploadFile: (type, finishCallback, fileObjects) => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                UploadAsync()?.uploadFile(type, finishCallback, fileObjects)
                return;
            }
            return uploadFile(type, finishCallback)
        },

        putFilesAndSend: (type, fileObjects, successCallback, finishCallback) => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                UploadAsync()?.uploadFile(type, successCallback, fileObjects)
                return;
            }
            return putFilesAndSend(type, fileObjects, successCallback, finishCallback)
        },
        reSendFile: (finishCallback, abortCallback, tempChatSrno) => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                Mutil.clog('getIsUploading 제거필요!!!')
                return;
            }
            return reSendFile(finishCallback, abortCallback, tempChatSrno)
        },
        getIsUploading: () => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                Mutil.clog('getIsUploading 제거필요!!!')
                return;
            }
            return getIsUploading()
        },
        setCancelUploadInfo: (tempChatSrno, cancelInfoJson) => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                Mutil.clog('setCancelUploadInfo 제거필요!!!')
                return;
            }
            return setCancelUploadInfo(tempChatSrno, cancelInfoJson);
        },
        setPathDivision: (Path) => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                Mutil.clog('setPathDivision 제거필요!!!')
                return;
            }
            setPathDivision(Path)
        },
        getQueueTotalCount: () => {
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                Mutil.clog('getQueueTotalCount 제거필요!!!')
                return;
            }
            return getQueueTotalCount()
        },
        uploadFileWithRestriction: uploadFileWithRestriction,

    }

    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    function setPathDivision(Path) {
        PathDivision = Path;
    }

    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    function getQueueTotalCount() {
        return queueTotalCount;
    }

    function initUploadData() {
        formDataArray = [];
        fileJsonArray = [];
        imageSrcArray = [];
        queueTotalCount = 0;
        isUploading = false;
        imageCount = 0;
        isUploadImageGroup = false;
        isCancel = false;
    }

    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    function uploadFile(type, finishCallback) {
        if (Often.isFunc(Func.CLOUD.UPLOAD_PREVENT)) {
            return Often.toast('info', i18next.t(main.alert.adminRestriction));
        }
        isProfileImage = type === "profile";
        isCameraImage = type === "camera";
        const isImage = isProfileImage || isCameraImage;
        const $fileUploadInput = getFileUploadObj(!isImage, isImage)

        $fileUploadInput.on("change", function (e) {
            let element = e.srcElement || e.target;
            $fileUploadInput.remove();

            //2. 보내긴 하는데 검증하는 케이스
            if (Often.isFunc(Func.ENTER.DBFI_UPLOAD_AGREE)) {
                showAgreementPopup({
                    element: fileList,
                    finishCallback,
                    title: "대외비 문서 및 고객 개인/신용정보가 <br>포함된 일체의 문서는 업로드를 금합니다."
                })
            } else {
                putFilesAndSend(type, element.files, finishCallback);
            }
        });
        $('body').append($fileUploadInput)
        $fileUploadInput.click();
    }

    /**
     * check allowed file extension and upload file
     * @param {{type: 'audio' | 'video' | 'image' | 'any', allowedExtensions?: never, isMultipartData: boolean} | {type?: never, allowedExtensions: string[], isMultipartData: boolean}} options
     * @param {(files: FileList) => void} callback
     */
    function uploadFileWithRestriction(options, callback) {
        if (Often.isFunc(Func.CLOUD.UPLOAD_PREVENT)) {
            return Often.toast('info', i18next.t(main.alert.adminRestriction))
        }

        const $fileInput = getFileInputWithRestriction({
            ...options,
            isCameraImage,
        })

        $fileInput.on('change', function (e) {
            const elem = e.srcElement || e.target
            $fileInput.remove()

            if (Often.isFunc(Func.ENTER.DBFI_UPLOAD_AGREE)) {
                showAgreementPopup({
                    element: fileList,
                    finishCallback: callback,
                    title: `대외비 문서 및 고객 개인/신용정보가 <br>포함된 일체의 문서는 업로드를 금합니다.`
                })
                return
            }

            // TODO: type 을 가져가서 분기하는 부분 제거 필요 현재는 'any' 로 고정해서 사용
            if (Often.isFunc("UPLOAD_ASYNC") && Often.isAct("messenger")) {
                UploadAsync()?.uploadFile('any', callback, elem.files)
            } else {
                putFilesAndSend('any', elem.files, callback)
            }
        })

        $('body').append($fileInput)
        $fileInput.trigger('click')
    }

    function isAllowedImage() {
        const pathPoint = $(this).val().lastIndexOf('.');
        const filePoint = $(this).val().substring(pathPoint + 1, $(this).val().length);
        const filetype = filePoint.toLowerCase();
        let uploadAble = false;
        let errorMessage = '';

        switch (true) {
            case ['jpg', 'gif', 'png', 'jpeg', 'svg', 'ico', 'bmp'].includes(filetype) :
            case filetype === 'html' && _USE_INTT_ID === 'SEM_1' :
                //pass
                break;
            default :
                if (_USE_INTT_ID === 'SEM_1') {
                    errorMessage = '이미지 파일과 EDMS 파일만 업로드 가능합니다.';
                } else {
                    errorMessage = i18next.t(main.alert.imageOnly);
                }
                Often.toast('error', errorMessage);
                return true;
        }

        if (['jpg', 'gif', 'png', 'jpeg', 'svg', 'ico', 'bmp'].includes(filetype)) {
            uploadAble = true;
        } else if (filetype === 'html') {
            if (_USE_INTT_ID === 'SEM_1') uploadAble = true;
        } else {
            uploadAble = false;
            if (_USE_INTT_ID === 'SEM_1') errorMessage = '이미지 파일과 EDMS 파일만 업로드 가능합니다.';
            else errorMessage = i18next.t(main.alert.imageOnly);
        }

        if (!uploadAble) {
            Often.toast('error', errorMessage);
            return false;
        }

    }

    function isAllowedFileExtension() {
        let isUploadLimit;
        try {
            isUploadLimit = !EnterConfig.isMadrascheck() &&
              DgbcConfig.isDgbc() &&
              !DgbcConfig.checkUploadExtension(filetype.toLowerCase())
        } catch (e) {
            isUploadLimit = false;
        }

        if (isUploadLimit) {
            Often.toast('error', i18next.t(common.extNotAvailable));
            return false;
        } else {
            return true
        }
    }

    function showAgreementPopup({element, finishCallback, title}) {
        PopupDraw.drawConfirm({
            title: i18next.t(common.fileUploadAgreement),
            contents: {
                main: title,
                submit: i18next.t('dictionary.confirm'),
                cancel: i18next.t('dictionary.cancel'),
            },
            callback: {
                submit: function () {
                    putFilesAndSend(type, element.files, finishCallback)
                }
            }
        });
    }

    async function getFileQueue({isFile, index}) {
        if (!Often.isMessenger()) {
            return ''
        }

        const isNotQueueCount =
          Often.isMessenger()
          && !isFile
          && isUploadImageGroup
          && imageSrcArray.length !== 0

        return await MessengerSend.getQueueNumber({isNotQueueCount})
    }

    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    async function putFilesAndSend(type, fileList, successCallback, finishCallback) {
        // 1. 기능키에 따른 예외 케이스 처리
        if (isFunctionalKeyCaseHandled()) return

        //2. 그 외 리턴 케이스 처리
        if (isNotValidFileSendCase()) return

        //3. 디폴트 세팅
        UploadHandler.setCurrentUploadCount(UploadHandler.getCurrentUploadCount() + 1);
        (UploadHandler.getCurrentUploadCount() === 1) && initUploadData();

        //4. 파일 개수 리미트 체크
        if (isOverFileLimit()) return

        //5. 정상
        $.each(fileList, function (i, file) {
            if (ImageUtil.isImageExtension(file.name) && ImageUtil.isImageSize(file.size)) {
                imageCount++;
            }
        })

        if (!Often.isMessenger()) {
            await checkAndPutFiles(false);
            return
        }
        MessengerImageGroup.drawImageSendPopup({
            fileList,
            isMultiImage: imageCount > 1,
        }, checkAndPutFiles, initUploadData);

        function isFunctionalKeyCaseHandled() {
            if (Often.isFunc(Func.CLOUD.UPLOAD_PREVENT)) {
                Often.toast('info', i18next.t(main.alert.adminRestriction));
                return true
            }
            if (Often.isFunc(Func.ENTER.UPLOAD_IMAGE_ONLY) && !isAllowedImage()) {
                return true
            }
            if (Often.isFunc(Func.CLOUD.FILE_EXTENSION_BLOCK) && !isAllowedFileExtension()) {
                return true
            }
            if (Often.isFunc(Func.ENTER.CHAT_FILE_UPLOAD_OFF) && Often.isMessenger()) {
                Often.toast('info', "채팅에서는 업로드 할 수 없습니다.");
                return true
            }

            return false
        }

        function isNotValidFileSendCase() {
            if (!fileList || fileList.length === 0) {
                return true
            }
            if (FileUtil.isLimitOnMultiUpload(fileList.length, Often.isMessenger())) {
                return true
            }
            if (type !== "profile" && LimitGuest.isStorageFullGuest("data500", Often.isAct("messenger"))) {
                return true// &&로 if 조건 묶을 시 제한 팝업 우선 띄움
            }
            if (isReUpload) {
                Often.toast("error", i18next.t(common.waitUploading))
                return true
            }

            return false
        }

        function isOverFileLimit() {
            let isFileQueueLimit;
            if (Often.isFunc(Func.CLOUD.CHAT_IMAGE_GROUP_MULTI)) {
                isFileQueueLimit = (!isUploadImageGroup && fileJsonArray.length > 10) || fileJsonArray.length > 30;
            } else {
                isFileQueueLimit = fileJsonArray.length > 10 || isUploadImageGroup;
            }

            if (isFileQueueLimit) {
                Often.toast("error", i18next.t(main.upload.fileInQueue));
                return true
            }

            return false
        }

        async function checkAndPutFiles(isChecked) {
            isUploadImageGroup = isChecked;
            let isAvailableUpload = true;

            //check
            for (let i = 0; i < fileList.length; i++) {
                if (FileUtil.isAvailableFileSize(fileList[i].size)) {
                    isAvailableUpload = false;
                    break;
                }

                if (Often.isFunc(Func.ENTER.UPLOAD_IMAGE_ONLY) && isNotImageUpload()) {
                    break;
                }

                if (FileUtil.isFolder(fileList[i].name)) {
                    isAvailableUpload = false;
                    break;
                }

                if (_USE_INTT_ID === "NABO_1" && await checkFiles(fileList[i])) {
                    isAvailableUpload = false;
                    Often.toast("error", i18next.t(main.enter.disableDRM));
                    break;
                }
            }
            if (!isAvailableUpload) return;

            //putfiles
            for (let i = 0; i < fileList.length; i++) {
                const isImage = ImageUtil.isImageExtension(fileList[i].name) && ImageUtil.isImageSize(fileList[i].size);
                const isProfile = type === "profile";
                if (type === "profile" && !isImage) {
                    Often.toast("error", i18next.t(main.alert.imageOnly));
                    continue;
                }

                if (type === "file") {
                    await putFile(null, fileList[i])
                    continue
                }

                if (isImage || isProfile) {
                    putImageFiles(isProfile ? 0 : i, isProfile);
                    continue;
                }

                await putFile(null, fileList[i]);
            }

            function isNotImageUpload() {
                const pathPoint = fileList[i].name.lastIndexOf('.');
                const filePoint = fileList[i].name.substring(pathPoint + 1, fileList[i].name.length);
                const filetype = filePoint.toLowerCase();
                isAvailableUpload = false;
                let errorMessage = '';

                if (['jpg', 'gif', 'png', 'jpeg', 'svg', 'ico', 'bmp'].includes(filetype)) {
                    isAvailableUpload = true;
                } else if (filetype === 'html') {
                    if (_USE_INTT_ID === 'SEM_1') isAvailableUpload = true;
                } else {
                    isAvailableUpload = false;
                    if (_USE_INTT_ID === 'SEM_1') errorMessage = '이미지 파일과 EDMS 파일만 업로드 가능합니다.';
                    else errorMessage = i18next.t(common.onlyImageAvailable);
                }

                if (!isAvailableUpload) {
                    Often.toast('error', errorMessage);
                }
                return !isAvailableUpload
            }
        }

        function putImageFiles(index, isProfile) {
            readImageFromUrl(index).subscribe(function (imageObject) {
                if (Often.isBrowser("IE")) {
                    loadImage(fileList[imageObject.index], {orientation: true}).then(data => {
                        putFile(data.image, fileList[imageObject.index], isProfile);
                    });
                } else {
                    putFile(imageObject.image, fileList[imageObject.index], isProfile, imageObject.index); // [원래 putFile 코드]
                }
            });

            function readImageFromUrl(index) {
                return Rx.Observable.create(function (observer) {
                    const reader = new FileReader();
                    const image = new Image();
                    reader.onload = function () {
                        image.onload = function () {
                            observer.next({image: image, index: index})
                        }
                        image.src = reader.result;
                        if (window._USE_INTT_ID === "MRA_1" || Often.isFunc("UPLOAD_IMAGE_LIKE_FILE")) {
                            image.onerror = () => {
                                putFile(null, fileList[index])
                            };
                        }
                    }
                    reader.readAsDataURL(fileList[index]);
                })
            }
        }

        async function putFile(currentImage, currentFile, isProfile, index) {
            currentImage = Often.null2Void(currentImage);
            const isFile = currentImage === "";
            const isProfileThumb = isProfile;
            const thumbFile = isFile ? "" : ImageUtil.getThumbImgFile(currentImage, currentFile, (isProfileThumb ? profileCondition : null));
            const originalFile = isFile ? currentFile : isProfileThumb ? thumbFile : currentFile;
            let fileName = currentFile.name;
            const isCaptureImage = (fileName === "image.png") || (fileName.indexOf("flow_noname_image.") === 0);


            fileName = (isCaptureImage ? ImageUtil.getFileNameByClipboard() : fileName);

            let chat_GB = "";
            if ("CHAT" === (PathDivision)) {
                chat_GB = roomInfo.ROOM_GB
            }
            if ("MESSAGE" !== PathDivision && "CHAT" !== PathDivision && PathDivision === undefined && !$('#innerMySettingPopup').is(':visible')) {
                setPathDivision("COLLABO")
            }

            const fileJson = {
                USER_ID: _USER_ID,
                RGSN_DTTM: _RGSN_DTTM,
                NOW: new Date().getTime(),
                FILE: originalFile,
                WIDTH: Often.null2Void(currentImage.width, ""),
                HEIGHT: Often.null2Void(currentImage.height, ""),
                THUMB_IMG_FILE: thumbFile,
                FILE_NAME: fileName,
                ROOM_SRNO: "CHAT" === (PathDivision) ? Often.null2Void(_ROOM_SRNO, "") : '',
                IS_UPLOAD_IMAGE_GROUP: isUploadImageGroup,
                IS_PROFILE: isProfileImage,
                PathDivision: PathDivision, // 쪽지 : MESSAGE, 채팅 : CHAT , 게시글 : COLLABO
                RoomGb: chat_GB, // 프로젝트 채팅방은 3
                VOICE_WAVEFORM: VoiceMessage.getChannelDataString(),
                PLAYTIME: VoiceMessage.getPlaytime(),
            }
            if (Often.isAct("main")) {
                fileJson.COLABO_SRNO = Often.null2Void(CONNECT_PROJECT_SRNO);
            }

            if (!isFile && isUploadImageGroup) imageSrcArray.push({
                fileName: originalFile.name,
                fileSize: originalFile.size,
                imageSrc: currentImage.src,
            });

            let _fileQueue = await getFileQueue({isFile, index})

            fileJsonArray.push(fileJson);
            formDataArray.push(json2formData(fileJson));
            queueTotalCount = formDataArray.length;

            if (Often.isMessenger()) {
                fileJson.TEMP_CHAT_SRNO = _fileQueue;
                const isIeBrowserAndImage = !isFile && Often.isBrowser("ie") // IE 채팅 이미지 업로드시 Rx.Observable 동기처리 성능 문제 발생
                if (!isIeBrowserAndImage && (isFile || !isUploadImageGroup)) {
                    const drawJson = MessengerSend.drawTempFile(_fileQueue, originalFile.name, originalFile.size, (isFile ? "" : currentImage.src));
                    if (!await checkNetworkAndDraw({
                        sendJson: {...drawJson, fileJson, originalFile},
                        _fileQueue
                    })) {
                        initUploadData()
                        return;
                    }
                }
            }
            setFileJsonAndUpload({_fileQueue, isFile, successCallback, finishCallback, fileList, fileJsonArray})
        }
    }

    async function checkNetworkAndDraw({sendJson, _fileQueue}) {
        if (!Mutil.isFunc('TEMP_MESSAGE') || !Often.isMessenger()) return true

        const messengerNetworkManager = requireModule('MessengerNetworkManager', {on: ['messenger']})();
        if (messengerNetworkManager?.isNetworkAndSocketUnstable() && await messengerNetworkManager?.unstableAfterCheckNetwork({limitSec: 8})) {
            const tempMessageManager = requireModule('TempMessageManager', {on: ['messenger']})();
            tempMessageManager?.setTempMessage({
                $target: $(`#message-${_fileQueue}`),
                sendJson,
                isSending: true,
            })
            return false;
        }

        return true
    }


    async function setFileJsonAndUpload({
                                            _fileQueue,
                                            isFile,
                                            successCallback,
                                            finishCallback,
                                            fileList,
                                            fileJsonArray
                                        }) {
        if (Often.isMessenger() && !isFile) {
            successFileJson[_fileQueue] = [];
            setCancelFileJson(_fileQueue, 0);
            MessengerImageGroup.cancelUploadChatImage(_fileQueue, '', removeFile);
        }

        let drawJson = '';
        if (imageCount === imageSrcArray.length && isUploadImageGroup) {
            successFileJson[_fileQueue] = [];
            cancelImageJson[_fileQueue] = imageSrcArray;
            setCancelFileJson(_fileQueue, 0);
            if (!Often.isBrowser("ie")) drawJson = MessengerSend.drawTempImageGroup(_fileQueue, imageSrcArray, fileList);
            MessengerImageGroup.cancelUploadChatImage(_fileQueue, '', removeFile);
        }


        if (!isUploading && fileList.length === queueTotalCount) {
            if (!await checkNetworkAndDraw({
                sendJson: isUploadImageGroup ? drawJson : fileJsonArray,
                _fileQueue
            })) {
                initUploadData()
            } else {
                sendFileListToServer(successCallback, finishCallback);
            }
        }
    }


    function sendFileListToServer(successCallback, abortCallback, reSendTempChatSrno) {

        isUploading = true;

        const xhr = [];
        const uploadURL = "/FLOW_UPLOAD_C001.jct";
        const isUsePresigendUrlUpload = !Often.isFunc("AWS_SECURE") && Often.isFunc("AWS_PRESIGNED_URL_UPLOAD") && !Often.isEmpty(window.AwsS3Uploader);
        let responseLength = 1;
        let resultFileArray = reSendTempChatSrno ? successFileJson[reSendTempChatSrno] : [];
        awaitFileToServer(0, isUsePresigendUrlUpload);

        async function awaitFileToServer(fileIndex, isUsePresigendUrl = false) {
            const tempChatSrno = reSendTempChatSrno ? reSendTempChatSrno : fileJsonArray[fileIndex].TEMP_CHAT_SRNO;
            // preSignURL 사용할 경우
            if (isUsePresigendUrl) {
                AwsS3Uploader.setUploadHandler({onLoadStart, onProgress, onAbort, onError, fileProcessAfterUpload});
                const isSuccess = await AwsS3Uploader.sendFileToS3(fileJsonArray[fileIndex]);
                if (isSuccess) return;
            }

            xhr[fileIndex] = new XMLHttpRequest();
            xhr[fileIndex].upload.addEventListener('loadstart', onLoadStart, false);
            xhr[fileIndex].upload.addEventListener('progress', onProgress, false);
            xhr[fileIndex].upload.addEventListener('abort', onAbort, false);
            xhr[fileIndex].upload.addEventListener('error', onError, false);
            xhr[fileIndex].open("POST", uploadURL, true);
            xhr[fileIndex].onreadystatechange = xhrOnReadyStateChange;
            xhr[fileIndex].send(formDataArray[fileIndex]);

            function xhrOnReadyStateChange() {
                const xhrThis = this;
                const isOk = xhrThis.readyState === 4 && xhrThis.status === 200;
                if (!isOk) return;
                const fileRec = JSON.parse(xhrThis.responseText).FILE_REC;
                if (isCancel && (fileRec && fileRec[0].IMG_PATH !== "")) {
                    return cancelStep();
                }
                const result = JSON.parse(xhrThis.responseText);
                fileProcessAfterUpload(result);
            }

            function fileProcessAfterUpload(result) {
                nextStep(function () {
                    if (result.COMMON_HEAD && result.COMMON_HEAD.ERROR) {
                        const data = result.COMMON_HEAD.DATA;
                        const code = 'exception:' +  data?.ERROR_INTERNATIONAL_CODE;
                        if(i18next.exists(code)) {
                            Often.toast("error", i18next.t(code))
                        } else {
                            result.COMMON_HEAD.DATA?.ERROR_CODE === -3 ? Often.toast('error', result.COMMON_HEAD.DATA?.ERROR_MESSAGE) :
                                Often.toast('error', result.COMMON_HEAD.MESSAGE);
                        }
                        MessengerApi.reloadMessages();
                    } else {
                        successStep(result, tempChatSrno);
                    }
                }, function () {
                    UploadHandler.setCurrentUploadCount(0);
                });
            }

            function onLoadStart() {
                UploadHandler.loadStart(fileJsonArray, fileIndex, function (cancelChatSrno) {
                    if (!fileJsonArray[fileIndex].IS_UPLOAD_IMAGE_GROUP) return cancelStep();
                    removeImageGroupFile(cancelChatSrno);
                })
            }

            function onProgress(e) {
                if (e.lengthComputable) {
                    const currentTempSrno = fileJsonArray[fileIndex].TEMP_CHAT_SRNO;
                    const imageGroupCount = fileJsonArray[fileIndex].IS_UPLOAD_IMAGE_GROUP && successFileJson[currentTempSrno] ?
                      successFileJson[currentTempSrno].length + cancelFileJson[currentTempSrno].length : 0;
                    const percent = imageGroupCount ? successFileJson[currentTempSrno].length + 1 : Math.ceil(((e.loaded || e.position) / e.total) * 100);
                    UploadHandler.progress(fileJsonArray, fileIndex, percent, imageGroupCount);
                }
            }

            function onAbort() {
                (typeof abortCallback === "function") && abortCallback(fileJsonArray[fileIndex].TEMP_CHAT_SRNO);
                UploadHandler.abort();
            }

            function onError() {
                nextStep(function () {
                    UploadHandler.error(fileJsonArray, fileIndex)
                }, function () {
                    UploadHandler.finished(fileJsonArray, fileIndex)
                })
            }

            function nextStep(stepCallback, lastCallback) {
                (typeof stepCallback === "function") && stepCallback()
                if (responseLength < queueTotalCount) {
                    responseLength++;
                    awaitFileToServer(responseLength - 1, isUsePresigendUrl);
                } else {
                    isReUpload = isUploading = false;
                    (typeof lastCallback === "function") && lastCallback();
                }
            }

            function cancelStep() {
                isReUpload = isUploading = false;
                xhr[fileIndex].abort();
                UploadHandler.abort();
                isCancel = false;
            }

            function successStep(result, tempChatSrno) {
                if (!(result.FILE_REC && result.FILE_REC.length > 0)) return UploadHandler.error(fileJsonArray, fileIndex);
                const fileRecJson = result.FILE_REC[0];
                if (!fileJsonArray[fileIndex]) return;
                if (!fileJsonArray[fileIndex].IS_UPLOAD_IMAGE_GROUP || fileRecJson.THUM_IMG_PATH === "") {
                    if (fileRecJson.IMG_PATH !== "") {
                        delete successFileJson[tempChatSrno];
                        delete cancelFileJson[tempChatSrno];
                    }
                    return successCallback(fileRecJson, tempChatSrno);
                }
                let index = fileJsonArray.findIndex(function (element) {
                    return element.TEMP_CHAT_SRNO === tempChatSrno;
                })
                if (index === -1) return;

                successFileJson[tempChatSrno] = resultFileArray;
                setCancelFileJson(tempChatSrno, fileIndex + 1);
                resultFileArray.push(fileRecJson);

                if (fileIndex === (fileJsonArray.length - 1) || fileJsonArray[fileIndex + 1].TEMP_CHAT_SRNO !== tempChatSrno) {
                    successCallback(resultFileArray, tempChatSrno);
                    removeCancelFileJson(tempChatSrno);
                    resultFileArray = [];
                }
            }

            function removeImageGroupFile(cancelChatSrno) {
                removeFile(cancelChatSrno);
                if (cancelChatSrno !== tempChatSrno) return;
                responseLength -= resultFileArray.length + 1;
                resultFileArray = [];
                if (fileJsonArray.length !== 0 && cancelChatSrno < fileJsonArray[fileJsonArray.length - 1].TEMP_CHAT_SRNO) {
                    return;
                }
                isCancel = true;
            }
        }
    }

    // 업로드 할 파일 찾기
    function getFileUploadObj(isMulti, isImage) {
        if (Often.isFunc(Func.ENTER.UPLOAD_IMAGE_ONLY)) isImage = true;
        const fileUploadObj = $('<input type="file" style="display: none;">');
        fileUploadObj.attr({
            'id': 'fileUploadInput',
            'multiple': isMulti,
            'accept': (isImage ? 'image/*' : ''),
        })
        if (isCameraImage) fileUploadObj.attr('capture', 'camera');
        return fileUploadObj
    }

    /**
     * check allowed file extensions and get file input element
     * @param {{type: 'audio' | 'video' | 'image' | 'any', allowedExtensions?: never, isMultipartData: boolean, isCameraImage: boolean} | {type?: never, allowedExtensions: string[], isMultipartData: boolean, isCameraImage: boolean}} options
     * @returns {*|jQuery|HTMLElement}
     */
    function getFileInputWithRestriction(options) {
        const allowOnlyImages = Often.isFunc(Func.ENTER.UPLOAD_IMAGE_ONLY)
        const acceptString = allowOnlyImages ? 'image/*' : generateAcceptAttributeValue(options.type, options.allowedExtensions)

        const $fileInput = $(`<input type="file" style="display: none;">`)
        $fileInput.attr({
            'id': 'fileUploadInput',
            'multiple': options.isMultipartData,
            'accept': acceptString,
        })

        if (options.isCameraImage) {
            $fileInput.attr('capture', 'camera')
        }
        return $fileInput
    }

    /**
     * generate accept attribute value for file input
     * @param {'audio' | 'video' | 'image' | 'any'} type
     * @param {string[]} allowedExtensions
     * @return {string}
     */
    function generateAcceptAttributeValue(type, allowedExtensions) {
        const group = ['audio', 'video', 'image']

        if (typeof type !== 'undefined' && group.includes(type)) {
            return `${type}/*`
        }

        if (Array.isArray(allowedExtensions)) {
            return allowedExtensions.join(', ')
        }

        return ''
    }

    function json2formData(json) {
        const formData = new FormData();
        for (let key in json) {
            formData.append(key, json[key]);
        }
        return formData;
    }

    //Todo. 이미지 묶어보내기 재전송 처리 부분 정리(해당 부분 시작으로 해서 마지막까지 묶어보내기 관련 함수)
    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    function reSendFile(successCallback, abortCallback, tempChatSrno) {
        isReUpload = isUploading = true;
        fileJsonArray = cancelFileJson[tempChatSrno];
        imageSrcArray = cancelImageJson[tempChatSrno];
        formDataArray = [];
        $.each(fileJsonArray, function (i, fileJson) {
            formDataArray.push(json2formData(fileJson));
        });
        queueTotalCount = formDataArray.length;
        sendFileListToServer(successCallback, abortCallback, tempChatSrno);
    }

    function setCancelFileJson(tempChatSrno, fileIndex) {
        cancelFileJson[tempChatSrno] = [];
        for (let i = fileIndex; i < fileJsonArray.length; i++) {
            if (fileJsonArray[i].TEMP_CHAT_SRNO !== tempChatSrno) continue;
            const fileJson = fileJsonArray[i];
            if (fileJson.THUMB_IMG_FILE) cancelFileJson[tempChatSrno].push(fileJson);
        }
        if (cancelFileJson[tempChatSrno].length < 2) return;
        let insertJson = {
            room_srno: _ROOM_SRNO,
        }
        insertJson[tempChatSrno] = {
            cancelFileJson: cancelFileJson[tempChatSrno],
            successFileJson: successFileJson[tempChatSrno],
            imageArray: cancelImageJson[tempChatSrno],
        }
        //IndexedDBUtil.setValue(_ROOM_SRNO, insertJson);
    }

    function removeCancelFileJson(tempChatSrno) {
        cancelFileJson[tempChatSrno] = []
        successFileJson[tempChatSrno] = [];
        //IndexedDBUtil.deleteValue(_ROOM_SRNO, tempChatSrno);
    }

    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    function getIsUploading() {
        return isUploading;
    }

    /** @deprecated UPLOAD_ASYNC 기능키가 messenger 가 아닌 main 에 모두 전체오픈되면 삭제 예정. */
    function setCancelUploadInfo(tempChatSrno, cancelInfoJson) {
        cancelFileJson[tempChatSrno] = cancelInfoJson.cancelFileJson;
        $.each(cancelFileJson[tempChatSrno], function (i, cancelFile) {
            cancelFile.TEMP_CHAT_SRNO = tempChatSrno;
        })
        successFileJson[tempChatSrno] = cancelInfoJson.successFileJson;
        cancelImageJson[tempChatSrno] = cancelInfoJson.imageArray;
        MessengerSend.drawTempImageGroup(tempChatSrno, cancelInfoJson.imageArray);
        MessengerImageGroup.cancelUploadChatImage(tempChatSrno, successFileJson[tempChatSrno].length);
        $("#message-" + tempChatSrno).find("#imageCountGraph").trigger("click");
    }

    function removeFile(cancelChatSrno) {
        for (let i = 0; i < fileJsonArray.length; i++) {
            if (fileJsonArray[i].TEMP_CHAT_SRNO !== cancelChatSrno) continue;
            fileJsonArray.splice(i, 1);
            formDataArray.splice(i, 1);
            queueTotalCount--;
            i--;
        }
    }

    async function checkFiles(file) {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        let promise = new Promise((resolve, _) => {
            reader.onload = function () {
                const strDrm = "<DOCUMENTSAFER"
                const strResult = reader.result;
                let targetStr = "";
                if (strResult.length > 14) targetStr = strResult.substring(0, 14);
                strDrm === targetStr ? resolve(true) : resolve(false);
            }
            reader.onerror = function () {
                resolve(false);
            }
        });
        let result = await promise;
        return result;
    }
})();
