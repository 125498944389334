var FileUtil = (function () {

    return {
        openFlowDrive: openFlowDrive,

        //only-get
        getFileSize: getFileSize,
        getFileNameAndExtension: getFileNameAndExtension,
        getStandardExtension: getStandardExtension,
        getFileName: getFileName,
        getSearchDate: getSearchDate,
        getAtchSrno: getAtchSrno,
        getRandKey: getRandKey,
        getUseInttId: getUseInttId,
        getIconClassNameByExt: getIconClassNameByExt,
        getIconClassNameByFullName: getIconClassNameByFullName,

        //etc
        isAvailableFileSize: isAvailableFileSize,
        checkAndConvertDriveExtClass: checkAndConvertDriveExtClass,
        convertDriveSize: convertDriveSize,
        initSetting: initSetting,
        isAtchAccessible: isAtchAccessible,
        saveFile: saveFile,
        checkFileOnServer: checkFileOnServer,
        checkViewerExtension: checkViewerExtension,
        isFolder: isFolder,
        isLimitOnMultiUpload: isLimitOnMultiUpload,
        isCloudFile: isCloudFile,
        replaceLocalDomain: replaceLocalDomain,

        openFile: openFile,
        downFile: downFile,

        isVideoViewerExtension: isVideoViewerExtension,
    }

    /** Note. openFile
     * mode
     * 1. SAVE
     * 2. DOC-VIEWER
     * fileJsonArray
     * 1. ATCH_SRNO : 파일 존재 체크 (필수값 )
     * 2. RAND_KEY : 파일 존재 체크 (필수값 )
     * 3. USE_INTT_ID : 파일의 이용기관 아이디 ( 다운로드 ) (필수값)
     * 4. FILE_NM : 파일 원본 이름 (문서 뷰어) (필수값)
     * 5. COLABO_SRNO : 프로젝트 존재 여부 및 권한 체크 (선택값)
     * 6. COLABO_COMMT_SRNO : 글 존재 여부 및 권한 체크 (선택값)
     * 7. ROOM_SRNO : 채팅방 존재 여부 (선택값)
     * 8. ROOM_CHAT_SRNO : 채팅 메세지 존재 여부 (선택값)
     * isSecretMsg : 시크릿 메세지 여부 (DOC-VIEWER)
     */

    function openFile(mode, fileJsonArray, isSecretMsg, isSurveyDiscription) {
        const fileName = getFileName(fileJsonArray[0]);
        const _isCloudFile = isCloudFile(getRandKey(fileJsonArray[0]));
        const isDocViewerExtension = checkViewerExtension(fileName);
        const totalLength = fileJsonArray.length
        const isElectron = ElectronApi.Comm.isElectron()
        const isEcmHtmlFile = Often.isFunc('ECM_HTML_DOWNLOAD') && /\.(html|htm)$/i.test(fileName);
        if(isElectron){
            const isImprovedFuncOn = Often.isFunc("ELECTRON_IMPROVED_FILE_DOWNLOAD")
            if(mode ==="SAVE"){
                if(window.isDownloadOnGoing === true && isImprovedFuncOn ) return Often.toast('error', '다중파일 다운로드 종료 후 다시 시도해주세요 !')
            }
            fileJsonArray.forEach(function (v, i) {
                (function (x) {
                    v["IS_LAST_FILE"] = totalLength-1 === x;
                    v["FILE_COUNT"] = totalLength;
                    v["IS_FIRST_REQ"] = x === 0
                    v["IS_IMG_BUNDLE"] = totalLength > 1;
                })(i);
            });
        }

        if (Often.isFunc("STREAM_VIEWER") && mode !== "SAVE" && isVideoViewerExtension(fileName) && !_isCloudFile && !isEcmHtmlFile) {
            if (!(
                Often.isBrowser('chrome') ||
                Often.isBrowser('edge') ||
                Often.isBrowser('whale')
            )) {
                saveFile(fileJsonArray);
                return;
            }

            /**
             * @우성호: mode: STREAM_VIEWER -> DOC_VIEWER 로 통일합니다.
             * STREAM_VIEWER 모드로 보내도 서버에서 DOC_VIEWER와 로직이 다르지 않습니다.
             * 모바일에서 DOC_VIEWER로 모드를 통일했기 때문에, 똑같이 DOC_VIEWER로 통일하겠습니다.
             */

            if (!Often.isAct('messenger') && !location.href.includes('/subscreen.act?GB=CHAT_COLLECTION')) {
                streamViewer(fileJsonArray, () => saveFile(fileJsonArray));
                return;
            }

            checkFileOnServer(fileJsonArray, "STREAM_VIEWER", function (pathArray) {
                const {FILE_PATH} = pathArray[0];
                VideoViewer.checkLoadable(FILE_PATH).then(isLoadable => {
                    if (isLoadable) {
                        OpenUtil.openSubScreen({GB: 'STREAM_VIEWER', FILE_JSON: fileJsonArray[0]});
                    } else {
                        downFiles(pathArray);
                    }
                })
            });
            return;
        }

        if (_isCloudFile) {
            externalFile(fileJsonArray);
            return;
        }

        if (mode === "SAVE" || !isDocViewerExtension || isEcmHtmlFile) {
            saveFile(fileJsonArray);
            return;
        }

        if (mode === "DOC-VIEWER") {
            DocViewer.openDocViewer(fileJsonArray, isSecretMsg, isSurveyDiscription);
            return;
        }

        Often.toast("error", "Check file!");
    }

    function checkFileArray(fileArray = []) {
        const existArray = fileArray && fileArray.length > 0;
        if (!existArray) Often.null2Void("error", i18next.t(main.alert.select, {val: $t(dictionary.file)}));
        return existArray;
    }

    function externalFile(fileArray) {
        if (!checkFileArray(fileArray)) return ;
        const externalFileArrray = fileArray.map(v => {
            v.EXTERNAL_FILE_URL = replaceLocalDomain(v.FILE_DOWN_URL);
            return v;
        })
        checkFileOnServer(externalFileArrray, "DOC-VIEWER", downFiles);
    }

    function saveFile(fileArray) {
        if (!checkFileArray(fileArray)) return ;
        checkFileOnServer(fileArray, "SAVE", downFiles);
    }

    function checkDeviceId() {
        const cookieDeviceId = Often.getCookie("FLOW_DUID");
        if (Often.null2Void(cookieDeviceId, "") !== "") return;

        const deviceJson = Often.getDeviceJson(window?._CLIENT_IP);
        Often.setCookie("FLOW_DUID", deviceJson.DUID);
    }

    function checkFileOnServer(fileArray, mode, callback) {
        let isLastFile = false
        let fileCount, isFirstReq, reqID, isImgBundle
        const ATCH_REC = fileArray.map(v => {
            const atchSrno = Often.null2Void(v.ATCH_SRNO, '');
            if (!atchSrno) {
                return;
            }
            if (Often.isFunc("UNNECESSARY_INFO")) {
                v.THUM_IMG_PATH = null;
                v.IMG_PATH = null;
                v.ATCH_URL = null;
                v.FILE_DOWN_URL = null;
                v.SRC = null;
            }else {
                v.FILE_DOWN_URL = replaceLocalDomain(v.FILE_DOWN_URL);
            }
            isLastFile = Often.null2Void(v.IS_LAST_FILE)
            fileCount = Often.null2Void(v.FILE_COUNT)
            isFirstReq = Often.null2Void(v.IS_FIRST_REQ)
            // reqID = self.crypto.randomUUID().replace(/-/g,'') // @우성호: https://flow.team/l/0IX47
            isImgBundle = Often.null2Void(v.IS_IMG_BUNDLE)
            return v;
        }).filter(atch => atch); // undefined 제거

        checkDeviceId();
        Ajax.executeApi(RenewalApi.GET.ACT_FILE_CHECK, $.extend({MODE: mode}, {
            ATCH_REC,
            packetOption: Ajax.OPTION.PREVENT_EXECUTE
        }), function (fileData) {
            const {ERR_MSG, FILE_REC} = fileData;
            const isArray = ATCH_REC.length > 1
            if (ERR_MSG.length > 0) {
                Often.toast("error", ERR_MSG);
                return;
            }
            let idx = 0

            const fileArray = FILE_REC.map(v => {
                v.FILE_DOWN_URL = convertLocalUrl(replaceLocalDomain(v.FILE_DOWN_URL));
                v.FILE_PATH = convertLocalUrl(replaceLocalDomain(encodeURI(v.FILE_PATH)));
                v.IS_LAST_FILE = isArray ? ATCH_REC[idx].IS_LAST_FILE : isLastFile;
                v.FILE_COUNT = isArray ? ATCH_REC[idx].FILE_COUNT : fileCount;
                v.IS_FIRST_REQ = isArray ? ATCH_REC[idx].IS_FIRST_REQ : isFirstReq;
                v.FILE_SEQ = idx;
                v.REQ_ID = reqID;
                v.IS_IMG_BUNDLE = isImgBundle
                if (idx === ATCH_REC.length-1) idx = 0
                else idx += 1
                return v;
            });
            (typeof callback === "function") && callback(fileArray);
        });
    }

    function convertLocalUrl(url) {
        if (!url) return url;
        if (location.hostname === 'localhost') {
            return url.replace('https://flowtest.info', 'http://' + location.host);
        } else {
            return url
        }
    }

    function streamViewer(fileArray, saveCallback) {
        const $flowStreamViewer = $('#flowStreamViewer');
        if ($flowStreamViewer.length === 0) {
            Often.toast("error", "check Dom Object!");
            return;
        }

        const mode = Often.isFunc("USE_STREAMING_MODE") ? "STREAMING" : "STREAM_VIEWER";

        checkFileOnServer(fileArray, mode, function (pathArray) {
            const {ERR_MSG} = pathArray[0];
            if (ERR_MSG != null) {
                Often.toast("error", ERR_MSG);
                return;
            }
            const {FILE_PATH} = pathArray[0];
            const {FILE_SIZE, RGSR_NM, RGSN_DTTM} = fileArray[0];
            VideoViewer.load($flowStreamViewer, {
                fileName: getFileName(fileArray[0]),
                fileSize: getFileSize(FILE_SIZE),
                userName: RGSR_NM,
                fileDate: RGSN_DTTM,
                fileUrl: FILE_PATH,
                saveCallback,
            });
        });
    }

    function replaceLocalDomain(url) {
        url = Often.null2Void(url, '');
        if (url.includes('127.0.0.1') === false) return url;
        const idx = url.indexOf('/FLOW_DOWNLOAD_R001.act');
        const path = url.substring(idx);
        return Often.getLocOrigin() + path;
    }

    function downFiles(fileRecord) {
        var t = 0;
        var errorFileMessage = "";
        $.each(fileRecord, function (i, file) {

            if (file.ERR_MSG) return (errorFileMessage = file.ERR_MSG);
            var isExternalFile = file.EXTERNAL_FILE_URL && file.EXTERNAL_FILE_URL.length > 0;
            var filePath = Often.null2Void(isExternalFile ? file.EXTERNAL_FILE_URL : file.FILE_PATH);
            let isLastFile = file.IS_LAST_FILE
            let fileCount = Often.isFunc("ELECTRON_IMPROVED_FILE_DOWNLOAD") ? file.FILE_COUNT : 0 ; // fileCount 가 0 이면 예전로직탐
            let isFirstReq = file.IS_FIRST_REQ
            let requestId = file.REQ_ID
            let isImgBundle = file.IS_IMG_BUNDLE
            let fileSeq = file.FILE_SEQ
            if (filePath === "") return Often.toast("error", i18next.t(main.alert.filePath));
            setTimeout(function () {
                downFile(filePath, isExternalFile, {isLastFile, fileCount, isFirstReq, requestId, isImgBundle, fileSeq})
            }, (Often.isGlobal() ? 2000 : 800) * t);
            t++;
        });
        (errorFileMessage && Often.toast("error", errorFileMessage));
    }

    function replaceDomain(filePath) {
        const isLocalTest = location.href.includes("http://localhost") || location.href.includes("http://127.0.0.1");
        if (!isLocalTest && window._ENTER_YN === "Y") filePath = filePath.replace('http:', 'https:');
        filePath = filePath.replace(/(https:\/\/)(hotfix.|release.|staging.|develop.|)(flow.team|morningmate.com)/ig, location.protocol + '//' + location.host);
        return filePath;
    }

    function downFile(filePath, isExternalFile, options={}) {
        const makeFileDownPath = (filePath = '' , isExternalFile = false) => {
            if(Often.isEmpty(filePath)) {
                return '';
            }

            const filePathForReplacedDomain = replaceDomain(filePath);
            if(isExternalFile) {
                return filePathForReplacedDomain;
            }

            const isHaveParameters = filePathForReplacedDomain.indexOf("?") > -1 ;
            if(isHaveParameters) {
                return `${filePathForReplacedDomain}&v=${Time.currentTime()}`;
            }

            return `${filePathForReplacedDomain}?v=${Time.currentTime()}`;
        }

        var fileDownPath = makeFileDownPath(filePath, isExternalFile);


        const isMobis = () => {
            try {
                return Mobis.prototype.isMobis() && !Mobis.prototype.checkInnerIp();
            } catch (e) {
                return false;
            }
        }
        const isHec = () => {
            try {
                return Hec.prototype.isHec() && !Hec.prototype.checkVpnIpCheck();
            } catch (e) {
                return false;
            }
        }
        if (isMobis() || isHec()) {
            Often.toast('error', '사내 대역에서만 다운로드 가능합니다.');
            return;
        }
        if (ElectronApi.Comm.isElectron()) {
            if (isExternalFile) return ElectronApi.Window.openExternalLink({url: fileDownPath });

            ElectronApi.Download.download({
                url: fileDownPath,
                fileName: '',
                fileSize: '',
                winInfo: Often.isMessenger() ? Often.null2Void(_ROOM_SRNO,"") : "",
                isLastFile: options.isLastFile,
                fileCount: options.fileCount,
                isFirstReq: options.isFirstReq,
                requestId: options.requestId,
                isImgBundle: options.isImgBundle,
                fileSeq: options.fileSeq
            });
            // Often.isMessenger() ? Electron.downloadFileForChat(fileDownPath, '', '', _ROOM_SRNO) : Electron.downloadFile(fileDownPath);
            return;
        }

        if (window.location.hostname.indexOf("flow.samsungsem.com") > -1) {
            // isExternalFile = true;
            window.open(fileDownPath, "_blank");
            Often.session2local(); // window.open : _parent 옵션은 beforeunload 이벤트를 발생시키므로 local teb 데이터를 설정 해줘야 합니다.
            return;
        }

        if (Often.isBrowser("safari")) {
            window.open(fileDownPath, isExternalFile ? "_blank" : "_parent");
        } else {
            const downloadLink = document.createElement('a');
            downloadLink.href = fileDownPath;
            downloadLink.download = 'file';
            downloadLink.style.display = 'none';
            downloadLink.target = isExternalFile ? "_blank" : "_self";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }

        /* 파일 다운로드를 직접 제어할 수 있음
        var xhr = new XMLHttpRequest();
        xhr.open('GET', fileDownPath, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (xhr.status === 200) {
                var blob = xhr.response;
                var downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = 'myfile.txt';
                downloadLink.style.display = 'none';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadLink.href);
            }
        };
        xhr.send();
        */
        Often.session2local(); // window.open : _parent 옵션은 beforeunload 이벤트를 발생시키므로 local teb 데이터를 설정 해줘야 합니다.
    }

    function openFlowDrive(responseType) {
        var isPost = (responseType === "POST");
        OpenUtil.openSubScreen({
            GB: "flowDrive",
            CURRENT_PROJECT_SRNO: (isPost ? ViewChanger.getProjectSrno() : ''),
            RESPONSE_TYPE: responseType,
            CONNECT_ID: SocketControl.getRoomConnectId(),
        });
    }

    function getFileName(fileJson) {
        return (fileJson.ORCP_FILE_NM ? $.trim(fileJson.ORCP_FILE_NM) :
            fileJson.FILE_NAME ? $.trim(fileJson.FILE_NAME) :
                fileJson.FILE_NM ? $.trim(fileJson.FILE_NM) : i18next.t(dictionary.unTitled));
    }

    /**
     * @param {string} fileByteSize
     * @returns {string} - 소수점 둘째짜리까지 표기됨
     */
    function getFileSize(fileByteSize) {
        if (fileByteSize && isNaN(+fileByteSize)) return fileByteSize;
        if (!fileByteSize || +fileByteSize === 0) return "-";
        var isOver1MB = fileByteSize > 1024 * 1024;
        var isOver1KB = fileByteSize > 1024;
        if (isOver1MB) return (Math.round((fileByteSize / (1024 * 1024) * 100)) / 100.0) + " MB";
        if (isOver1KB) return (Math.round((fileByteSize / (1024) * 100)) / 100.0) + " KB";
        return fileByteSize + " B";
    }

    function getFileNameAndExtension(fullName) {
        if (!fullName) return {NAME: "", EXT: ""}
        var startIndex = fullName.lastIndexOf(".");
        var lastIndex = fullName.length;
        var fileName = startIndex === -1 ? fullName : fullName.substring(0, startIndex);
        var fileExt;
        try {
            fileExt = startIndex === -1 ? "" : fullName.substring(startIndex + 1, lastIndex).toLowerCase();
        } catch (e) {
            fileExt = "";
        }
        return {NAME: fileName, EXT: fileExt}
    }

    function getIconClassNameByExt(randKey, ext) {
        return checkAndConvertDriveExtClass(randKey, getStandardExtension(ext));
    }

    function getIconClassNameByFullName(randKey, nameExt) {
        return checkAndConvertDriveExtClass(randKey, getStandardExtension(getFileNameAndExtension(nameExt).EXT));
    }

    function checkAndConvertDriveExtClass(randKey, extensionClass) {
        return (randKey === "CLOUD" ? "cloud" :
            randKey === "GOOGLEDRIVE" ? "google-drive" :
                randKey === "DROPBOX" ? "dropbox" :
                    randKey === "ONEDRIVE" ? "onedrive" :
                    extensionClass)
    }

    function isCloudFile(randKey) {
        return randKey === "GOOGLEDRIVE" || randKey === "DROPBOX" || randKey === "ONEDRIVE";
    }

    function convertDriveSize(randKey, size) {
        var returnValue;
        if (randKey === "GOOGLEDRIVE") {
            returnValue = i18next.t(main.fileUtil.fileFrom, {val: "Google Drive"})
        } else if (randKey === "DROPBOX") {
            returnValue = i18next.t(main.fileUtil.fileFrom, {val: "Dropbox"})
        } else if (randKey === "ONEDRIVE") {
            returnValue = i18next.t(main.fileUtil.fileFrom, {val: "One Drive"})
        } else {
            returnValue = size;
        }
        return returnValue;
    }

    function getStandardExtension(ext) {
        if (Often.null2Void(ext) === "") return "ETC";
        var EXTENSION = {
            DEFAULT: ['default'],
            CLOUD: ['googleDrive', 'dropbox', 'onedrive'],
            PDF: ['pdf'],
            WORD: ['doc', 'docx'],
            EXCEL: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltxm', 'xls', 'xlt', 'xls', 'xlam'],
            HWP: ['hwp'],
            PPT: ['ppt', 'pptx'],
            IMAGE: ['png', 'jpeg', 'jpg', 'bmp', 'rle', 'gif', 'pddv', 'tif', 'tiff', 'raw', 'al', 'eps', 'svg', 'svgz',
                'iff', 'fpxv', 'frm', 'pcx', 'pct', 'pic', 'pxr', 'sct', 'tga', 'vda', 'icb', 'vst'],
            MEDIA: ['avi', 'mpg', 'mpeg', 'mpe', 'wmv', 'asf', 'asx', 'flv', 'rm', 'mov', 'dat', 'mpeg1', 'mpeg2', 'mpeg4',
                'mp3', 'mp4', 'ogg', 'wma', 'wav', 'au', 'mid'],
            HTML: ['html'],
            AUTOCAD: ['dwg', 'dwgc', 'dws', 'dxf', 'dwt', 'plt', 'lsp'],
            ZIP: ['alz', 'ace', 'arc', 'arj', 'b64', 'bh', 'bhx', 'bin', 'bz2', 'cab', 'ear', 'enc', 'gz', 'ha', 'hqx',
                'ice', 'img', 'jar', 'lha', 'lzh', 'mim', 'pak', 'rar', 'sit', 'tar', 'tgz', 'uue', 'war', 'xxe', 'z',
                'zip', 'zoo', '001'],
            //Todo. 필터없음
            TXT: ['txt'],
            PSD: ['psd'],
            AI: ['ai'],
        }
        var returnKey;
        for (var key in EXTENSION) {
            if (EXTENSION[key].join('|').indexOf(ext.toLowerCase()) > -1) {
                returnKey = key;
            }
        }
        return Often.null2Void(returnKey, "ETC").toLowerCase();
    }

    function isVideoViewerExtension(fileName) {
        const {EXT} = getFileNameAndExtension(fileName);
        const standardExtension = getStandardExtension(EXT);
        return "media" === standardExtension;
    }

    function checkViewerExtension(fileName) {
        var DOC_EXTENSION = ["DOC", "DOCX", "DOCM", "DOTM", "DOT", "DOTX", "PPT", "PPTX", "PPTM", "POT", "POTX", "POTM", "PPS", "PPSX", "ODT", "XLS", "XLSX", "XLSM", "XLT", "XLTX", "XLTM", "XLSB", "THMX", "CSV", "PDF", "TXT", "GIF", "PNG", "TIF", "TIFF", "JPG", "JPEG", "BMP", "EML", "MSG", "HWP", "HWX", "HWPX", "XML", "HML", "HTML", "MHT", "MHML"];
        var tempExtension = $.trim(fileName.split(".").pop());
        var extension = tempExtension.toUpperCase();
        var isDocViewerExtension = DOC_EXTENSION.indexOf(extension) > -1;
        return isDocViewerExtension;
    }

    function initSetting() {
        setFileLimitSize();
    }

    function setFileLimitSize() {
        if (!Often.isFunc(Func.CLOUD.AD_FILE_UPLOAD)) return;
        Ajax.executeApi(RestApi.GET.COLABO2_FILESIZE_CONF_R001, {
            PTL_ID: _PTL_ID,
            CHNL_ID: _CHNL_ID,
            USE_INTT_ID: _USE_INTT_ID,
        }, function (dat) {
            LocalUtil.setLocalJson("ONLY_FILE_LIMIT_SIZE", {
                WEB_PROJ_SIZE: Often.null2Void(dat.WEB_PROJ_SIZE, "0"),
                WEB_CHAT_SIZE: Often.null2Void(dat.WEB_CHAT_SIZE, "0"),
                MB_PROJ_SIZE: Often.null2Void(dat.MB_PROJ_SIZE, "0"),
                MB_CHAT_SIZE: Often.null2Void(dat.MB_CHAT_SIZE, "0"),
                WEB_MESSAGE_SIZE: Often.null2Void(dat.WEB_MESSAGE_SIZE, "0"),
                MB_MESSAGE_SIZE: Often.null2Void(dat.MB_MESSAGE_SIZE, "0"),
            });
        })
    }

    function isAvailableFileSize(size) {
        var GUEST_LIMIT_SIZE = 10485760; //10MB
        var DEFAULT_MAX_SIZE = Often.isFunc('AD_FILE_UPLOAD_UNLIMITED')? 32212582400 : 524288000; // 30GB : 500MB
        const GLOBAL_DEFAULT_MAX_SIZE = 1048576000;
        var buyCode = LocalUtil.getLocalValue("ONLY_BUY_SETTING", "BUY_YN");
        var sizeNumber = Number(size);
        var DB_LIMIT_SIZE = Number(LocalUtil.getLocalValue("ONLY_FILE_LIMIT_SIZE", "WEB_" + (Often.isAct("main") ? "PROJ" : "CHAT") + "_SIZE"));
        if (Often.isAct("letter") || Often.isAct("editor")) {
            DB_LIMIT_SIZE = Number(LocalUtil.getLocalValue("ONLY_FILE_LIMIT_SIZE", "WEB_MESSAGE_SIZE"));
        }
        var isUpgradePopup = false;
        var currentLimitSize;

        const isPlanUpSize = PlanFunc.isLikePlanFunc('UPLOAD_SIZE');
        if (Often.isFunc(Func.CLOUD.AD_FILE_UPLOAD) && !isNaN(DB_LIMIT_SIZE) && DB_LIMIT_SIZE > 0) currentLimitSize = (DB_LIMIT_SIZE * 1024 * 1024);
        // else if (ServerChecker.isZoomok) currentLimitSize = ZoomokConfig.get("FILE_LIMIT_SIZE_B");
        // else if (ServerChecker.isHyundaicar) currentLimitSize = HyundaicarConfig.get("FILE_LIMIT_SIZE_B");
        // else if (ServerChecker.isDbfi) currentLimitSize = DbfiConfig.get("FILE_LIMIT_SIZE_B");
        // else if (ServerChecker.isBgf) currentLimitSize = BgfConfig.get("FILE_LIMIT_SIZE_B");
        else if (isPlanUpSize !== null) { // USE_PLAN 기능키 안키면 null
            // @우성호: 플랜관리 기능키 (USE_PLAN)을 사용중일 경우, 상품용 기능키로 파일용량을 제한 합니다.
            // 자세한 내용: (https://indecisive-pan-8f7.notion.site/76188e3c3e0646a483144ce6f344605d)
            let popupType;
            if (!isPlanUpSize) {
                currentLimitSize = GUEST_LIMIT_SIZE;
                popupType = "updata10"
            } else {
                currentLimitSize = PlanFunc.getPlanFuncFileSize('UPLOAD_SIZE');
                const func = LocalUtil.getLocal("ONLY_FUNC_LIST").match(/PLAN_UPLOAD_SIZE_\d{1,3}[A-Z]/)?.[0];
                let size = func.match(/\d{1,3}/)?.[0];
                const unit = func.match(/\w$/)?.[0];

                if (unit === 'G') size = `${+size * 1000}`;
                else if (Often.isFunc('AD_FILE_UPLOAD_UNLIMITED')) size = '30000';
                popupType = `updata${size}`;
            }

            if (sizeNumber > currentLimitSize) PlanFunc.drawLimitPlanPopup(popupType);
        } else if (buyCode !== BuyCode.FREE) currentLimitSize = DEFAULT_MAX_SIZE;
        else {
            isUpgradePopup = true;
            currentLimitSize = ServerChecker.isKyungrinara ? Kyungrinara.get("FILE_GUEST_LIMIT_SIZE_B") : GUEST_LIMIT_SIZE;
        }


        if (currentLimitSize === 0) currentLimitSize = DEFAULT_MAX_SIZE;
        if (Often.isGlobal() && (currentLimitSize > GLOBAL_DEFAULT_MAX_SIZE && !Often.isFunc('AD_FILE_UPLOAD_UNLIMITED'))) currentLimitSize = GLOBAL_DEFAULT_MAX_SIZE;
        if (sizeNumber < currentLimitSize) return false;
        if (!isUpgradePopup) {
            var inputFileSize = getFileSize(currentLimitSize);
            if (inputFileSize === '0 B') Often.toast("error", i18next.t(main.alert.adminRestriction));
            else Often.toast("error", "PC에서 첨부파일 등록은 최대 " + inputFileSize + "까지만 가능합니다.");
            return true;
        }

        var limitType = `data${ServerChecker.isKyungrinara ? Kyungrinara.get("FILE_GUEST_LIMIT_SIZE_MB") : "10"}`;
        LimitGuest.isLimitGuest(limitType, Often.isMessenger());

        return true;
    }

    function setFileExtensionBlockList() {
        if (!Often.isFunc(Func.CLOUD.FILE_EXTENSION_BLOCK)) return;
        //Todo. 사용처가 없어 주석해둠

        // Ajax.executeApi(RestApi.GET.COLABO2_FILE_EXTENSION_BLOCK_R001, {}, function (dat) {
        //     var returnArray = [];
        //     dat.FILE_EXTENSION_LIST.forEach(function (v) {
        //         returnArray.push(v.EXTENSION);
        //     })
        //     LocalUtil.setLocal("ONLY_EXTENSION_BLOCK_LIST", returnArray);
        // })
    }

    function isAtchAccessible(projectAuthYn, writerYn, managerYn, expryYn) {
        if (Often.null2Void(expryYn, "Y") === "Y") return false;
        if (Often.null2Void(projectAuthYn, "N") === "N") return true; // 프로젝트에 다운로드가 전체 허용이거나
        if (Often.null2Void(managerYn, "N") === "Y") return true; // 본인이 관리자거나
        if (Often.null2Void(writerYn, "Y") === "Y") return true; // 본인이 글을 섰을때 true
        return false;
    }

    function getSearchDate(dateCode) {
        var today = new Date();
        var returnJson = {
            START_DT: "",
            FNSH_DT: "",
        }
        returnJson.FNSH_DT = Time.currentTime('YYYYMMDD999999'); // 종료 날짜
        if (dateCode === "7") {
            today.setDate(today.getDate() - dateCode);
        } else if (dateCode === "99") {

        } else {
            today.setMonth(today.getMonth() - dateCode);
        }
        returnJson.START_DT = Time.currentTime('YYYYMMDD000000');  // 시작날짜

        return returnJson;
    }

    function getAtchSrno(data) {
        var array = [];
        if (Array.isArray(data)) {
            return Often.toast("error", i18next.t(main.alert.listUnavailable));
        } else if (typeof data === "string") {
            array = (data.split(":"));
        } else if (typeof data === "object") {
            if (Object.keys(data).length === 0) return "";
            if (!data.ATCH_SRNO) return "";
            array = (data.ATCH_SRNO.split(":"));
        }
        var isRandKeySumAtchSrno = ((array.length > 2 && (array[1].indexOf("FLOW_") > -1 || array[1].indexOf("GOOGLE") > -1 || array[1].indexOf("DROP") > -1)) || array.length === 3);
        var isTagSumAtchSrno = (array.length === 2);
        return (array[(isRandKeySumAtchSrno ? 2 : isTagSumAtchSrno ? 1 : 0)])
    }

    function getRandKey(data) {
        var array = [];
        if (Array.isArray(data)) {
            return Often.toast("error", i18next.t(main.alert.listUnavailable));
        } else if (data && (data.RAND_KEY && data.RAND_KEY.length > 0)) {
            return data.RAND_KEY;
        } else if (data && (data.FILE_IDNT_ID && data.FILE_IDNT_ID.length > 0)) {
            return data.FILE_IDNT_ID;
        } else if (typeof data === "string") {
            array = (data.split(":"));
        } else if (typeof data === "object") {
            if (Object.keys(data).length === 0) return;
            if (!data.ATCH_SRNO) return "";
            array = (data.ATCH_SRNO.split(":"));
        }
        var isRandKeySumAtchSrno = (array.length > 1);
        return (isRandKeySumAtchSrno ? array[1] : "");
    }

    function getUseInttId(data) {
        var url1 = getUseInttIdByUrl(data.ATCH_URL);
        if (url1 !== "") return url1;
        var url2 = getUseInttIdByUrl(data.DOWNLOAD_URL);
        return url2;

        function getUseInttIdByUrl(atchUrl) {
            if (!atchUrl) return "";
            var keyword = "&USE_INTT_ID=";
            var idIndex = atchUrl.indexOf(keyword);
            if (idIndex === -1) return "";
            var nextIndex = atchUrl.indexOf("&", idIndex + 1);
            if (nextIndex === -1) {
                return atchUrl.substring(idIndex + keyword.length);
            } else {
                return atchUrl.substring(idIndex + keyword.length, nextIndex);
            }
        }
    }

    function isFolder(fileName) {
        var isFolder = fileName.indexOf(".") == "-1";
        if (isFolder) Often.toast("error", i18next.t(main.alert.uploadFolder));
        return isFolder;
    }

})()

function isLimitOnMultiUpload(fileCount, isTry) {
    if (isTry && fileCount > 30) {
        Often.toast("success", i18next.t(main.upload.limit, {count: 30}));
        return true;
    }
    return false;
}
