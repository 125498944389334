const Validation = (function () {

    //Note. 신버전은 좀 더 명확한 이메일 정규식을 활용함!(비밀번호 찾기, 회원 가입 등 활용), 로그인은 Validation 체크하지 않음
    const emailRex = /^([\w_+])*[a-zA-Z0-9]*([\w._\-])*([a-zA-Z0-9])+([\w_])*@([a-zA-Z0-9\-]+\.)+[a-zA-Z0-9]{2,8}$/g;
    const urlRex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g; //
    const postUrlRex = /^https?:\/\/(?:\w+?\.)?(?:flow\.team|flowtest\.info|morningmate\.com)\/l\/\w+$/; // 포스트 url
    const teamUrlRex = /^[0-9a-zA-Z\-]{3,50}$/;
    const specialRex = /[~!@$%^&*+|?:;<>{}\[\]★☆]/; // 특수문자
    const emojiRex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/ig;
    const domainRex = /^[0-9a-z_+]*$/g;
    const phoneNumberRex = /^[+?(\d*|\-!)]{1,20}$/g;
    const numberRex = /^[0-9]+$/;
    const passwordRex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*\-_+=,./?:;'"{<>()\[}\]\\|])[A-Za-z\d`~!@#$%^&*\-_+=,./?:;'"{<>()\[}\]\\|]{8,20}$/g; // 8~20자 영문, 숫자, 특수문자
    const loginPasswordRex = /^.{1,20}$/g; // 기존 계정 중 password 숫자+특문으로만 되어있는 경우 고려하여 로그인 시 자리수만 체크
    const moreThanEightRex = /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z]).*$/g; // 8글자 이상 특수문자 포함
    const koreanRex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/ig;
    const upperCaseRex = /[A-Z]/;
    const ipv4Rex = /^(?!0{1,3}.0{1,3}.0{1,3}.0{1,3})(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/;
    // const subDomainRex = /[\w\-]+\.flow\.team/ig;
    const moreThanFifteenRex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[`~!@#$%^&*\-_+=,./?:;'"{<>()\[}\]\\|])[A-Za-z\d`~!@#$%^&*\-_+=,./?:;'"{<>()\[}\]\\|]{15,30}$/g; // 15~30자 대/소문자 구분, 숫자, 특수문자
    const asciiRex = /^[!-~]*$/ // 아스키코드를 체크하는 정규식 (33번부터 126번까지)
    // todo [] : 멘션 이슈, <> : xss 이슈
    const nameSpecialRex = /[#<>\[\]]/;
    // flags emoji regex
    const flagEmojiRex = /\p{Regional_Indicator}/ug


    return {
        // GETTER
        getPasswordRex: getPasswordRex,
        getEmailRex: getEmailRex,
        getSpecialRex: getSpecialRex,
        getNameSpecialRex: getNameSpecialRex,
        getIpv4Rex: getIpv4Rex,
        getFlagEmojiRex: getFlagEmojiRex,

        // Check Functions
        checkInput: checkInput,
        checkEditable: checkEditable,
        checkPrivacyValidate: checkPrivacyValidate,
        checkContainUpperCase: checkContainUpperCase,
        isDetectPrivacy: isDetectPrivacy,
        checkPasswordAllowedCharacters: checkPasswordAllowedCharacters,
        checkCountryCode: checkCountryCode,
    }

    function checkEditable($editableObject, isToast, type) {
        const isEmpty = ("" === $.trim(TagConvert.html2DbStringByPost($editableObject.html())));
        let returnJson = {};
        const errorCode = (isEmpty ? "empty" : "");
        const isError = "" !== errorCode;
        if (isError) {
            switch (type) {
                case 'temporary': {
                    returnJson = {
                        errorObj: $editableObject,
                        errorCode: errorCode,
                        errorMessage: i18next.t(main.alert.noTemporaryContents), //#####번역필요#####
                    }
                    break;
                }
                default: {
                    returnJson = {
                        errorObj: $editableObject,
                        errorCode: errorCode,
                        errorMessage: i18next.t(common.enterContext, {val: $t(dictionary.article)}),
                    }
                    break;
                }
            }
            if (isToast) {
                Often.toast("error", returnJson.errorMessage)
                returnJson.errorObj.focus();
            }
        }
        return returnJson
    }

    function checkInput($inputObjects, isToast, type) {
        let isEmpty = false;
        let isOver = false;
        let isUnValid = false;
        let targetObj;
        let returnJson = {};

        $.each($inputObjects, function (i, inputEl) {

            const value = $.trim($(inputEl).val());
            const maxLength = Number(Often.null2Void($(inputEl).attr('maxlength'), '50'));
            const validCode = Often.null2Void($(inputEl).attr('data-valid'), '');
            const isRequired = "Y" === Often.null2Void($(inputEl).attr('data-required-yn'), 'N');

            if (!isEmpty && "" === value && isRequired) {
                isEmpty = true;
                targetObj = $(inputEl);
            } else if (!isEmpty && !isOver && maxLength < value.length) {
                isOver = true;
                targetObj = $(inputEl);
            } else if (!isEmpty && !isOver && !isUnValid && value.length > 0) {
                isUnValid = isUnValidValue(validCode, value, inputEl);
                isUnValid && (targetObj = $(inputEl))
            }
        })

        const errorCode = (isEmpty ? "empty" : isOver ? "over" : isUnValid ? "un-valid" : "");
        const isError = "" !== errorCode;
        if (targetObj && isError) {
            switch (type) {
                case 'temporary': {
                    returnJson = {
                        errorObj: targetObj,
                        errorCode: errorCode,
                        errorMessage: i18next.t(main.alert.noTemporaryContents),
                    }
                    break;
                }

                default: {
                    returnJson = {
                        errorObj: targetObj,
                        errorCode: errorCode,
                        errorMessage: targetObj.attr('data-' + errorCode + '-msg'),
                    }
                    break;
                }
            }

            if (isToast) {
                Often.toast("error", returnJson.errorMessage)
                returnJson.errorObj.focus();
            }
        }

        return returnJson
    }

    function isUnValidValue(validCode, value, inputEl) {
        const isEmoji = new RegExp(emojiRex).test(value);
        const isNameSpecial = new RegExp(nameSpecialRex).test(value);
        const isValidName = !isNameSpecial && !isEmoji;
        const validInputCheck = new RegExp(/^[+?(\d*|\-?!\-))*]{1,20}$/g).test(value);
        const continuousSign = new RegExp(/[-+]{2,}/g).test(value);
        const isNumberExist = new RegExp(/\d/).test(value);
        const isPlusFirst = new RegExp(/^([^+]*\+)?[^+]*$/).test(value);
        const isMinusMiddle = !new RegExp(/^-|-$/).test(value);
        const isValidPhoneNumber = validInputCheck && !continuousSign && isNumberExist && isPlusFirst && isMinusMiddle;

        return (
            ("teamUrl" === validCode && !new RegExp(teamUrlRex).test(value)) ||
            ("name" === validCode && !isValidName) ||
            ("email" === validCode && !new RegExp(emailRex).test(value)) ||
            ("password" === validCode && !new RegExp(getPasswordRex()).test(value)) ||
            ("password-confirm" === validCode && !isSamePassword($(inputEl))) ||
            ("domain" === validCode && !new RegExp(domainRex).test(value)) ||
            ("phoneNumber" === validCode && !isValidPhoneNumber) ||
            ("number" === validCode && !new RegExp(numberRex).test(value)) ||
            ("login-password" === validCode && !new RegExp(loginPasswordRex).test(value))
        )
    }

    function getPasswordRex() {
        if (Often.isFunc(Func.ENTER.PWD_REG_MORE_THAN_EIGHT)) return moreThanEightRex;
        if (Often.isFunc(Func.ENTER.PWD_REG_MORE_THAN_FIFTEEN)) return moreThanFifteenRex;
        return passwordRex;
    }

    function getEmailRex() {
        return emailRex;
    }

    function getSpecialRex() {
        return specialRex;
    }

    function getNameSpecialRex() {
        return nameSpecialRex;
    }

    function getIpv4Rex() {
        return ipv4Rex;
    }

    function getFlagEmojiRex() {
        return flagEmojiRex
    }

    function isSamePassword(password2Obj) {
        const $form = password2Obj.parents('form');
        return ($form.length > 0 && $form.find(".js-join-password").length > 0 &&
            password2Obj.val() === $form.find(".js-join-password").val())
    }
    function getKoreanRex() {
        return new RegExp(koreanRex);
    }

    function checkKoreanInPassword(inputValue, callback) {
        const isCheckBrowser = Often.isBrowser("mac") && Often.isBrowser("whale")
            || Often.isBrowser("mac") && Electron.isElectronApp() || Often.getClientOSInfo().isWin;

        if (isCheckBrowser) {
            if (koreanRex.test(inputValue)) {
                callback();
                return true;
            }
        }

        return false;
    }

    /**
     * @우성호 : 패스워드 안에 허용하지 않는 문자가 들어가는지 체크합니다.<br>
     * (8자 이상의 영문, 숫자, 특수문자)
     * @param inputValue 패스워드 값
     * @param callback 패스워드 안에 한글이 들어있으면 실행되는 콜백 함수
     */
    function checkPasswordAllowedCharacters(inputValue, callback) {
        if (!asciiRex.test(inputValue)) {
            callback?.();
            return true;
        }
        return false;
    }

    /**
     * 입력값 중 대문자가 있는 지 확인
     *
     * @param inputValue 입력값
     */
    function checkContainUpperCase(inputValue) {
        if (inputValue.length === 0) return false;
        return new RegExp(upperCaseRex).test(inputValue)
    }

    function isDetectPrivacy(text) {
        if (Often.isFunc("PRIVACY_VALIDATE_CHECK")) {
            const words = text.split(/ |\n/);

            for (let i = 0; i < words.length; i++) {
                if (!checkPrivacyValidate(words[i])) return true;
            }
            return false;
        }
    }

    function checkCountryCode(ntnlCd) {
        //FlowUtil.getCnplNtlCd에 있는 국가코드 목록
        const countryCodeArr = ["82", "233", "241", "592", "220", "590", "502", "1671", "1473", "30", "299", "245", "224"
            , "224", "238", "264", "234", "211", "27", "31", "599", "977", "47", "64", "687", "227", "505", "886", "45"
            , "1767", "1809", "49", "670", "856", "231", "371", "7", "961", "266", "40", "352", "250", "218", "370", "423"
            , "261", "853", "389", "265", "60", "223", "596", "52", "377", "212", "230", "222", "258", "382", "1664", "373", "960", "356"
            , "976", "1", "1340", "691", "678", "973", "1246", "1242", "880", "1441", "229", "58", "84", "32", "375", "501", "387", "267"
            , "591", "257", "226", "975", "359", "55", "673", "685", "966", "357", "378", "239", "590", "221", "381", "1758", "1784", "1869"
            , "508", "252", "249", "597", "248", "94", "268", "46", "41", "34", "421", "386", "963", "232", "65", "971", "297", "374", "54"
            , "354", "509", "353", "994", "93", "1264", "376", "355", "213", "244", "1268", "372", "593", "503", "44", "1284", "967"
            , "968", "61", "43", "504", "962", "256", "598", "998", "380", "251", "964", "98", "972", "20", "39", "91", "62", "81",
            , "1876", "260", "240", "850", "995", "86", "236", "253", "350", "263", "235", "420", "56", "237", "7", "974", "855", "254"
            , "269", "506", "225", "57", "242", "243", "53", "965", "682", "385", "996", "686", "992", "255", "66", "1649", "90", "228"
            , "676", "993", "216", "1868", "507", "595", "675", "970", "298", "51", "351", "48", "1787", "33", "594", "689", "679", "358"
            , "63", "36", "852"];
        return countryCodeArr.indexOf(ntnlCd) > -1;
    }

    function checkPrivacyValidate(text) {
        if (text.match(/[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]/)) {
            return true;
        } else if (text.match(/\d{2}(?:0[1-9]|1[0-2])([0-2][0-9]|3[0-1]){1}(-|\^-\^|\^-|-\^|\.|\.|\^\.\^|\^\.|\.\^| |\^\^|\^ | \^|\^)[1-4]\d{6}/)) {
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 주민등록번호가 확인되어 차단하였습니다.");
            return false;
        } else if (text.match(/\d{2}(?:0[1-9]|1[0-2])([0-2][0-9]|3[0-1]){1}[1-4]\d{6}/)) {
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 주민등록번호가 확인되어 차단하였습니다.");
            return false;
        } else if (text.match(/\d{2}(0[1-9]|1[0-2])([0-2][0-9]|3[0-1]){1}(-|\^ -\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^| |\^\^|\^ | \^ |\^|\^)[5-8]\d{6}/)) {
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 외국인등록번호가 확인되어 차단하였습니다. ");
            return false;
        } else if (text.match(/\d{2}(0[1-9]|1[0-2])([0-2][0-9]|3[0-1]){1}[5-8]\d{6}/)) {
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 외국인등록번호가 확인되어 차단하였습니다. ");
            return false;
            // DGB캐피탈 요청 (이메일, 전화번호 허용)
            // } else if (text.match(/[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/)) {
            //     Often.toast('error',  "이메일을 입력할 수 없습니다.");
            //     return false;
            // } else if (text.match(/(?:\(?(?:02|0[3-4][1-3]|0[5-6][1-4]|070)\)?(-|\^-\^|\^-|-\^)?\d{3,4}(-|\^-\^|\^-|-\^)?\d{4}|01[016-9](-|\^-\^|\^-|-\^)?\d{3,4}(-|\^-\^|\^-|-\^)?\d{4})/)) {
            //     // ex) 021231234, 02-1231234, 02-123-1234, 02123-1234
            //     Often.toast('error',  "전화번호를 입력할 수 없습니다.");
            //     return false;
            // } else if (text.match(/(?:[A-Z]{1}\d{8})/)) {
            //     //정상적인 전자여권 번호 외 다른 번호가 들어올 시 여권번호 입력하라는 오류 수정 ( 2018/07/26 JM)
            //     return true;
            // } else if (text.match(/(?:[a-z]{1}\d{8})/)) {
            //     // 전자여권
            //     Often.toast('error',  "전자여권 번호를 입력할 수 없습니다.");
            //     return false;
        } else if (text.match(/(?:[A-Z]{1}\d{7})/)) {
            // 기존여권
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 여권번호가 확인되어 차단하였습니다.");
            return false;
        } else if (text.match(/(?:[a-z]{1}\d{7})/)) {
            // 기존여권
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 여권번호가 확인되어 차단하였습니다.");
            return false;
        } else if (text.match(/(?:서울|부산|대구|인천|광주|대전|울산|세종|경기|강원|충북|충남|전북|전남|경북|경남|제주|11|12|13|14|15|16|17|18|19|20|21|22|23|25|26)(-|\^-\^|\^-|-\^| |\^ \^|\^ |\^|\^)\d{2}(-|\^-\^|\^-|-\^)\d{6}(-|\^-\^|\^-|-\^)\d{2}/)) {
            Often.toast('error', "개인정보보호법 제24조 및 제29조에 따라 운전면허번호가 확인되어 차단하였습니다.");
            return false;
        }

        // DGBC는 계좌번호, 카드번호, 사업자등록번호는 체크하면 안됩니다.
        if (ServerChecker.isDgb) {
            if (text.match(/(?:[0,1,2,3,9]\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{6}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{3}|[0,1,2,3,9]\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.\.\^|\^)\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{8}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{1}|[0,1,2,3,9]\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{9}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{1}|[0,1,2,3,5,6,7,9]\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{6}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{1}|[0,1,2,3,9]\d{2}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{2}(-|\^-\^|\^-|-\^|      \.|\^\.\^|\^\.|\.\^|\^)\d{6})/)) {
                Often.toast('error', i18next.t("계좌번호를 입력할 수 없습니다."));
                return false;
            } else if (text.match(/(?:[0,1,2,3,9]\d{2}\d{2}\d{6}\d{3}|[0,1,2,3,9]\d{2}\d{2}\d{8}\d{1}|[0,1,2,3,9]\d{2}\d{9}\d{1}|[0,1,2,3,5,6,7,9]\d{2}\d{2}\d{6}\d{1}|[0,1,2,3,9]\d{2}\d{2}\d{6})/)) {
                Often.toast('error', i18next.t("계좌번호를 입력할 수 없습니다."));
                return false;
            } else if (text.match(/\d{4}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)(?:31)\d{2}(-||\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{4}(-|\^-\^|\^-|-\^|\.|\^\.\^|\^\.|\.\^|\^)\d{4}/)) {
                Often.toast('error', i18next.t("카드번호를 입력할 수 없습니다."));
                return false;
            } else if (text.match(/d\{4}(?:31)\d{2}\d{4}\d{4}/)) {
                Often.toast('error', i18next.t("카드번호를 입력할 수 없습니다."));
                return false;
            } else if (text.match(/(?:0|7[135]|10[014-9]|11[0347]|12[0-689]|13[012578]|14[0-2]|20[1469]|21[012457]|22[02-7]|30[0-5678|31[0-456]|40[0-4789]|41[012567]|50[0-8]|51[0-5]|60[023679]|61[0-357]|62[01])(-|\^-\^|\^-|-\^)(?:[0-7][1-9]|[1-7][0-9]|0[0-9]|8[1-79])(-|\^-\%|\%-|-\%)\d{5}/)) {
                Often.toast('error', i18next.t("사업자등록번호를 입력할 수 없습니다."));
                return false;
            } else if (text.match(/(?:0|7[135]|10[014-9]|11[0347]|12[0-689]|13[012578]|14[0-2]|20[1469]|21[012457]|22[02-7]|30[0-5678]|31[0-456]|40[0-4789]|41[012567]|50[0-8]|51[0-5]|60[023679]|61[0-357]|62[01])(?:[0-7][1-9]|[1-7][0-9]|0[0-9]|8[1-79])\d{5}/)) {
                Often.toast('error', i18next.t("사업자등록번호를 입력할 수 없습니다."));
                return false;
            }
        }
        return true;
    }
})();
