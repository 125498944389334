
const SuperAdmin = {
    showSuperAdminPopup
}


let SuperAdminPageData = {
    PG_NO: 0,
    PG_PER_CNT: 10,
    NEXT_YN: "Y",
    ZERO_FIRST: true,
    SRCH_WORD: "",
    SRCH_TYPE: "ALL",
    SORT_GB : "DEFAULT",
    SORT_DESC: "DESC",
}

const $superAdminPop  = document.getElementById("superAdminPop");
const $tbody = $($superAdminPop).find("tbody");

function showSuperAdminPopup() {
    initPopup();
    $superAdminPop.style.display = "";
}

function hideSuperAdminPopup() {
    $superAdminPop.style.display = "none";
}

function initPopup() {
    $tbody.empty();
    $("#SRCH_WORD").val(""); // 인풋값 초기화
    $($superAdminPop).find("option:selected").prop("selected", false) //선택된 값 제거
    initSuperAdminPageData();
    addEventOnSuperAdminPopup();
    drawUserList();
    userListScrollEvent();
    clearFilter();
    setTimeout(()=>{
        $("#SRCH_WORD").focus();
    },100);

}


function clearFilter() {
    $($superAdminPop).find(".sort_r").removeClass("sort_r").addClass("sort");
}

function toggleFilter($eTarget) {
    const targetClass = $eTarget.attr("class");

    if(targetClass.indexOf("_r") > -1) $eTarget.removeClass("sort_r").addClass("sort")
    else $eTarget.removeClass("sort").addClass("sort_r")
}

function initSuperAdminPageData() {
    SuperAdminPageData.PG_NO=0;
    SuperAdminPageData.PG_PER_CNT=10;
    SuperAdminPageData.NEXT_YN="Y";
    SuperAdminPageData.ZERO_FIRST=true;
    SuperAdminPageData.SRCH_WORD="";
    SuperAdminPageData.SRCH_TYPE="ALL";
    SuperAdminPageData.SORT_GB="DEFAULT";
    SuperAdminPageData.SORT_DESC="DESC";
}

function initSuperAdminPageDataWithoutSrch() {
    SuperAdminPageData.PG_NO=0;
    SuperAdminPageData.PG_PER_CNT=10;
    SuperAdminPageData.NEXT_YN="Y";
    SuperAdminPageData.ZERO_FIRST=true;
    SuperAdminPageData.SORT_GB="DEFAULT";
    SuperAdminPageData.SORT_DESC="DESC";
}
function initSuperAdminPageDataWithoutSort() {
    SuperAdminPageData.PG_NO=0;
    SuperAdminPageData.PG_PER_CNT=10;
    SuperAdminPageData.NEXT_YN="Y";
    SuperAdminPageData.ZERO_FIRST=true;
    SuperAdminPageData.SRCH_WORD="";
    SuperAdminPageData.SRCH_TYPE="ALL";
}
function initSuperAdminPageDataWithoutSortSrch() {
    SuperAdminPageData.PG_NO=0;
    SuperAdminPageData.PG_PER_CNT=10;
    SuperAdminPageData.NEXT_YN="Y";
    SuperAdminPageData.ZERO_FIRST=true;
}

function drawUserList() {
    Ajax.executeApi("ACT_AFFILIATE_USER_LIST",
        {
            LIMIT : SuperAdminPageData.PG_PER_CNT,
            OFFSET: SuperAdminPageData.PG_NO === 0 ? 0 :SuperAdminPageData.PG_NO * SuperAdminPageData.PG_PER_CNT,
            SRCH_WORD: SuperAdminPageData.SRCH_WORD,
            SRCH_TYPE: SuperAdminPageData.SRCH_TYPE,
            SORT_GB: SuperAdminPageData.SORT_GB,
            SORT_DESC: SuperAdminPageData.SORT_DESC,
        }
        , (dat) => {
            if(dat.NEXT_YN != 'Y') SuperAdminPageData.NEXT_YN = 'N';
            $.each(dat.USER_LIST, function (i, user) {
                const isSuperAdmin = user.SUPER_ADMIN_YN === "Y";
                $tbody.append(ListHelper.replaceJson($("#superAdminTdItem").find("tbody").html(), {
                    "cmnm": user.CMNM,
                    "name": user.FLNM,
                    "user-id": user.USER_ID,
                    "jbcl-nm": user.JBCL_NM,
                    "clph-no": user.CLPH_NO,
                    "is-selected": isSuperAdmin ? "selected" : "",
                    "admin-text": isSuperAdmin ? "관리자 [해제]":"관리자 추가",
                    "use-intt-id": user.USE_INTT_ID,
                }))
            })
            SuperAdminPageData.PG_NO++;
        });
}

function drawSearchUserList() {
    initSuperAdminPageDataWithoutSort();
    // clearFilter();
    SuperAdminPageData.SRCH_WORD = Often.null2Void($("#SRCH_WORD").val(), "");
    if(Often.null2Void($("#SRCH_WORD").val())) {
        SuperAdminPageData.SRCH_TYPE = $($superAdminPop).find("option:selected").val();
    } else {
        SuperAdminPageData.SRCH_TYPE = "ALL";
    }

    $tbody.empty(); // 기존 리스트 지우기

    drawUserList();
}


function userListScrollEvent() {
    ListHelper.initPageData(SuperAdminPageData);
    $(".list_table-scroll").drawListHelper({
        pageData: SuperAdminPageData,
        nextYn: "Y",
        notEmpty: true,
        callback: {
            scroll: async function () {
                if(SuperAdminPageData.NEXT_YN !== 'Y') return;
                await drawUserList();
            },
        },
        fixScroll: true,
    })
}


function getTrEl() {
    return `<tr data-id="" data-name="" data-value=""></tr>`
}

function getTdEl(className, text) {
    return `<td className='${className}'>${text}</td>`
}

function getBtnEl(className, text, userId) {
    return `<button data-id='${userId}' class='${className}'>${text}</button>`
}


function addEventOnSuperAdminPopup() {
    $($superAdminPop).off("click").on("click", (e) => {
        const $eTarget = $(e.target);
        if(!$eTarget) return;
        const eTargetClass = Often.null2Void($eTarget.attr("class"), "");
        switch(true) {
            case eTargetClass.indexOf('js-pop-close') > -1 || eTargetClass.indexOf('js-confirm-btn') > -1 :
                // 팝업 닫기로직
                hideSuperAdminPopup();
                //end
                break;
            case eTargetClass.indexOf('js-search-btn') > -1 :
                drawSearchUserList();
                break;
            case eTargetClass.indexOf('js-specify-btn') > -1 :
                //버튼 토글 로직
                let isSelected = eTargetClass.indexOf('selected') > -1;
                if(isSelected) $eTarget.removeClass('selected').text('관리자 추가');
                else $eTarget.addClass('selected').text('관리자 [해제]');
                //end
                const $targetTr = $eTarget.findUp("tr");
                const targetUseInttId = $targetTr.find(".js-admin-pop-cmnm").data("useInttId");
                const targetUserNm = $targetTr.find(".js-admin-pop-name").text();

                //슈퍼어드민 추가로직
                Ajax.executeApi("ACT_SUPER_ADMIN_C001",
                    {
                        YN_VAL:isSelected ? "N" : "Y",
                        LEVEL_USER_ID: $eTarget.data("id"),
                        LEVEL_USE_INTT_ID: targetUseInttId,
                        SRCH_USER_NM: targetUserNm,
                    }, (dat)=>{
                    let isSelected = eTargetClass.indexOf('selected') > -1;
                    if(isSelected) {
                        $eTarget.removeClass('selected').text('관리자 추가');
                        Often.toast('info', '관리자가 해제되었습니다.');
                    } else {
                        $eTarget.addClass('selected').text('관리자 [해제]');
                        Often.toast('info', '관리자가 추가되었습니다.')
                    }
                })
                //end
                break;
            case eTargetClass.indexOf('dimmed') > -1 : {
                hideSuperAdminPopup();
                break;
            }
            case eTargetClass.indexOf('sort') > -1 : {
                // clearFilter();
                let curruntActiveFilter = $($superAdminPop).find(".sort_r");
                if($($superAdminPop).find(".sort_r").length === 1
                   && curruntActiveFilter.data("value") !== $eTarget.data("value")) clearFilter();
                toggleFilter($eTarget);

                initSuperAdminPageDataWithoutSrch();
                SuperAdminPageData.SORT_GB = $eTarget.data("value");
                SuperAdminPageData.SORT_DESC = $eTarget.attr("class").indexOf("_r") > -1 ? "DESC" : "ASC";
                console.log(SuperAdminPageData)
                $tbody.empty();
                drawUserList();
            }
        }
    });

    $($superAdminPop).off('keyup').on('keyup', function(e) {
        const keyCode = e.keyCode;

        switch(keyCode) {
            case 13 : drawSearchUserList();
                break;
            case 27: hideSuperAdminPopup();
                break;
        }
    })
}