/**
 * 로그인 정보 암호화 모듈
 * 브라우저에서 JSEncrypt 또는 CryptoJS를 지원하지 않을 경우, undefined 리턴
 * @returns {undefined|{encrypt: (function(Object): string)}}
 * @constructor
 */
var FlowCrypto = function () {
    // 암호화 모듈이 지원되지 않는 브라우저에 대한 방어 로직
    if (!window.JSEncrypt || !window.CryptoJS) return undefined;

    return {
        /**
         * Encrypt a plain Object data
         * @param {Object} data
         * @returns {string}
         */
        encrypt: function (rsaPublicKey, data) {
            var rsaEncryptor = new JSEncrypt();
            rsaEncryptor.setPublicKey(rsaPublicKey);
            return rsaEncryptor.encrypt(data);
            // var encrypted = CryptoJS.AES.encrypt(data, rsaPublicKey);

            // var jsonBody = JSON.stringify(data);
            // var encrypted = CryptoJS.AES.encrypt(jsonBody, rsaPublicKey);
            // var key = CryptoJS.enc.Base64.stringify(encrypted.key);
            // var iv = CryptoJS.enc.Base64.stringify(encrypted.iv);
            // var ciphertext = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
            // var secret = rsaEncryptor.encrypt(key + '::' + iv);
            //
            // return ciphertext + '.' + secret;
        },
        decrypt: function (rsaPrivateKey, data) {
            const rsaEncryptor = new JSEncrypt();
            rsaEncryptor.setPrivateKey(rsaPrivateKey);
            return rsaEncryptor.decrypt(data);
        }
    };
};