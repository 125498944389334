var RenewalApi = (function () {
    return {
        getTotalCount: function () {
            //Note. 16개
            return Object.keys(RenewalApi.GET).length + Object.keys(RenewalApi.POST).length +
                Object.keys(RenewalApi.PUT).length;
        },
        //조회 및 체크
        GET: {
            ACT_POST_BY_TINY_URL: "POST_URL", //ACT_POST_BY_TINY_URL, POST_URL
            ACT_ROOM_LIST: "CHATTING", //ACT_ROOM_LIST, CHATTING
            ACT_ALARM_LIST: "ALARM", //ACT_ALARM_LIST, ALARM

            ACT_FILE_CHECK: "ACT_FILE_CHECK", // 파일 권한 체크
            ACT_ORG_USERLIST: "ACT_ORG_USERLIST",
            ACT_POST_LIST: "ACT_POST_LIST",
            ACT_PROJECT_LIST: "ACT_PROJECT_LIST",
            ACT_POST_WORKER_LIST: "ACT_POST_WORKER_LIST", // 포스트 담당자 조회
            ACT_SEARCH_FILE_LIST: "ACT_SEARCH_FILE_LIST",
            ACT_SEARCH_POST_N_REMARK_LIST: "ACT_SEARCH_POST_N_REMARK_LIST",
            ACT_PROJECT_INFO: "ACT_PROJECT_INFO",
            ACT_TASK_LIST: "ACT_TASK_LIST",
            ACT_FILE_LIST: "ACT_FILE_LIST",
            ACT_EXIST_YN_BY_POST_N_REMARK: "ACT_EXIST_YN_BY_POST_N_REMARK",
            ACT_ONE_TASK_STATE: "ACT_ONE_TASK_STATE",   // 단일 업무 상태 조회
            ACT_PREVIOUS_CHAT_LIST: "ACT_PREVIOUS_CHAT_LIST",   //답장 상위메세지 기준 조회
            ACT_FLOW_UPDATE_HISTORY: "ACT_FLOW_UPDATE_HISTORY",   // 플로우 pc앱 키워드 지정 다운로드링크
            ACT_AUTO_LOGIN_BY_AD: "ACT_AUTO_LOGIN_BY_AD",   // AD ID로 자동로그인
            ACT_ORG_CHART_ACTIVE_YN: "ACT_ORG_CHART_ACTIVE_YN", //조직도 관리 활성화 여부
            ACT_SET_CHAT_MANAGER: "ACT_SET_CHAT_MANAGER", //채팅 관리자 지정/지정해제
            ACT_GET_ID_BY_EMPNO: "ACT_GET_ID_BY_EMPNO", // USER ID로 EMP NO 받아오기
            ACT_SECTION_TASK_LIST: "ACT_SECTION_TASK_LIST", //섹션까지 함께 조회
            ACT_SECTION_LIST: "ACT_SECTION_LIST", //섹션리스트 조회
            ACT_FIND_TEMPORARY_POST: "ACT_FIND_TEMPORARY_POST", //임시 저장 글 조회
            ACT_FIND_TEMPORARY_COUNT: "ACT_FIND_TEMPORARY_COUNT", //임시 저장 글 갯수 조회
            ACT_RECEIVED_MESSAGE_READ: "ACT_RECEIVED_MESSAGE_READ",
            ACT_GANTT_FILTER_LIST: "ACT_GANTT_FILTER_LIST", //간트차트 필터 리스트 조회
            ACT_GANTT_COLUMN_LIST: "ACT_GANTT_COLUMN_LIST", //간트차트 컬럼 리스트 조회
            ACT_PROJECT_LIST_BY_TASK: "ACT_PROJECT_LIST_BY_TASK", //업무 필터에 해당하는 프로젝트 리스트
        },
        //생성
        POST: {
            COLABO2_C104: "COLABO2_C104", // 라벨 설정(record)
            ACT_SECTION_C001: "ACT_SECTION_C001", // 섹션추가
            ACT_NEW_CHAT_INVITATION: "ACT_NEW_CHAT_INVITATION", //채팅 초대 신규 전문
            ACT_SAVE_TEMPORARY_POST: "ACT_SAVE_TEMPORARY_POST", //게시글 임시 저장
        },
        //수정
        PUT: {
            COLABO2_BG_COLOR_U002: "COLABO2_BG_COLOR_U002", // 프로젝트 일괄 컬러 수정
            COLABO2_NOTI_U102: "COLABO2_NOTI_U102", //푸시알림 수정(record)
            ACT_FLOW_MEME: "ACT_FLOW_MEME", //밈 등록
            ACT_MEMO: "ACT_MEMO", //플로키의 메모장
            ACT_TASK_ORDER_UPDATE: "ACT_TASK_ORDER_UPDATE", //섹션 수정
            ACT_TASK_ORDER_DESC_UPDATE: "ACT_TASK_ORDER_DESC_UPDATE", //섹션 수정
            ACT_SECTION_EDIT: "ACT_SECTION_EDIT", //섹션 수정
            ACT_GANTT_COLUMN_UPDATE: "ACT_GANTT_COLUMN_UPDATE", //간트 컬럼 업데이트
        },
        //삭제
        DELETE: {
            ACT_CHAT_EXPORT_ALL: "ACT_CHAT_EXPORT_ALL",
            ACT_DELETE_TEMPORARY_POST: "ACT_DELETE_TEMPORARY_POST",
        }
    }
})();
