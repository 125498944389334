var Datapuree = (function () {

    return {
        getVersionName: getVersionName,
    }

    function getVersionName(isGuest) {
        return i18next.t(main.company.kungrinaraVersion, {
            version: isGuest ? $t(dictionary.free) : $t(dictionary.premium)
        }).replace(/(경리나라|Kyungrinara|경리 나라)/i, '데이터퓨레');
    }

})()
