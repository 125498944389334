var GoogleTagManager = (function () {

    return {
        initSetting: initSetting,
        collect: collect,
    }

    function initSetting(googleKey) {
        if (!googleKey) return;
        $('head').append(`
            <!--Google Tag Manager START-->
            <script>
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l !== 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${googleKey}')
            </script>
            <!--Google Tag Manager END-->
        `);
    }

    function collect(_JSON) {
        if (MonitorUtil.isLimitMonitor()) return;
        if (!Often.isServerModeByHost("REAL") && !Often.isServerModeByHost("ALL_TEST") && !Often.isGlobal()) return;
        if (!window.dataLayer) return;

        //개인정보 삭제
        delete _JSON.USER_ID
        delete _JSON.USER_NM
        delete _JSON.USE_INTT_ID

        _JSON.userId = btoa(unescape(encodeURIComponent(_JSON.userId)));
        _JSON.date = Time.currentTime()
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(_JSON);
    }
})()
