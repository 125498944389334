var Secure = (function () {

    return {
        preventOverlapLogin: preventOverlapLogin,
    }

    //이중방지 로그인
    function preventOverlapLogin() {
        var deviceJson = Often.getDeviceJson(_CLIENT_IP);
        Often.setCookie("FLOW_DUID", deviceJson.DUID, '','', TeamsHelper.isTeams());
        var deviceId = deviceJson.DUID;
        var deviceName = deviceJson.DUID_NM;
        var overLapYn = Often.null2Void(LocalUtil.getLocal('ONLY_MULTIPLE_LOGIN_YN'), "N");

        if (overLapYn === "N") return;

        Ajax.executeApi(RestApi.GET.FLOW_DEVICE_ACCESS_R001, {
            SRCH_USER_ID: _USER_ID,
            DUID: deviceId,
        }, function (dat) {
            $.each(dat.DUID_REC, function (i, v) {
                var duidnm1 = deviceName.split("_")[0] + ""
                var duidnm2 = v.DUID_NM.split("_")[0] + ""
                //Note. 같은 기기번호이거나 다른 분야(웹,데탑,모바일)의 기기일땐 보내지 않는다.
                if (deviceId === v.DUID || duidnm1.indexOf(duidnm2) === -1) return;
                SocketControl.sendMessage({
                    CHAT_CODE: SocketAPI.COMM.OVERLAP_LOGOUT,
                    ROOM_SRNO: _USER_ID,
                    DUID: v.DUID
                })
            });
        });
    }

})()
