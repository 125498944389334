const Color = (function () {

    return {
        rgbToHex,
        findDominantColorInImage,
    }

    function rgbToHex(r, g, b) {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    async function findDominantColorInImage(imgSrc) {
        try {
            // 이미지 객체를 생성합니다.
            const img = new Image();

            // 이미지가 로드될 때까지 대기합니다.
            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
                img.src = imgSrc;
            });

            // 이미지를 캔버스에 렌더링합니다.
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // 이미지의 픽셀 데이터를 가져옵니다.
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const pixels = imageData.data;

            // 픽셀 데이터를 분석하여 가장 많이 사용된 색상을 찾습니다.
            const colorCounts = {};
            let maxCount = 0;
            let maxColor = null;
            for (let i = 0; i < pixels.length; i += 4) {
                const r = pixels[i];
                const g = pixels[i + 1];
                const b = pixels[i + 2];
                const a = pixels[i + 3];
                if (a === 255) {
                    const hex = rgbToHex(r, g, b);
                    if (colorCounts[hex]) {
                        colorCounts[hex]++;
                    } else {
                        colorCounts[hex] = 1;
                    }
                    if (colorCounts[hex] > maxCount) {
                        maxCount = colorCounts[hex];
                        maxColor = hex;
                    }
                }
            }

            // 가장 많이 사용된 색상을 반환합니다.
            return maxColor;
        } catch (error) {
            console.debug(error);
            return null;
        }
    }
})();