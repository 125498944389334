
var GoogleOAuth = (function () {

    return {
        logoutSecurity: logoutSecurity,
        getKey: getKey,
        getScope: getScope,
        getRedirectUri: getRedirectUri,
        isUsable: isUsable,
        getAuthByScope: getAuthByScope
    }

    function logoutSecurity(callback) {
        var isElectron = ElectronApi.Comm.isElectron();
        var initUrl = "https://accounts.google.com/Logout?continue=http://google.com";
        var tempWindow;
        if (isElectron) {
            tempWindow = window.open(initUrl, '_blank', "top=" + screen.height + ", left=" + screen.width);
        } else {
            tempWindow = window.open(initUrl);
        }
        var timer = setTimeout(function () {
            tempWindow.close();
            (typeof callback === 'function') && callback();
            clearTimeout(timer);
        }, 1000);
    }

    function getKey(isReal) {
        const production = {
            'developerKey' : "HddwNpZ_0QQclS7AGTJMGrhT",
            "clientId" : "37387137807-39m3vmoo1o6ktlm4k0d1cmajamm2gkhi.apps.googleusercontent.com",
            "appId" : "37387137807"
        }

        const develop = {
            'developerKey' : "JaoC4XDgXDBa5jWmiyyjEca7",
            "clientId" : "37387137807-6kmhqldqos2op49mrfaofapt4pl71101.apps.googleusercontent.com",
            "appId" : "37387137807"
        }

        const joins = {
            'developerKey' : "ZK_HyMRRXwUyR61z0wotg2Hl",
            "clientId" : "1020012817568-qnk6lru6t13hk6mnk2v2kolrm3ih28bn.apps.googleusercontent.com",
            "appId" : "1020012817568"
        }

        const production_global = {
            'developerKey' : "AIzaSyB-Le9CmTt_Wno48L6t78bFnXVtzgosJTM",
            "clientId" : "712211540843-knp3ldjikf8n2dtt0t8li7p2o39bo6gd.apps.googleusercontent.com",
            "appId" : "712211540843"
        }

        const develop_production_global = {
            'developerKey' : "AIzaSyB-Le9CmTt_Wno48L6t78bFnXVtzgosJTM",
            "clientId" : "712211540843-29aunsda1as5f2okocmmqss5gsv08rp5.apps.googleusercontent.com",
            "appId" : "712211540843"
        }

        if (ServerChecker.isJoins) {
            return joins;
        }
        if(Often.isGlobal()) {
            return isReal ? production_global : develop_production_global;
        }

        return isReal ? production : develop;
    }

    function getScope(apiKey) {
        switch (apiKey) {
            case "SignIn" :
                return wrapWithGoogleUrl("userinfo.profile");
            default :
                return wrapWithGoogleUrl(apiKey.toLowerCase());
        }
    }

    function wrapWithGoogleUrl(key) {
        return "https://www.googleapis.com/auth/" + key;
    }

    function getRedirectUri(apiKey) {
        return Often.getLocOrigin() + "/collabo/inc/google" + apiKey + "_view.jsp";
    }

    function isUsable() {
        if (parseInt(Often.getIEVersion(), 10) < 9) {
            Often.toast('error', i18next.t(main.alert.unSupportIE));
            return false;
        } else {
            return true;
        }
    }

    function getAuthByScope(scope, immediate = true, callback) {
        gapi.load('auth2', {
            callback: () => {
                gapi.auth2.authorize({
                    client_id: getKey(ServerChecker.isReal).clientId,
                    scope: getScope(scope),
                    immediate: immediate,
                    cookie_policy: 'none'
                }, (result) => {
                    (result && !result.error) ? callback() : alertLogin(scope)
                });
            }
        })
    }

    function alertLogin(scope, callback) {
        PopupDraw.drawConfirm({
            contents: {main: i18next.t(main.alert.googleLoginRequired)},
            callback: {submit: () => {getAuthByScope(scope, false, callback)}}
        })
    }

})()
