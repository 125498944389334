var TagUtil = (function () {

    // REGEXP - Hash Tag
    var hashtagDataRegexp = /\#\[([^\[\]\s]+)\]\(([^\[\]\s]+)\)/ig;
    //var hashtagTagRegexp = /(<span class=\"tag hashtag-span\" contenteditable=\"false\".*?>\#)([^\[\]\s]+)(<\/span>)/ig;

    // REGEXP - Mention
    var mentionDataRegexp = /\@\[([^\[\]]+)\]\(([^\[\]]+?)\)/ig;
    //var mentionTagRegexp = /(<span class=\"tag mention-span\" user-id=\")(.*)(\" contenteditable=\"false\".*?>)([a-zA-Zㄱ-힣0-9&amp;\"\'\!\@\$\%\^\&\*\(\)\_\+\-\=\|\{\}\[\]\\\/\.\&\^\ \,\~\:\?\☆\★\s\u0080-\uFFFF]+)(<\/span>)/ig;
    //var mentionTagRegexp1 = /(<span class=\"tag mention-span\" contenteditable=\"false\" user-id=\")([a-zA-Zㄱ-힣0-9_\-\/&amp;\@\.\&\^\s]+)(\".*?>)([a-zA-Zㄱ-힣0-9&amp;\"\'\!\@\$\%\^\&\*\(\)\_\+\-\=\|\{\}\[\]\\\/\.\&\^\ \,\~\:\?\☆\★\s\u0080-\uFFFF]+)(<\/span>)/ig;

    // REGEXP - commtHstrDataRegexp
    var commtHstrDataRegexp = /\%\[([^\[\]]+)\]\(([^\[\]]+?)\)/ig;

    // REGEXP - Url Tag
    // (REDOS 보완된 정규식)
    var urlTagRegexp = new RegExp(/(?:\b(?:https?|ftp|file):\/\/|www\.)[\w@.#\-:]+(?:[\/\w\-@:%._$+~^#=!*,(){}\[\]\\|';ㄱ-ㅎ가-힇\p{Extended_Pictographic}]+)*(?:\?\S+)?/, 'uig')
    // var urlTagRegexp = /(?:\b(?:https?|ftp|file):\/\/|www\.)[\w@.#\-:]+(?:[\/\w\-@:%._$+~^#=!*,(){}\[\]\\|';]+)*(?:\?\S+)?/ig
    // var urlTagRegexp = /(?:\b(?:https?|ftp|file):\/\/|www\.)[\w@.#\-:]+(?:[\/\w\-@:%._$+~^#=!*,\\|';]+)*(?:\?\S+)?/ig
    // var urlTagRegexp = /(?:(?:https?|ftp|file):\/\/|www\.)(?:[\w@.\-:]+)(?:\.\w{2,14})(?::\d{1,5})?(?:[\/\w\-!$*\\|;^&@:%.,_+~#=(){}\[\]]+)*(?:\/?\?\S+)?\b/ig
    // var urlTagRegexp = /(?:(?:https?|ftp|file):\/\/|www\.)(?:[\w@.\-:]+)(?:\.\w{2,14})(?::\d{1,5})?(?:[\/\w\-!$*\\|;^&@:%.,_+~#=]+)*(?:\/?\?\S+)?/ig
    // var urlTagRegexp = /((?:((https?|ftp|file):\/\/)|(www\.))(\.?[\w\d\-@]+)+(\.[\w\d\-]{2,}(\/|\b(?=\s)|\b(?!.:)))(\/?[\w\d가-힇\-@:%._+~#=]+)*)((\/?\?[\S]+(=?[\S])*)(\&?[\S]+=[\S]+)?)?/ig;
    // var urlTagRegexp = /(((?:(?:(?:https?|ftp|file):\/\/|www\\.|ftp\\.)[-a-zA-Z0-9+&@#/%?=~_|$!:,.;¤ㄱ-ㅎㅏ-ㅣㄱ-힣()\u2700-\u27BF\uE000-\uF8FF|\uD83C|\uDC00-\uDFFF|\uD83D|\uDC00-\uDFFF|\u2011-\u26FF|\uD83E|\uDD10-\uDDFF]*[-a-zA-Z0-9+&@#/%=~_|¤ㄱ-ㅎㅏ-ㅣㄱ-힣$?\u2700-\u27BF\uE000-\uF8FF|\uD83C|\uDC00-\uDFFF|\uD83D|\uDC00-\uDFFF|\u2011-\u26FF|\uD83E|\uDD10-\uDDFF]|((?:mailto:)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._%-]+\\.[a-zA-Z]{2,4})\\b)|"(?:(?:https?|ftp|file):\/\/|www\\.|ftp\\.)[^\\"\\r\\n]+\\"?|\\'(?:(?:https?|ftp|file):\/\/|www\\.|ftp\\.)[^'\\r\\n]+'?|(http(s)?:\/\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=¤]{2,256}\\.(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)\\b([-a-zA-Z0-9@:%_+.~#?&/=¤]*))|((www\.)[0-9a-zA-z_\-?=%/.&#;()¤ㄱ-ㅎㅏ-ㅣㄱ-힣?]*[-a-zA-Z0-9+&@#/%=~_|¤ㄱ-ㅎㅏ-ㅣㄱ-힣$?\u2700-\u27BF\uE000-\uF8FF|\uD83C|\uDC00-\uDFFF|\uD83D|\uDC00-\uDFFF|\u2011-\u26FF|\uD83E|\uDD10-\uDDFF]+))/ig;
    // var urlTagRegexp = /(((?:(?:(?:https?|ftp|file):\/\/|www\\.|ftp\\.)[-a-zA-Z0-9+&@#/%?=~_|$!:,.;ㄱ-ㅎㅏ-ㅣㄱ-힣()]*[-a-zA-Z0-9+&@#/%=~_|¤ㄱ-ㅎㅏ-ㅣㄱ-힣$?]|((?:mailto:)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._%-]+\\.[a-zA-Z]{2,4})\\b)|"(?:(?:https?|ftp|file):\/\/|www\\.|ftp\\.)[^\\"\\r\\n]+\\"?|\\'(?:(?:https?|ftp|file):\/\/|www\\.|ftp\\.)[^'\\r\\n]+'?|(http(s)?:\/\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)\\b([-a-zA-Z0-9@:%_+.~#?&/=]*))|((www\.)[0-9a-zA-z_\-?=%/.&#;()ㄱ-ㅎㅏ-ㅣㄱ-힣?]*[-a-zA-Z0-9+&@#/%=~_|ㄱ-ㅎㅏ-ㅣㄱ-힣$?]+))/ig;
    // var urlTagRegexp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim

    return {
        getUrlRegexp: () => urlTagRegexp,

        commtHstrData2Tag: commtHstrData2Tag,

        //mention
        mentionData2Tag: mentionData2Tag,
        mentionTag2Data: mentionTag2Data,
        extractMentionedUsers: extractMentionedUsers,
        removeMentionTags: removeMentionTags,

        //hash
        hashtagData2Tag: hashtagData2Tag,
        hashtagTag2Data: hashtagTag2Data,
        extractHashtags: extractHashtags,

        //style
        styleData2TagPlusTag2html: styleData2TagPlusTag2html,
        styleTag2Data: styleTag2Data,
        splitStyle: splitStyle,

        //newLine
        NewLine2Data: NewLine2Data,
        NewLine2Tag: NewLine2Tag,
        checkEmptyNewLine: checkEmptyNewLine,

        //short
        shortContent: shortContent,
        shortContentByNewLine: shortContentByNewLine,
        shortContentByLong: shortContentByLong,

        //remove
        removeSpecialTag: removeSpecialTag,
        removeAllTag: removeAllTag,
        removeNewLine: removeNewLine,
        removePrevNewLine: removePrevNewLine,

        //tools
        br2n: br2n,
        n2br: n2br,
        escapeHtml: escapeHtml,
        escapeHtmlWithDiv: escapeHtmlWithDiv,
        tag2html: tag2html,
        html2tag: html2tag,
        text2highlight: text2highlight,
        LinkData2Tag: LinkData2Tag,
        link2tag: link2tag,
        json2text: json2text,
        icon2tag: icon2tag,
        blank2SpaceTag: blank2SpaceTag,
        spaceTag2Tag: spaceTag2Tag,
        data2Html: data2Html,
        quot2Html: quot2Html,
        replaceLink: replaceLink,
        rn2n: rn2n,
        customTrim: customTrim,
        normalizeSpaces: normalizeSpaces,
        preventConvertHtmlSpecialCodeInUrl: preventConvertHtmlSpecialCodeInUrl,
        replaceText: replaceText,

    }

    function checkEmptyNewLine(contents) {
        var returnContents = tag2html(contents);
        returnContents = returnContents.replace(/&nbsp;/ig, "");
        if ($.trim(returnContents) === "") return ""
        return contents;
    }

    //Todo. '&' 라는 특수기호 하나를 검색하면 tag2html로 인해 &가 노출되는 이슈가 있어서 주석.
    function text2highlight(mode, baseText, searchText) {
        //baseText = TagUtil.tag2html(baseText);
        //searchText = TagUtil.tag2html(searchText);
        baseText = quot2Html(Often.null2Void(baseText, "-"));    // "가 있을 때 텍스트 잘림
        searchText = quot2Html(Often.null2Void(searchText, ""));
        if (searchText === "") return baseText;
        if (searchText.indexOf(" ") > -1) {
            var searchTextArray = searchText.split(" ");
            text2tag(searchTextArray);
        } else {
            text2tag(searchText);
        }
        return baseText;

        function text2tag(searchKeyword) {
            var isConverted = false;
            var regexException = /[|\\{}()[\]^$+*?.]/g;

            const isArrayKeyword = typeof searchKeyword !== "string";
            const pattern = isArrayKeyword ? searchKeyword.map(v => `${v.replace(regexException, '\\$&')}`).join(`|`) : `${searchKeyword.replace(regexException, '\\$&')}`;

            var highLightRegexIg = new RegExp("(" + pattern + ")", 'ig');

            baseText = baseText.replace(highLightRegexIg, getSearchTag());

            isConverted = true;

            if (!isConverted) baseText = baseText.replace(highLightRegexIg, getSearchTag());
        }

        function getSearchTag() {
            if (mode === 'CHAT_SEARCH') return '<span class="search-highlight">$1</span>';
            if (mode === 'SEARCH') return '<strong>$1</strong>';
            if (mode === 'SEARCH2') return '<b>$1</b>';
        }
    }

    //개행태그 <br> -> \n
    function br2n(data) {
        if (!data) return data;
        return data.replace(/(<br>)/ig, "\n");
    }

    function n2br(data) {
        return data.replace(/\n/ig, "<br>");
    }

    function tag2html(str, value) {
        str = Often.chatNull2Void(str) + "";
        return str.replace(/\<|\>/ig, function (v) {
            //Often.clog("tag2html", value); //Note. 태그 깨지는 곳 찾으려고 당분간 열어놓기
            return Often.null2Void({'<': '&lt;', '>': '&gt;'}[v]);
        });
    }


    // 만약 링크 주소를 html 태그에 넣는 다면 & 문자는 &amp; 로 변환. 링크가 html 예약 문자로 변환하는 것 방지
    // 테스트 케이스 : &not
    // 에러 케이스 : &not => ¬(실제 보이는 형태)
    // 성공 케이스 : &amp;not => &not(실제 보이는 형태)
    function getUrlTextInHtmlTag(contents) {
        return contents.replace(/&/g, "&amp;");
    }

    function escapeHtml(text) {
        if (Often.null2Void(text, "") === "") return "";
        return text.replace(/[\"&<>]/g, function (a) { //&lt; check
            return Often.null2Void({'"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;'}[a], "");
        });
    }

    function escapeHtmlWithDiv(text) {
        const element = document.createElement('div');
        element.textContent = text;
        return element.innerHTML;
    }

    function quot2Html(text) {
        text = Often.null2Void(text);
        return text.replace(/\"/ig, '&quot;');
    }

    function html2tag(str) {
        str = Often.chatNull2Void(str,"");
        return str.replace(/(&lt;|&gt;|&amp;|&nbsp;|&#x2F;|&#39;)/ig, function (v) {
            return {
                '&lt;': '<',
                '&gt;': '>',
                '&amp;': '&',
                '&nbsp;': ' ',
                '&#x2F;': '/',
                '&#39;': '\'',
            }[v];
        });
    }

    function rn2n(txt) {
        return txt.replace(/\r\n/ig, '\n');
    }

    function customTrim(cntn) {
        /**
         * Cover Case
         * 1.(공백)(엔터)\n1\n2\n(엔터)(공백)
         */
        return cntn.replace(/(^(\s|\\n)*)|((\s|\\n)*$)/g, "");
    }

    function normalizeSpaces(text) {
        text = Often.null2Void(text);
        return text.replace(/(\s)\s+/g, '$1');
    }

    //#[]() -> <해시>
    function hashtagData2Tag(data) {
        return data.replace(hashtagDataRegexp, '<span class="tag hashtag-span" contenteditable="false">#$1</span>');
    }

    //<해시> -> #[]()
    function hashtagTag2Data(data) {
        return data;
        // TagUtilV2.convertHashServerTag 로 대체
        //return data.replace(hashtagTagRegexp, "#[$2]($2)"); // hashtag
    }

    //^[]() -> <게시글 히스토리 보기>
    function commtHstrData2Tag(data) {
        return data.replace(commtHstrDataRegexp, '<span class="commt-hstr-span" data-hstr-srno="$2" contenteditable="false">$1</span>');
    }

    //@[]() -> <멘션>
    function mentionData2Tag(data, isNoMention) {
        if (isNoMention) {
            return data.replace(mentionDataRegexp, '$1');
        } else {
            return data.replace(mentionDataRegexp, '<span class="tag mention-span" user-id="$2" contenteditable="false">$1</span>');
        }
    }

    //<멘션> -> @[]()
    function mentionTag2Data(data) {
        return data;
        // TagUtilV2.convertMentionServerTag 로 대체
        //return data.replace(mentionTagRegexp, "@[$4]($2)").replace(mentionTagRegexp1, "@[$4]($2)"); // mention
    }

    //<멘션> remove
    function extractMentionedUsers(cntn) {
        var mentionUser = null;
        var mentionList = [];
        while (true) {
            mentionUser = mentionDataRegexp.exec(cntn);
            if (!mentionUser) {
                break;
            } else {
                mentionList.push(mentionUser[2]);
            }
        }
        return mentionList;
    }

    //html 멘션 태그 제거 후 이름만 남김
    function removeMentionTags(str) {
        return Mutil.removeMentionTag(str);
        //return str.replace(mentionTagRegexp1, '$4').replace(mentionTagRegexp, '$4');
    }

    //<해시> remove
    function extractHashtags(cntn) {
        var hashtagWord = null;
        var hashtagList = [];
        while (true) {
            hashtagWord = hashtagDataRegexp.exec(cntn);
            if (!hashtagWord) {
                break;
            } else {
                hashtagList.push(hashtagWord[1]);
            }
        }
        return hashtagList;
    }

    //<스타일> -> <f_b>
    function styleTag2Data(data) {
        data = splitStyle(Often.chatNull2Void(data));
        return data;
        // TagUtilV2.convertStyleServerTag 로 대체
        /*
        return data
            .replace(/(<b>|<b style="">|<strong>)(.*?)(<\/b>|<\/strong>)/ig, "<f_b>$2</f_b>")
            .replace(/(<u>|<u style="">)(.*?)(<\/u>)/ig, "<f_u>$2</f_u>")
            .replace(/(<i>|<i style="">|<em>)(.*?)(<\/i>|<\/em>)/ig, "<f_i>$2</f_i>")
            .replace(/(<strike>|<strike style="">)(.*?)(<\/strike>)/ig, "<f_del>$2</f_del>")
            .replace(/(<font face[^>]*>)/ig, "");
        */
    }

    //<f_b> -> <스타일> // tag2tag => tag2html 위에 위치해야함
    function styleData2TagPlusTag2html(data = "") {

        if(data.length == 0 ) return data;

        var tmpStyleCharacter = {
            boldStart: "[[f_b]]",
            boldEnd: "[[/f_b]]",
            italicStart: "[[f_i]]",
            italicEnd: "[[/f_i]]",
            underlineStart: "[[f_u]]",
            underlineEnd: "[[/f_u]]",
            delStart: "[[f_del]]",
            delEnd: "[[/f_del]]",
        };

        var styleTag = {
            boldStart: "<b>",
            boldEnd: "</b>",
            italicStart: "<i>",
            italicEnd: "</i>",
            underlineStart: "<u>",
            underlineEnd: "</u>",
            delStart: "<strike>",
            delEnd: "</strike>",
        };

        if (navigator.userAgent.indexOf("Trident") > 0) {
            styleTag.boldStart = "<strong>";
            styleTag.boldEnd = "</strong>";
            styleTag.italicStart = "<em>";
            styleTag.italicEnd = "</em>";
        }

        var result = data.replace(/<f_b>/ig, tmpStyleCharacter.boldStart).replace(/<\/f_b>/ig, tmpStyleCharacter.boldEnd)
            .replace(/<f_u>/ig, tmpStyleCharacter.underlineStart).replace(/<\/f_u>/ig, tmpStyleCharacter.underlineEnd)
            .replace(/<f_i>/ig, tmpStyleCharacter.italicStart).replace(/<\/f_i>/ig, tmpStyleCharacter.italicEnd)
            .replace(/<f_del>/ig, tmpStyleCharacter.delStart).replace(/<\/f_del>/ig, tmpStyleCharacter.delEnd);

        //result = tag2html(result); //NOTE. 스타일 태그 <> => [] => (시점) => <> 중간 시점에 HTML화 해야 정상적으로 표현됨

        return result.replace(/\[\[f_b\]\]/ig, styleTag.boldStart).replace(/\[\[\/f_b\]\]/ig, styleTag.boldEnd)
            .replace(/\[\[f_u\]\]/ig, styleTag.underlineStart).replace(/\[\[\/f_u\]\]/ig, styleTag.underlineEnd)
            .replace(/\[\[f_i\]\]/ig, styleTag.italicStart).replace(/\[\[\/f_i\]\]/ig, styleTag.italicEnd)
            .replace(/\[\[f_del\]\]/ig, styleTag.delStart).replace(/\[\[\/f_del\]\]/ig, styleTag.delEnd);
    }

    //스타일태그 <u style="font-weight: bold;"> -> <u><b>
    function splitStyle(data) {

        var returnText = data;

        var TagMix = {
            BOLD: {
                STYLE: 'font-weight: bold',
                TAG: /(<b style=".*?">)(.*?)(<\/b>)/,
            },
            UNDERLINE: {
                STYLE: 'text-decoration-line: underline',
                TAG: /(<u style=".*?">)(.*?)(<\/u>)/,
            },
            ITALIC: {
                STYLE: 'font-style: italic',
                TAG: /(<i style=".*?">)(.*?)(<\/i>)/,
            },
            STRIKE: {
                STYLE: 'text-decoration-line: line-through',
                TAG: /(<strike style=".*?">)(.*?)(<\/strike>)/,
            },
            SPAN: {
                TAG: /(<span style=".*?">)(.*?)(<\/span>)/,
            }
        }

        if (data.indexOf("<span style=") > -1) {
            returnText = tag2splitTag(returnText, TagMix.SPAN.TAG, '<span style=', '', '');
        }

        if (data.indexOf(TagMix.BOLD.STYLE) > -1) {
            returnText = tag2splitTag(returnText, TagMix.UNDERLINE.TAG, '<u style=', '<u>', '</u>');
            returnText = tag2splitTag(returnText, TagMix.ITALIC.TAG, '<i style=', '<i>', '</i>');
            returnText = tag2splitTag(returnText, TagMix.STRIKE.TAG, '<strike style=', '<strike>', '</strike>');
        }

        if (data.indexOf(TagMix.UNDERLINE.STYLE) > -1) {
            returnText = tag2splitTag(returnText, TagMix.BOLD.TAG, '<b style=', '<b>', '</b>');
            returnText = tag2splitTag(returnText, TagMix.ITALIC.TAG, '<i style=', '<i>', '</i>');
            returnText = tag2splitTag(returnText, TagMix.STRIKE.TAG, '<strike style=', '<strike>', '</strike>');
        }

        if (data.indexOf(TagMix.ITALIC.STYLE) > -1) {
            returnText = tag2splitTag(returnText, TagMix.BOLD.TAG, '<b style=', '<b>', '</b>');
            returnText = tag2splitTag(returnText, TagMix.UNDERLINE.TAG, '<u style=', '<u>', '</u>');
            returnText = tag2splitTag(returnText, TagMix.STRIKE.TAG, '<strike style=', '<strike>', '</strike>');
        }

        if (data.indexOf(TagMix.STRIKE.STYLE) > -1) {
            returnText = tag2splitTag(returnText, TagMix.BOLD.TAG, '<b style=', '<b>', '</b>');
            returnText = tag2splitTag(returnText, TagMix.UNDERLINE.TAG, '<u style=', '<u>', '</u>');
            returnText = tag2splitTag(returnText, TagMix.ITALIC.TAG, '<i style=', '<i>', '</i>');
        }

        return returnText;

        function tag2splitTag(data, matchRex, matchRexFront, frontTag, backTag) {
            var dataArr1 = [];
            dataArr1 = data.split(matchRex);
            dataArr1.forEach(function (i, v) {
                var tagStyle = dataArr1[v];
                if (tagStyle.indexOf(matchRexFront) > -1) {
                    if (tagStyle.indexOf("bold") > -1) {
                        frontTag.concat("<b>");
                        backTag = "</b>".concat(backTag);
                    }
                    if (tagStyle.indexOf("underline") > -1) {
                        frontTag.concat("<u>");
                        backTag = "</u>".concat(backTag);
                    }
                    if (tagStyle.indexOf("line-through") > -1) {
                        frontTag.concat("<strike>");
                        backTag = "</strike>".concat(frontTag);
                    }
                    if (tagStyle.indexOf("italic") > -1) {
                        frontTag.concat("<i>");
                        backTag = "</i>".concat(backTag);
                    }
                    dataArr1[v] = frontTag;
                    dataArr1[v + 2] = backTag;
                }
            });
            return dataArr1.join('');
        }
    }

    function removeNewLine(cntn) {
        return cntn.replace(/(\r\n\t|\n|\r\t|"\\n")/gm, " ");
    }

    //앞공백만 제거
    function removePrevNewLine(cntn) {
        return cntn.replace(/^\s*/, "");
    }

    function removeAllTag(cntn) {
        return cntn.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "");
    }

    //태그 제거
    function removeSpecialTag(data) {
        var removeTagRegexp = /(<span style=\"background-color: )(.*?)(\">)([a-zA-Zㄱ-힣0-9\(\)\_\-\☆\★\○\●\◇\◆\□\■\△\▲\▽\▼\◁\◀\▷\▶\+@\.\=\~\^\s\[\]\#\%\!\$\&\*\<\>\?\/\'\"\:\;\,\{\\\}]+)(<\/span>)/ig; //191008 =,~ 추가 //191017 \☆\★\○\●\◇\◆\□\■\△\▲\▽\▼\◁\◀\▷\▶ 추가
        var removeTagRegexp2 = /(<span style=\"white-space:pre)(.*?)(\">)([a-zA-Zㄱ-힣0-9\(\)\_\-\☆\★\○\●\◇\◆\□\■\△\▲\▽\▼\◁\◀\▷\▶\+@\.\=\~\^\s\[\]\#\%\!\$\&\*\<\>\?\/\'\"\:\;\,\{\\\}]+)(<\/span>)/ig;    //19년 4월 이후로 발생 안함
        var removeTagRegexp3 = /(<font style="vertical-align: inherit;">)|(<\/font>)/ig;
        var removeTagRegexp4 = /(<\/span>)([a-zA-Zㄱ-힣0-9\(\)\_\-\☆\★\○\●\◇\◆\□\■\△\▲\▽\▼\◁\◀\▷\▶\+@\.\=\~\^\s\[\]\#\%\!\$\&\*\<\>\?\/\'\"\:\,\{\\\}]+)(<span style=\"background-color: )(initial|transparent)(;\">)/ig; //: -> &#58;
        var removeTagRegexp5 = /(<\/span>)(<span style=\"background-color: )(initial|transparent)(;\">)/ig;
        var removeTagRegexp6 = /(<div style=\"user-select: auto;\">)|(<div style=\\"user-select: auto;\\">)|(<br style=\\\"user-select: auto;\\\">)|(<br style=\"user-select: auto;\">|<p style=\"user-select: auto;\">|<p style=\\\"user-select: auto;\\\">)/ig;
        var removeTagRegexp7 = /(<sub><\/sub>)|(<sup><\/sup>)|(<sub><\\\/sub>)|(<sup><\\\/sup>)/ig;
        var removeTagRegexp8 = /(<a href=)(.*?\"\>)(.*?)(<\/a>)/ig;
        var removeTagRegexp9 = /(<a class=)(.*?\"\>)(.*?)(<\/a>)/ig;
        var removeTagRegexp10 = /(<div class="js-hidden-component hidden-component" contenteditable="false">)|(<div class="" contenteditable="">)|(<div class="edit-component" contenteditable="">)/ig;
        var removeTagRegexp11 = /(<p class="js-hidden-component hidden-component" contenteditable="false">)|(<p class="" contenteditable="">)|(<p class="edit-component" contenteditable="">)/ig;
        data = data
            .replace(removeTagRegexp, "$4")
            .replace(removeTagRegexp2, "$4")
            .replace(removeTagRegexp3, "")
            .replace(removeTagRegexp4, "$2").replace(removeTagRegexp5, "")
            .replace(removeTagRegexp4, "$2").replace(removeTagRegexp5, "") //두겹일경우가있음
            .replace(removeTagRegexp6, "")
            .replace(removeTagRegexp7, "")
            .replace(removeTagRegexp8, "$3")
            .replace(removeTagRegexp9, "$3")
            .replace(removeTagRegexp10, "")
            .replace(removeTagRegexp11, "");
        return data;
    }

    //<개행> -> \n
    function NewLine2Data(data) {
        if (data.trim() === "") return ""
        var result = data
            .replace(/(\n<\/div>)/ig, "</div>") //Note. 댓글 shift + enter 에 따른 개행 한줄 이슈 #87776
            .replace(/((<br><\/p><p>)|(<br><\/div><div>)|(<br><\/p><div>)|(<br><\/div><p>))/ig, "\n")
            .replace(/((<\/p><p>)|(<\/div><p>)|(<\/p><div>)|(<\/div><div>)|<br>)/ig, "\n")
            .replace(/(<div><div>)/ig, "<div>");
        result = result
            .replace(/(\n<div>)/ig, "\n")
            .replace(/(<\/div>\n)/ig, "\n");

        if (data.indexOf('<div>') === 0) {
            result = result.substring(5);
        } else if (data.indexOf('<p>') === 0) {
            result = result.substring(3);
        }
        result = result.replace(/((<p>)|(<div>))/ig, "\n").replace(/((<\/p>)|(<\/div>))/ig, "");
        return result;
    }

    //\n -> <개행>
    function NewLine2Tag(data) {

        var isWinOs = Often.getClientOSInfo().isWin;
        var result = "<div>" + data.replace((isWinOs ? /(\r\n|\n)/ig : /(\r|\n)/ig), "</div><div>") + "</div>";

        //마지막 div tag 스킵
        var resultLength = result.length;
        var divHtml = "<div></div>";
        var isLastDiv = divHtml === result.substring(resultLength - divHtml.length, resultLength);
        result = isLastDiv ? result.substring(0, resultLength - divHtml.length) : result;

        //div tag => br을 넣어줌
        result = result.replace(/(<div><\/div>)/ig, "<div></br></div>");

        //b태그 내부 개행 적용 안되는 부분 수정 //Question. 이렇게 태그가 남을수 있나?
        result = result.replace(/(<div><b><\/div>)/ig, "<div></br></div>");
        return result;
    }

    //\t -> &nbsp; x 4
    function data2Html(data) {
        return data.replace(/\t/ig, "&nbsp;&nbsp;&nbsp;&nbsp;");
    }

    //url-> <링크>
    function LinkData2Tag(data, isSpan) {
        //공백 html 태그 이슈
        if (data.indexOf("&nbsp;") === -1) return link2tag(data, isSpan);
        var returnArray = [];
        data.split("&nbsp;").forEach(function (v) {
            returnArray.push(link2tag(v, isSpan));
        })
        return returnArray.join("&nbsp;");
    }

    function link2tag(data, isSpan) {
        /**
         * Cover Case
         * 주소 https://naver.com 붙여
         * 주소 http://yahoo.co.kr 붙여
         * 주소 https://release.flow.team/main.act)를 붙여
         * 주소 https://release.flow.team/main.act를 붙여
         * 주소 https://release.flow.team/main.act) 붙여
         * 주소 https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN¬i_seq=1576&IO_GB=I 붙여
         * 주소 https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN¬i_seq=1576&IO_GB=가 붙여
         * 주소https://www.sisajournal-e.com/news/articleView.html?idxno=1 ?붙여
         * 주소https://www.sisajournal-e.com/news/articleView.html?idxno= ?붙여
         * 주소https://www.sisajournal-e.com/news/articleView.html?idxno ?붙여
         * 주소https://www.sisajournal-e.com/news/articleView.html?idxno&q=v ?붙여
         * 주소 https://www.youtube.com/watch?v=kPz5b-n3vC4 붙여?
         * 쿠팡 https://www.coupang.com/vp/products/6740771702?itemId=15735340756&vendorItemId=82949272155&sourceType=cmgoms&isAddedCart= 되냐
         * 네이버쇼핑 https://shopping.naver.com/luxury/cosmetic/products/7086113515?NaPm=ct%3Dl8u8jhs2%7Cci%3Dshoppingwindow%7Ctr%3Dluxc%7Chk%3D9231e474bd318ed6682da032c35b6e9075e3df2b%7Ctrx%3D 되요
         * 주소 www.naver.com 붙여
         * 주소 https://subdomain.naver2222222.com2222222 붙여
         * 주소 www.xxxx.io 붙여
         * 주소 wwww.xxxx.io/#123/3333 붙여
         * 주소 www.yyyy.team/@333 붙여
         * http://www.foufos.gr
         * https://www.foufos.gr
         * http://foufos.gr
         * http://www.foufos.gr/kino
         * http://werer.gr
         * www.foufos.gr
         * www.mp3.com
         * www.t.co
         * http://t.co
         * http://www.t.co
         * https://www.t.co
         * www.aa.com
         * http://aa.com
         * http://www.aa.com
         * https://www.aa.com
         * www.foufos
         * www.foufos-.gr
         * www.-foufos.gr
         * foufos.gr
         * http://www.foufos
         * http://foufos
         * www.mp3#.com
         * &not 주목 https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN&noti_seq=1576&IO_GB=I
         * http://ec2-18-237-25-224.us-west-2.compute.amazonaws.com:8899/users/sign_in
         * http://211.118.224.192:82/redmine/issues/3229
         * https://ctbos.syn.me:5006/Project/Bandi/Camera
         * ftp://211.118.224.192:5221/Project/Bandi/Camera
         * file://221.118.224.192:5221/Project/Bandi/Camera
         */

        if (data.match(urlTagRegexp) === null) {
            return data
        }

        const processed = ServerChecker.isPsnm ? preventConvertHtmlSpecialCodeInUrl(data) : data

        const urls = [...processed.matchAll(urlTagRegexp)].map(url => ({
            value: sanitizeTinyUrl(url[0]),
            pos: url.index,
        }))

        const segments = urls.map(({value, pos}, index) => {
            const nextUrlStartsAt = index + 1 !== urls.length ? urls[index + 1].pos : processed.length
            return {
                targetText: processed.slice(pos, nextUrlStartsAt),
                url: value,
            }
        })

        let result = processed.slice(0, urls[0].pos)
        for (const {url, targetText} of segments) {
            const styleTagsPurged = purgeTextStyleTagsInUrl(url)
            const specialCharactersPurged = purgeSpecialCharacters(styleTagsPurged)
            const encodedUrl = Mutil.isEncodedUrl(specialCharactersPurged) ? specialCharactersPurged : encodeURI(specialCharactersPurged)

            const tag = isSpan ? `<span class="js-hyper-button urllink blue" data-url="${encodedUrl}">${specialCharactersPurged}</span>`
                : `<a href="${encodedUrl}" target="_blank" class="js-hyper-button urllink blue">${specialCharactersPurged}</a>`
            result += targetText === '' ? tag : targetText.replace(specialCharactersPurged, tag)
        }

        return result

        function sanitizeTinyUrl(url) {
            const regex = new RegExp(/https?:\/\/(?:\w+?\.)?(?:flow\.team|flowtest\.info|morningmate\.(?:com|info))\/l\/[a-zA-Z0-9]+/, 'i')
            const sanitized = url.match(regex)
            if (sanitized) {
                return sanitized[0]
            }
            return url
        }

        function purgeTextStyleTagsInUrl(url) {
            return url.replace(/\<\/?(?:b|i|u|strike)\>/ig, '')
        }

        function purgeSpecialCharacters(url) {
            if (url.slice(-1).match(/[\(\){}\[\]\<\>\.']/g)) {
                return url.slice(0, -1)
            }
            return url
        }
    }


    //개행별단축, 최대 2줄
    function shortContentByNewLine(contents, length) {
        var returnContents = "";
        var contentsArray = contents.split("\n");
        var maxLine = 2;
        var currentLine = 0;
        contentsArray.forEach(function (v) {
            var value = $.trim(v);
            if (value !== "" && currentLine < maxLine) {
                currentLine++;
                returnContents += shortContent(value, length) + (currentLine === 1 ? "\n" : "");
            }
        });
        return returnContents;
    }

    /**
     * 대상이 너무 길 경우 뒤에 ... 을 붙혀 return
     * @param {String} contents 잘라야 하는 대상
     * @param {Integer} length 잘라 내야하는 길이
     */
    function shortContent(contents, length) {
        if (contents.length <= length) return contents;
        return contents.substring(0, length) + "...";
    }

    //채팅롱텍스트
    function shortContentByLong(contents) {
        var contentsArray = contents.split("\n");
        if (contentsArray.length < 15) return contents;
        if (contents.length > 299) return contents.substring(0, 300)
        return contentsArray.slice(0, 15).join("\n");
    }

    //JSON텍스트
    function json2text(contents) {
        var returnContents = contents;

        if (contents.indexOf('{"COMPS":') === -1 && contents.indexOf('{"SYS_CODE":') === -1) return returnContents;
        var jsonText = jsonContents2text(contents, true);
        if ("" !== jsonText) return jsonText;

        try {
            returnContents = returnContents.replace('\n', ' ')
            var sysCode = JSON.parse(returnContents).SYS_CODE;
            //var cntn = jsonContents2text(JSON.parse(returnContents).CNTN, false);
            var imageIcon = "[ICON:IMAGE] (" + i18next.t(dictionary.image) + ")";
            var fileIcon = "[ICON:FILE] (" + i18next.t(dictionary.file) + ")";
            //Todo. 개행처리가 필요해보이는데 애매함
            returnContents = /*cntn + " " + */{
                S13: imageIcon,
                S14: fileIcon,
                S20: imageIcon + " " + fileIcon,
            }[sysCode] || '';
        } catch (e) {
            //pass
        }
        return returnContents;
    }

    function jsonContents2text(contents, isCatchReturnEmpty) {
        try {
            var returnContents = "";
            var postJsonArray = JSON.parse(contents).COMPS;
            postJsonArray.forEach(function (v) {
                if (v.COMP_TYPE === "TEXT") {
                    returnContents += v.COMP_DETAIL.CONTENTS + "\n";
                }
            })
            return returnContents;
        } catch (e) {
            return isCatchReturnEmpty ? "" : contents;
        }
    }

    //아이콘태그
    function icon2tag(contents) {

        var returnContents = contents;

        var icon = {
            FILE: '<i class="all-setup-icon-type-1"></i>',
            IMAGE: '<i class="all-setup-icon-type-2"></i>',
        }

        returnContents = returnContents.replace(/\[ICON:IMAGE\]/ig, icon.IMAGE);
        returnContents = returnContents.replace(/\[ICON:FILE\]/ig, icon.FILE);

        return returnContents;
    }

    //post 임시 공백 추가 - 링크에 &nbsp;가 붙기 때문에 임시 치환을 해줌
    function blank2SpaceTag(contents) {
        return contents.replace(/ /gi, " &nbsp;");
    }

    //post 공백 추가
    function spaceTag2Tag(contents) {
        return contents.replace(/ &nbsp;/gi, "&nbsp;");
    }

    //past 의도치 않게 html 특수문자가 완성되서 다른 문자로 변경되는 것을 방지
    // 테스트 케이스 : abs https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN&noti_seq=1576&IO_GB=I
    // 오류 케이스  : abs https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN&noti_seq=1576&IO_GB=I
    //   (실제로 보이는 형태) abs  https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN¬i_seq=1576&IO_GB=I (&not => ¬)
    // 성공 케이스 : abs  https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&amp;code=HPN_0002&amp;src=PN&amp;noti_seq=1576&amp;IO_GB=I
    //  (실제로 보이는 형태) abs  https://p055ppyy7vyolhl.hiwebnet.com/Pay/PayTreatment/?mode=redir&code=HPN_0002&src=PN&noti_seq=1576&IO_GB=I
    function preventConvertHtmlSpecialCodeInUrl(contents) {
        const urlArr = contents.match(urlTagRegexp);
        const haveLinkText = urlArr && urlArr.length > 0;
        if(haveLinkText) {
            urlArr.map((url) => {
                const urlTextInHtmlTag = getUrlTextInHtmlTag(url);
                contents  = contents.replace(url, urlTextInHtmlTag);
            });
        }
        return contents;
    }

    // 만약 링크 주소를 html 태그에 넣는 다면 & 문자는 &amp; 로 변환. 링크가 html 예약 문자로 변환하는 것 방지
    // 테스트 케이스 : &not
    // 에러 케이스 : &not => ¬(실제 보이는 형태)
    // 성공 케이스 : &amp;not => &not(실제 보이는 형태)
    function getUrlTextInHtmlTag(contents) {
        return contents.replace(/&/g, "&amp;");
    }



    function replaceLink(contents) {
        const flowReplaceValue = "!@#flow!@#";
        const flowReplaceLength = flowReplaceValue.length;
        let link = Often.null2Void(contents).replace(urlTagRegexp, flowReplaceValue + "$&" + flowReplaceValue);
        const startOffset = link.indexOf(flowReplaceValue) + flowReplaceLength;
        const endOffset = link.indexOf(flowReplaceValue, startOffset);
        if (endOffset < 0) return contents;
        return link.substring(startOffset, endOffset);
    }

    function replaceText(text){
        text = Often.chatNull2Void(text,"");
        return text.replace(/(&lt|&gt|&amp;|&quot;|&apos;)/ig, function (v) {
            return {'&lt': '<', '&gt': '>', '&amp;': '&', '&quot;': '"', '&apos;': '\''}[v];
        });
    }

})()
