var BaseSetting = (function () {

    return {
        initSetting: initSetting,
        asyncSetFuncSetting: asyncSetFuncSetting,
        setBuySetting: setBuySetting,
        setUserSetting: setUserSetting,
        setFuncSetting: setFuncSetting,
        setOrgActiveYn: setOrgActiveYn,
    };

    function initSetting(){
        const buyData = LocalUtil.getLocalJson("ONLY_BUY_SETTING");
        const {SERP_YN, SERP_GUBUN} = buyData;
        const serpGubun = Mutil.n2v(SERP_GUBUN, 'SERP');
        ServerChecker.isKyungrinara = SERP_YN === "Y" && serpGubun === "SERP";
        ServerChecker.isDatapuree = SERP_YN === "Y" && serpGubun === "DATAPUREE";
        setSearchFilter()
    }

    function asyncSetFuncSetting() {
        return new Promise((resolve) => setFuncSetting(resolve));
    }

    function asyncSetBuySetting() {
        return new Promise((resolve) => setBuySetting(resolve));
    }

    function setFuncSetting(callback) {
        Ajax.executeApi(RestApi.GET.COLABO2_FUNC_R003, {}, function (dat) {
            LocalUtil.setLocal("ONLY_FUNC_LIST", dat["FUNC_DEPLOY_LIST"]);
            (typeof callback === "function") && callback();
        });
    }

    function setBuySetting(callback) {
        Ajax.executeApi(RestApi.GET.COLABO2_BUY_R001, {
            DUID: Often.getCookie("FLOW_DUID")
        }, function (buyData) {
            LocalUtil.setLocal("ONLY_FUNC_LIST", buyData["FUNC_DEPLOY_LIST"]);
            LocalUtil.setLocal("ONLY_CHATBOT_ID_LIST", JSON.stringify(buyData["CHATBOT_REC"]));
            var settingJson = {}
            for (var key in buyData) {
                if (key !== 'FUNC_DEPLOY_LIST' && key !== 'CHATBOT_REC' && key !== 'COMMON_HEAD') {
                    settingJson[key] = buyData[key];
                } else {
                    //pass
                }
            }
            const {SERP_YN, SERP_GUBUN} = settingJson;
            const serpGubun = Mutil.n2v(SERP_GUBUN, 'SERP');
            ServerChecker.isKyungrinara = SERP_YN === "Y" && serpGubun === "SERP";
            ServerChecker.isDatapuree = SERP_YN === "Y" && serpGubun === "DATAPUREE";
            LocalUtil.setLocal("ONLY_BUY_SETTING", JSON.stringify(settingJson));
            LocalUtil.setLocal("ONLY_USE_PLAN", JSON.stringify(buyData["PLAN_REC"]));
            (typeof callback === "function") && callback(buyData)
        });
    }

    function setUserSetting(callback) {
        Ajax.executeApi(RestApi.GET.COLABO2_SET_R101, {}, function (setData) {
            var settingJson = {}
            for (var key in setData) {
                key !== 'COMMON_HEAD' && (settingJson[key] = setData[key])
            }
            LocalUtil.setLocal("ONLY_USER_SETTING", JSON.stringify(settingJson));
            (typeof callback === "function") && callback(setData)
        });
    }

    /**
     * @deprecated deprecated 일자 : 220920
     * 구버전 공지
     */
    function showMainNotice() {
        // 공지 기능키를 테스트 목적으로 사용하기 위해 기능키를 지우고 return 하도록 수정했습니다.
        // 만약 이 공지를 다시 사용해야 한다면, 새로운 기능키를 만드는 것을 추천드립니다.
        // if (Often.isFunc("USE_NEW_NOTICE")) return;
        return;

        var isElectron = ElectronApi.Comm.isElectron();
        var gbName = isElectron ? "D" : "P";
        Ajax.executeApi(RestApi.GET.COLABO2_ADM_R002, {GUBUN: gbName}, function (noticeData) {
            if (Often.null2Void(noticeData.READ_YN, "Y") === "Y") return;

            var $flowNoticePop = PopupDraw.getPopupWrapObj();
            $flowNoticePop.find(".contents").html($("#flowNoticeDiv").html());
            $flowNoticePop.find(".js-ttl").text(noticeData.TTL);
            $flowNoticePop.find(".js-cntn").html(noticeData.CNTN.replace("\\n", "<br>"));
            $flowNoticePop.find(".js-link").text(noticeData.BTN_NM);

            $flowNoticePop.on("click", function (e) {
                var $eTarget = $(e.target);
                var $link = $eTarget.findUp(".js-link");
                var $notShow = $eTarget.findUp(".js-not-show");
                var $close = $eTarget.findUp(".js-close");

                if ($link.length > 0) return submitNoticePopup();
                if ($notShow.length > 0) return readNotice();
                if ($close.length > 0) return PopupDraw.closePopup();

                if ($eTarget.findUp(".back-area").length > 0 &&
                    $eTarget.findUp("#flowNotice").length === 0) {
                    PopupDraw.closePopup();
                }
            })

            var noticeImageEl = new Image();
            noticeImageEl.src = noticeData.IMG_URL;
            noticeImageEl.onload = function () {
                $(noticeImageEl).addClass("js-img");
                var $originImgArea = $flowNoticePop.find(".js-img");
                $originImgArea.after($(noticeImageEl));
                $originImgArea.remove();
                $("body").append($flowNoticePop);
                $flowNoticePop.fadeIn(200);
            };

            function submitNoticePopup() {
                if (isElectron) return ElectronApi.Window.open({
                    url: noticeData.LNK,
                    target: "notice",
                    width: 1200, height: 800
                })
                window.open(noticeData.LNK, "_blank");
            }

            function readNotice() {
                Ajax.executeApi(RestApi.POST.COLABO2_ADM_C002, {
                    GUBUN: gbName,
                    NOTICE_SRNO: noticeData.NOTICE_SRNO,
                    packetOption: Ajax.OPTION.PREVENT_EXECUTE,
                }, PopupDraw.closePopup);
            }
        });

    }

    function setSyncList() {
        Ajax.executeApi(RestApi.GET.FLOW_EXT_SERVICE_R001, {}, function (data) {
            let storageJson = {};
            if (!((data.EXT_SERVICE_REC && data.EXT_SERVICE_REC.length === 0) || data.EXT_SERVICE_REC[0] === "")) {
                $.each(data.EXT_SERVICE_REC, function (i, v) {
                    storageJson[v.EXT_SERVICE_NM] = 'Y'
                })
            }
            LocalUtil.setLocal("ONLY_USER_INTEGRATION", JSON.stringify(storageJson));
        })
    }

    function setOrgActiveYn(callback) {
        Ajax.executeApi(RenewalApi.GET.ACT_ORG_CHART_ACTIVE_YN, {
            USE_INTT_ID: _USE_INTT_ID
        }, function (data) {
            LocalUtil.setLocal("ONLY_ORG_CHART_ACTIVE_YN", data.ACTIVE_YN);
            (typeof callback === "function") && callback();
        });
    }

    function setSearchFilter() {
        // filters
        const searchFilters = [
            'ONLY_SEARCH_PERIOD_FILTER',
        ]

        // copy filter value from cookie
        searchFilters.forEach(filterKey => {
            const filterValue = Often.getCookie(filterKey)
            if (filterValue !== '') {
                // set filter value on localstorage
                LocalUtil.setLocal(filterKey, filterValue)
            }
        })
    }
})();
