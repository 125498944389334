const PartnerStack = (function () {

    return {
        load: load,
    }
    function load() {
        if (window._ENTER_YN === 'Y') return;
        var gs = document.createElement('script');
        gs.src = 'https://js.partnerstack.com/v1/';
        gs.type = 'text/javascript';
        gs.async = 'true';
        gs.onload = gs.onreadystatechange = function () {
            var rs = this.readyState;
            if (rs && rs != 'complete' && rs != 'loaded') return;
            try {
                window.growsumo?._initialize('pk_M2Jfh4me1YHWCPC43RVq3c5rE8lDYW9o');
                if (typeof (window.growsumoInit) === 'function') {
                    growsumoInit();
                }
            } catch (e) {
            }
        };
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(gs, s)

        var data = Often.getCookie('PS_CUSTOMER_KEY')
        if(data === '') {
            Often.setCookie('PS_CUSTOMER_KEY', Often.null2Void(window.growsumo?.data.customer_key), Often.isGlobal() ? 30 : 1);
        }
    }
})();
