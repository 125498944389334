var TeamsOneDrive = function () {

    /**
     * One Drive Library 사용하야 OneDrive Open
     * 추가 : Electron일 경우 Electron 상호간 처리
     * @param successCallback OneDrive 연동 성공시 Callback
     */
    const openOneDrive = (successCallback) => {
        openOneDriveOnWeb(successCallback); // Web
    }

    // Web에서 사용할 One Drive file picker
    const openOneDriveOnWeb = (successCallback) => {
        const odOptions = {
            clientId: getClientId(),
            action: "share",
            multiSelect: true,
            advanced: {
                redirectUri: window.location.origin + '/one_drive_auth.act',
                createLinkParameters: {type: "edit", scope: "anonymous"}
            },
            success: (files) => {
                (typeof successCallback === "function") && successCallback(createFileArray(files.value));
            },
            error: function () { /* error handler */
                OneDriveErrorMsg();}
        };
        Mutil.lib.getOneDrive().then(() => {
            OneDrive.open(odOptions);
        })
    }

    // Electron에서 사용 (API)
    const openOneDriveOnElectron = (successCallback) => {
        ElectronApi.Window.openOneDrive();
    }

    /**
     * OneDrive에서 내려준 파일들을 Flow 파일 형식으로 변환
     * 추가 : Electron에서 사용
     * @param OneDriveFiles One Drive에서 내려주는 파일 Array
     * @returns {*[]} One Drive file array return
     */
    const createFileArray = (OneDriveFiles) => {
        let fileArray = [];
        const randKey = "ONEDRIVE";
        OneDriveFiles.forEach(function (oneDriveFile) {
            const webViewURL = oneDriveFile['webUrl']
            const tempFileName = randKey + "_" + Time.currentTime("type22");
            const f_info = {
                RAND_KEY: randKey,
                FILE_IDNT_ID: randKey,
                IMG_PATH: webViewURL,
                FILE_DOWN_URL: webViewURL,
                ATCH_URL: webViewURL,
                FILE_NM: Often.null2Void(oneDriveFile.name, tempFileName),
                FILE_NAME: Often.null2Void(oneDriveFile.name, tempFileName),
                FILE_SIZE: Often.null2Void(oneDriveFile.size, 0),
                USE_INTT_ID: _USE_INTT_ID,
                CLOUD_YN: "Y",
                ATCH_SRNO: "0",
            }
            fileArray.push(f_info);
        });
        return fileArray;
    }

    const getClientId = () => {
        return Often.isGlobal() ? "784c2c55-db27-41e9-9028-df392c67696d" : "0a239bcc-caa6-4cce-8416-55bd966b68cf"
    }

    // Onedrive 오류 발생시 메시지
    const OneDriveErrorMsg = () => {
        Often.toast('error', i18next.t(common.alert.onedriveAlert))
    }

    const load = () => {
        Mutil.lib.getOneDrive().then(() => {});
    }

    return {
        openOneDrive: openOneDrive,
        createFileArray: createFileArray,
        OneDriveErrorMsg : OneDriveErrorMsg,
        load: load,
    }


}();
