var ServerLocation = (function () {

    return {
        drawButton: drawButton,
        consoleLocation: consoleLocation,
    }


    function getConnectedServerRegion() {
        return Ajax.executeApi("CURRENT_REGION", {});
    }

    function consoleStr(data) {
        console.log(`%c Connected Server : ${data?.SERVER_LOC}`, "color: blue; background: yellow; font-size: 20px; padding: 5px;");
    }

    function consoleLocation() {
        setTimeout(() => getConnectedServerRegion().then(data => consoleStr(data)), 300);
    }

    function drawButton() {
        function drawOnLeftSide() {
            const region = {
                "US East (Ohio)": "🇺🇸",
                "EU (London)": "🇬🇧",
                "Asia Pacific (Seoul)": "🇰🇷",
                "Asia Pacific (Tokyo)": "🇯🇵"
            };

            getConnectedServerRegion().then(function(data) {
                if (data?.SERVER_LOC) {
                    consoleStr(data);
                    const currentRegion = data.SERVER_LOC;
                    const $logoArea = $('.logo-area');
                    $logoArea.css({'display': 'flex', 'align-content': 'center', 'justify-content': 'center'});

                    const $regionArea = $(`
                <a id="globalRegion" style="">
                    <span class="ellipsis js-mouseover js-over-tooltip" mouseover-text="${currentRegion}">
                    <div id="currentRegion" class="item" data-val="ko">${region[currentRegion]} </div>
                    </span>
                </a>
                `);
                    $regionArea.css({
                        'font-size': '32px',
                        'line-height': '38px',
                        'height': '32px',
                        'display': 'flex',
                        'margin-left': '8px',
                        'cursor': 'pointer'
                    });
                    $logoArea.append($regionArea);
                }
            });
        }
        setTimeout(() => drawOnLeftSide(), 300);
    }
})()