var GoogleDrive = function () {
    var isServerCheckerExist = typeof ServerChecker !== 'undefined'; // index 페이지 오류로 차후 수정 필요
    var keyAndId = isServerCheckerExist ? GoogleOAuth.getKey(ServerChecker.isReal) : '';
    var clientId = keyAndId.clientId;
    var appId = keyAndId.appId;
    var pickerApiLoaded = false;
    var oauthToken;
    var loadCallback;
    var successCallback;

    return {
        load: load,
        openGoogleDrive: openGoogleDrive,
        OpenPickerAfterSign: OpenPickerAfterSign,
    }

    function load() {

        // if(_INNER_NETWORK_YN == "N")
        //     $("head").append('<script async src="https://apis.google.com/js/platform.js?google-drive"></script>');
    }

    function openGoogleDrive(_loadCallback, _successCallback) {
        Mutil.lib.getGoogleDrive().then(() => {
            if (typeof gapi == "undefined" || gapi == null) return;
            if (parseInt(Often.getIEVersion(), 10) < 9) {
                Often.toast("error", i18next.t(main.alert.unSupportIE));
                return
            }
            loadCallback = _loadCallback;
            successCallback = _successCallback;

            if(Often.isGlobal()) {
                onAuthApiLoad_Global();
            } else {
                gapi.load(ElectronApi.Comm.isElectron() ? 'auth' : 'auth2', {callback: onAuthApiLoad});
                gapi.load('picker', {callback: onPickerApiLoad});
            }
        })
    }


    /**
     * Callback after the API client is loaded. Loads the
     * discovery doc to initialize the API.
     */
    function onAuthApiLoad_Global() {
        gapi.load('client:picker', async () => {
            await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
        });
       loadGoogleSignInDriveApi();
    }
    function onAuthApiLoad() {
        const auth = ElectronApi.Comm.isElectron() ? gapi.auth : gapi.auth2;
        auth.authorize({
            client_id: clientId,
            scope: GoogleOAuth.getScope("Drive"),
            immediate: true,
            cookie_policy: 'none'
        }, handleAuthResult);
    }

    function onPickerApiLoadForOnlyPicker() {
        onPickerApiLoad();
        createPicker();
    }

    function onPickerApiLoad() {
        pickerApiLoaded = true;
    }

    function handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
            oauthToken = authResult.access_token;
            createPicker();
        } else {
            Often.isMessenger() ? loadGoogleSignInDriveApi() : showAuthConfirm();
        }
    }

    function showAuthConfirm() {
        PopupDraw.drawConfirm({
            contents: {main: i18next.t(main.alert.googleLoginRequired)},
            callback: {submit: loadGoogleSignInDriveApi}
        })
    }

    function loadGoogleSignInDriveApi() {
        new GoogleSignInDriveApi(OpenPickerAfterSign).load();
    }

    function OpenPickerAfterSign(token) {
        Mutil.lib.getGoogleDrive().then(() => {
            oauthToken = token;
            gapi.load('picker', {callback: onPickerApiLoadForOnlyPicker});
        })
    }

    /**
     *  Create and render a Picker object for searching images.
     */
    function createPicker() {
        if (!(pickerApiLoaded && oauthToken)) return;
        var lang = Often.null2Void(Often.getCookie("FLOW_LANG"), "ko");
        var picker = new google.picker.PickerBuilder()
            .disableFeature(google.picker.Feature.NAV_HIDDEN)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
            .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
            .setAppId(appId)
            .setOAuthToken(oauthToken)
            .setOrigin(Often.getLocOrigin())
            .setLocale(lang)
            .addView(new google.picker.DocsView(google.picker.ViewId.DOCS).setIncludeFolders(true).setOwnedByMe(true)) //내 드라이브
            .addView(new google.picker.DocsView(google.picker.ViewId.DOCS).setOwnedByMe(false)) //공유 문서함
            .addView(new google.picker.DocsView(google.picker.ViewId.DOCS).setIncludeFolders(true).setEnableDrives(true)) //공유 드라이브
            .addView(google.picker.ViewId.DOCUMENTS)
            .addView(google.picker.ViewId.SPREADSHEETS)
            .addView(google.picker.ViewId.PRESENTATIONS)
            .addView(google.picker.ViewId.PDFS)
            .addView(google.picker.ViewId.DOCS_IMAGES)
            .setCallback(pickerCallback)
            .build();
        picker.setVisible(true);
        $(".picker-dialog").css('z-index', '99999');
        (typeof loadCallback === "function") && loadCallback();
    }

    function pickerCallback(data) {
        if (data.action !== google.picker.Action.PICKED) return;
        var fileArray = [];
        var randKey = "GOOGLEDRIVE";
        data.docs.forEach(function (googleDriveFile) {
            var googleDocsExt = "";
            //Note. 구글독스는 확장자를 표시할 수도 있으나 과거 데이터와 싱크 문제가 있어 잠시 보류
            // var googleDocsExt = googleDriveFile.serviceId === "pres" ? ".presentation" :
            //     googleDriveFile.serviceId === "doc" ? ".doc" :
            //         googleDriveFile.serviceId === "freebird" ? ".form" :
            //             googleDriveFile.serviceId === "spread" ? ".spreadsheet" : "";
            var tempFileName = randKey + "_" + Time.currentTime("type22");
            var fileName = Often.null2Void(googleDriveFile.name, tempFileName) + googleDocsExt;
            var f_info = {
                RAND_KEY: randKey,
                FILE_IDNT_ID: randKey,
                ATCH_URL: googleDriveFile.url,
                IMG_PATH: googleDriveFile.url,
                FILE_DOWN_URL: googleDriveFile.url,
                FILE_NM: fileName,
                FILE_NAME: fileName,
                FILE_SIZE: Often.null2Void(googleDriveFile.sizeBytes, 0),
                CLOUD_YN: "Y",
                ATCH_SRNO: "0",
                USE_INTT_ID: _USE_INTT_ID,
            };
            fileArray.push(f_info);
        });
        (typeof successCallback === "function") && successCallback(fileArray);
    }

}();
