var IndexedDBUtil = (function() {

    var openRequest, database, transaction, objectStore;

    return {
        createObjectStore: createObjectStore,
        selectOneIndexedDB: selectOneIndexedDB,
        deleteAllIndexedDB: deleteAllIndexedDB,
        setValue: setValue,
        deleteValue: deleteValue,
    }

    function createObjectStore(dbName, callback) {
        return callback();

        /* 간헐적으로 indexDB.open callback not working!
        if(!Often.isFunc(Func.CLOUD.CHAT_IMAGE_GROUP_KEEP) || Often.isBrowser("ie")) return callback();
        if(!indexedDB) {
            alert(i18next.t('front.alert.notSupport', {val: '$t(dictionary.browser)'}));
            return;
        }
        if(openRequest) return;

        openRequest = indexedDB.open(dbName);
        openRequest.onupgradeneeded = function(event) {
            var db = event.target.result;
            db.createObjectStore('tempImage', {keyPath: 'room_srno'});
        }
        openRequest.onerror = function() {
            alert("Fail to create index DB");
        }
        openRequest.onsuccess = function() {
            database = openRequest.result;
            (typeof callback === "function") && callback();
        }
         */
    }

    function createTransactionAndStore(){
        transaction = database.transaction(['tempImage'], 'readwrite');

        transaction.onerror = function() {
            console.error("Creating transaction fail");
        }

        objectStore = transaction.objectStore('tempImage');
    }

    function insertIndexedDB(insertObject) {
        createTransactionAndStore();
        var insertRequest = objectStore.add(insertObject);
        insertRequest.onsuccess = function(event) {
        }
    }

    function updateIndexedDB(value){
        createTransactionAndStore();
        var updateRequest = objectStore.put(value);
        updateRequest.onsuccess = function(event) {
        }
    }

    function selectOneIndexedDB(key) {
        if(!openRequest) return;
        createTransactionAndStore();
        var selectRequest = objectStore.get(key);
        selectRequest.onsuccess = function() {
            var updateJson = {
                room_srno: key,
            };
            for(var jsonKey in selectRequest.result) {
                if(jsonKey === "room_srno") continue;
                var tempChatSrno = MessengerSend.getQueueNumber();
                updateJson[tempChatSrno] = selectRequest.result[jsonKey];
                Upload.setCancelUploadInfo(tempChatSrno, selectRequest.result[jsonKey]);
            }
            updateIndexedDB(updateJson);
        }
    }

    function deleteIndexedDB(key) {
        createTransactionAndStore();
        var deleteRequest = objectStore.delete(key);
        deleteRequest.onsuccess = function(event) {
        }
    }

    function deleteAllIndexedDB(dbName) {
        createObjectStore(dbName, function() {
            if(!openRequest) return;
            createTransactionAndStore();
            var deleteRequest = objectStore.clear();
            deleteRequest.onsuccess = function() {
            }
        })
    }

    function setValue(key, setObject) {
        if(!openRequest) return;
        createTransactionAndStore();
        var selectRequest = objectStore.get(key);
        selectRequest.onsuccess = function() {
            if (!selectRequest.result) return insertIndexedDB(setObject);
            delete setObject.room_srno;
            var resultObject = selectRequest.result;
            updateIndexedDB($.extend({}, resultObject, setObject));
        }
    }

    function deleteValue(key, QueueNumber) {
        if(!openRequest) return;
        createTransactionAndStore();
        var selectRequest = objectStore.get(key);
        selectRequest.onsuccess = function() {
            var resultObject = selectRequest.result;
            delete resultObject[QueueNumber];
            updateIndexedDB(resultObject);
        }
    }
})();
